import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { objectAsIterableArray } from 'datahive-basic';
import { ProductEntity } from 'src/app/core/entities/product.entity';
import { MasterdataCache } from 'src/app/core/services/mmr-cache';
import { MMRGlobals } from 'src/app/core/services/mmr-globals';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent
       implements OnInit {
   @Input() product: ProductEntity;
   componentDefinitions: any; //ComponentDefinitionEntity[];
   @Input() changelogItems: string[][];   
   @Output() selectedComponent = new EventEmitter();
   PropertyEntity = null; //PropertyEntity;

   
   constructor(public globals: MMRGlobals,
               public cache: MasterdataCache) { }
               
   public async ngOnInit() {
      const category = this.cache.getProductCategoryById(this.product.ProductCategoryId);      
      this.componentDefinitions = [];
      for(const def of category.ComponentDefinitionAffiliations) {
         const compDef = this.cache.getComponentDefinitionByEntityId(def.ComponentDefinitionEntityId);         
         this.componentDefinitions.push(compDef);
      }
      this.init();
   }
   public async ngOnChanges() {
      this.init();    
   }
   private init() {
      this.componentDefinitions.forEach(componentDefinition => {
         (componentDefinition as any).counter = [];
         const defaultCountInThisCategory = this.howOftenIsComponentDefinitionInProduct(componentDefinition.EntityId);
         for (let cnt = 0; cnt < defaultCountInThisCategory; cnt++) {
            (componentDefinition as any).counter.push({placeholder: true});
         }
      });
      this.emitSelectedComponent({
         index : 0
      });
   }
   public emitSelectedComponent(event: any) {
      const parts = [];
      objectAsIterableArray(this.product.Components).forEach(component => {
         this.product.Components[component.dicKey].forEach(part => {
            parts.push(part);
         });
      });
      this.selectedComponent.emit(parts[event.index]);
   }
   private howOftenIsComponentDefinitionInProduct(componentDefinitionEntityId: string) {
      if (this.product.Components && this.product.Components[componentDefinitionEntityId]) {
         return this.product.Components[componentDefinitionEntityId].length;
      }
      return 0;
   } 
}
