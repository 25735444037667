import { Injectable } from '@angular/core';
import { AreaEntity } from '../core/entities/area.entity';
import { AreaRepository } from '../core/services/area.repository';
import { MMRGlobals } from '../core/services/mmr-globals';
import { MMRHttpClient } from '../core/shared/mmr-http-client';

@Injectable()
export class AreaRepositoryService
       implements AreaRepository {
    apiPrefix = 'areas';
    constructor(public bsGlobals: MMRGlobals,
                private http: MMRHttpClient) {

    }
    public async getById(areaId: string): Promise<AreaEntity> {
        const area = await this.http.get<AreaEntity>(this.bsGlobals.baseUriGroups + this.apiPrefix + '/' + areaId);
        return area;
    }
}
