import { UserAction } from 'src/app/core/entities/user';
import { ComponentEntity } from './component.entity';
import { PlantEntity } from './plant.entity';

export class CustomerEntity {
   EnterpriseId;

   CreatedBy: UserAction;
   CreatedDate: string;
   ModifiedBy: UserAction;
   ModifiedDate: string;      

   ChangeHappenedDate: string;   
      
   Name: string;
   SiteIds: string[];
   BasicInformation: ComponentEntity;

   FileLocation: string;
   UserHasSupplierDocumentationReadAccess: boolean;
   /** Lazy loaded */   
   Sites: PlantEntity[];
}
