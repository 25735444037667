import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GetEventItemFormRequest } from 'src/app/core/input-data/service-book/get-event-item-form.request';
import { EventItemFormPresenter } from 'src/app/core/output-boundaries/event-item-form.presenter';
import { LayoutService } from 'src/app/core/services/layout.service';
import { MMRGlobals } from 'src/app/core/services/mmr-globals';
import { ServiceBookItemCheckAttachmentUseCaseService } from 'src/app/core/use-cases/service-book/event-item-check-attachment.use-case';
import { EventItemCreateUseCaseService } from 'src/app/core/use-cases/service-book/event-item-create.use-case';
import { EventItemEditUseCaseService } from 'src/app/core/use-cases/service-book/event-item-edit.use-case';
import { GetEventItemFormUseCaseService } from 'src/app/core/use-cases/service-book/get-event-item-form.use-case';
import { ServiceBookItemFormComponent } from '../base-service-book-item.form.component';
import { EventItemFormModel } from '../event-item-form/event-item.form-model';
import { EventItemViewPresenterService } from './event-item-view.presenter.service';
import { EventItemFormViewModel } from './event-item-view.view-model';

@Component({
  selector: 'app-event-item-view',
  templateUrl: './event-item-view.component.html',
  styleUrls: ['./event-item-view.component.scss'],
  providers: [
   GetEventItemFormUseCaseService,
   { provide: EventItemFormPresenter, useClass: EventItemViewPresenterService},
]
})
export class EventItemViewComponent 
       extends ServiceBookItemFormComponent
       implements OnInit {
   
   editorOptions = {
      hideIcons: ['Image']
   };
   constructor(public globals: MMRGlobals,
               public activatedRoute: ActivatedRoute,
               public router: Router,
               public formBuilder: UntypedFormBuilder,
               public checkAttachmentUseCaseService: ServiceBookItemCheckAttachmentUseCaseService,
               public getEventItemFormUseCase: GetEventItemFormUseCaseService,
               public layoutService: LayoutService,
               public eventItemFormPresenter: EventItemFormPresenter<EventItemFormViewModel>) { 
      super(globals, 
            activatedRoute, 
            router, 
            layoutService,
            formBuilder, 
            checkAttachmentUseCaseService);
   }   
   openSharePoint(e) {
      this.globals.trackEvent('OpenLibrary', e.detail.webUrl);
      window.open(e.detail.webUrl);
   }  
   public async ngOnInit() {
      this.globals.track();
      super.ngOnInit(true);      
   }  
   public async loadFormData() {
      const request = new GetEventItemFormRequest();
      request.IncludeRead = true;
      request.EventItemId = this.currentItemId;
      await this.getEventItemFormUseCase.execute(request);
      const event = this.eventItemFormPresenter.viewModel.GetEventItemFormResponse.EventItemEntity;
      if(event) {
         const formModel = {} as EventItemFormModel;
         formModel.Name = event.Name;
         formModel.Description = event.Description;
         formModel.SiteId = event.SiteId;
         formModel.LineIds = event.Lines.map(line => line.MasterdataId);
         formModel.ProductIds = event.Products.map(line => line.MasterdataId);
         formModel.BasicInformation = event.BasicInformation;
         this.formGroup.patchValue(formModel);
         this.setSelectedSite();         
         this.setSelectedLine();         
         this.setSelectedProduct();         
      }
      if(!this.edit) {
         this.setPreselectedFields(EventItemFormModel);
      }
   }
   public goBack() {
      this.router.navigate(['portal', 'servicebook', 'overview']);
   }
   public async editControllerAction() {}        
   public createControllerAction() {}
}
