import { ServiceBookItemsPresenter } from "src/app/core/output-boundaries/service-book-items.presenter";
import { ServiceBookItemListResponse } from "src/app/core/output-data/service-book-item-list.response";
import { ServiceBookItemListViewModel } from "./service-book-item-list.view-model";

export class ServiceBookItemListPresenterService
       extends ServiceBookItemsPresenter<ServiceBookItemListViewModel> {
   constructor() {
      super(ServiceBookItemListViewModel);
   }
   public showList(response: ServiceBookItemListResponse): void {
      this.viewModel.response = response;
   }
}
