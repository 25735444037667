import { Component, OnInit } from '@angular/core';
import { MMRGlobals } from 'src/app/core/services/mmr-globals';
import { BreadCrumbMenu } from '../breadcrumb-menu';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(public globals: MMRGlobals) { }

   ngOnInit(): void {
   }   
}
