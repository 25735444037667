import { AppSettings } from "./portal-users/app-settings";
import { FilesAccessMatrix } from "./portal-users/file-access-matrix";
import { PermissionAssignement } from "./portal-users/permission-assignement";

export class PortalUserEntity {
   Id: string;
   UserId: string;
   PermissionAssignements: PermissionAssignement[];
   FirstName: string;
   LastName: string;    
   EMail: string;
   Internal: boolean;
   FilesAccessMatrix: FilesAccessMatrix;
   AppSettings: AppSettings;
   /** External users */
   CustomerId: string;    
   HasAcceptedInvitation: boolean;    
   LastTimeFilesUpdated: Date;

   /** Internal users */
   IsGlobalAdmin: boolean; 
   hasPermission(permissionName: string) {
      if(this.IsGlobalAdmin) { return true; }

      for(const assignement of this.PermissionAssignements) {
         if(assignement.PermissionName === permissionName) {
            return true;
         }
      }
      return false;
   }
   hasPermissionForSite(permissionName: string, siteId: string) {
      if(this.IsGlobalAdmin) { return true; }

      for(let assignement of this.PermissionAssignements) {
         if(assignement.PermissionName === permissionName) {
            if(assignement.GlobalScope) { return true; }
            if(assignement.SpecificScopeIds.includes(siteId)) { return true; }
         }
      }
      return false;
   }
   hasAtLeastOnePermission(permissionNames: string[]) {
      if(this.IsGlobalAdmin) { return true; }

      for(const name of permissionNames) {
         if(this.hasPermission(name)) {
            return true;
         }
      }
      return false;
   }
   getDisplayName() {
      return this.FirstName + ' ' + this.LastName;
   }
   getPermissionAssignment(permissionName: string): PermissionAssignement {
      if(!this.PermissionAssignements) { return null; }
      let assignment = this.PermissionAssignements.find(pa => pa.PermissionName === permissionName);
      return assignment;
   }
}