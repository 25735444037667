
<ng-container *ngIf="hasWritePermission">
   <button mat-icon-button
         (click)="onEdit(serviceBookItem, context)">
      <mat-icon>edit</mat-icon>
   </button>
   <button mat-icon-button
           *ngIf="serviceBookItem.DeletedByCustomer"
           (click)="onRestore(serviceBookItem, context)">
      <mat-icon>settings_backup_restore</mat-icon>
   </button>      
   <button mat-icon-button
         (click)="onDelete(serviceBookItem, context)">
      <mat-icon class="mmr-delete-icon">delete_outline</mat-icon>
   </button>
   <!-- <button mat-icon-button
         (click)="openAttachments()">
      <mat-icon>folder_open</mat-icon>
   </button>    -->
</ng-container>
