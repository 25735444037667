<mat-tab-group animationDuration="0ms"
               (selectedTabChange)="emitSelectedComponent($event)"> 
   <mat-tab label="General">
      <div class="tab-content-outer">         
         <div class="tab-content-inner">
            <div class="scrollbar-placeholder"></div>
            <div class="float-left">
               <table class="bs-segment-no-float">
                  <tr>
                     <th><h3>Basic</h3></th>
                     <th colspan="2"></th>
                  </tr>
                  <tr>
                     <th class="bs-property-name">Customer</th>
                     <th class="bs-property-value"
                        *ngIf="product.EnterpriseName">
                        {{product.EnterpriseName}}
                     </th>
                  </tr>
                  <tr>
                     <th class="bs-property-name">Site</th>
                     <th class="bs-property-value">{{product.SiteName}}</th>
                  </tr>
                  <tr>
                     <th class="bs-property-name">Line</th>
                     <th class="bs-property-value">{{product.LineName}}</th>      
                  </tr>
                  <tr>
                     <th class="bs-property-name">Order</th>
                     <th class="bs-property-value"
                        *ngIf="product.OrderNumber">
                        {{product.OrderNumber}}
                     </th>
                  </tr>
                  <tr>
                     <th class="bs-property-name">Type</th>
                     <th class="bs-property-value">{{product.ProductTypeName}}</th>
                  </tr>
                  <tr>
                     <th class="bs-property-name">Category</th>
                     <th class="bs-property-value">{{product.ProductCategoryName}}</th>  
                  </tr>               
               </table> 
               <br>
               <!-- <app-show-details [entity]="product"></app-show-details>                       -->
            </div>
            <!-- <ng-container *ngIf="componentDefinitions[0]">
               <table *ngFor="let segment of componentDefinitions[0].Segments"
                     class="bs-segment">
                  <tr>
                     <th><h3>{{segment.Name}}</h3></th>
                     <th colspan="2"></th>
                  </tr>            
                  <tr *ngFor="let property of segment.Properties">
                     <th class="bs-property-name">{{property.Name}}</th>

                     <th class="bs-property-value">
                        <app-show-property [property]="property" 
                                          [value]="product.Components[componentDefinitions[0].EntityId][0][property.Id]">
                        </app-show-property>
                     </th>               
                  </tr>
               </table>
               <div class="clear-both"></div>                        
            </ng-container> -->
         </div>
      </div>
   </mat-tab>
   <!-- <ng-container *ngFor="let definition of componentDefinitions">
      <ng-container *ngIf="product.Components[definition.EntityId] && product.Components[definition.EntityId].length > 0 && definition.EntityId !== 'general_products'">
         <ng-container *ngFor="let c of definition.counter; let i = index">
            <mat-tab *ngIf="product.Components[definition.EntityId][i].Inactive !== true"
                     [label]="definition.Name + ' ' + (definition.counter.length > 1 ? i + 1 : '')">
               <app-show-component-details [definition]="definition"
                                          [component]="product.Components[definition.EntityId][i]">
               </app-show-component-details>
            </mat-tab>
         </ng-container>
      </ng-container>
   </ng-container>  -->
   <!-- <mat-tab label="History"
            *ngIf="changelogItems && changelogItems.length > 0">
      <ng-template mat-tab-label>
         <div class="bs-icon-middle bs-icon-history" style="margin-right: 10px;"></div>History
      </ng-template>            
      <app-show-history [changelogItems]="changelogItems">

      </app-show-history>
   </mat-tab>    -->
</mat-tab-group>
