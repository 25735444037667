import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LineWithContent } from 'src/app/core/entities/line-with-content';
import { SiteWithContent } from 'src/app/core/entities/site-with-content';
import { LayoutService } from 'src/app/core/services/layout.service';
import { MMRGlobals } from 'src/app/core/services/mmr-globals';
import { AGGridBaseOverviewComponent } from 'src/app/core/shared/ag-grid/ag-grid-base-overview.component';
import { getGridOptions } from 'src/app/core/shared/ag-grid/helpers';
import { BreadCrumbMenu } from 'src/app/presentation/breadcrumb-menu';
import { navigateToMaintenanceDocumentation } from '../product-detail/product-detail.component';
import { getColumnDefinitionLineList } from './line-list.column-definition';

@Component({
  selector: 'app-plant-detail',
  templateUrl: './plant-detail.component.html',
  styleUrls: ['./plant-detail.component.scss']
})
export class PlantDetailComponent 
       extends AGGridBaseOverviewComponent
       implements OnInit {
   currentSite: SiteWithContent;
   selectedLine: LineWithContent;
   navigateToMaintenanceDocumentation = navigateToMaintenanceDocumentation;
   constructor(public globals: MMRGlobals,
               public router: Router,
               public layoutService: LayoutService,
               public activatedRoute: ActivatedRoute,) {
      super();
   }

   public async ngOnInit() {
      this.globals.track();
      await this.initGridOptions();
      this.determineCurrentSite();
      this.refreshView();
      this.select(); 
      this.buildBurgerMenu();
   }
   public ngOnDestroy() {
      this.globals.resetBurgerMenu();
   }
   public buildBurgerMenu() {
      const burger = new BreadCrumbMenu();
      burger.addItem('All Plants', '/portal/masterdata/plant-overview');
      burger.addItem('Plant: <strong>' + this.currentSite.Name + '</strong>', '/portal/masterdata/plant-detail/' + this.currentSite.SiteId);
      this.globals.setBurgerMenu(burger);
   }
   private determineCurrentSite() {
      const accessor = 'id';
      const snapshot = this.activatedRoute.snapshot;
      const siteId = snapshot.params[accessor];
      this.currentSite = this.globals.allCustomerSites.filter(site => site.SiteId === siteId)[0];      
   }
   public async initGridOptions() {
      this.gridOptions = getGridOptions(true, this.globals, this, false);
      this.gridOptions.context = this;
      this.gridOptions.isFullWidthRow = this.isFullWidth;
      this.gridOptions.onRowSelected = this.onSelectContext;
      this.gridOptions.columnDefs = getColumnDefinitionLineList();
      const prom = new Promise<void>(resolve => {
         this.gridOptions.onGridReady = () => {
            resolve();
         };
      });
      return prom;     
   }
   public select() {
      let cnt = 0;
      this.gridOptions.api.forEachNode((node) => {
         if(cnt === 0) {
            node.setSelected(true);         
         }
         cnt++;
     });
   }   
   public async onSelect() {
      const selected = this.gridOptions.api.getSelectedRows();
      this.selectedLine = selected[0];   
   }
   public async onSelectContext(grid) {
      grid.context.onSelect.call(grid.context);
   }
   public refreshView() {
      this.gridOptions.rowData = [];
      for(const line of this.currentSite.Lines) {
         this.gridOptions.rowData.push(line);
      }
      const line = new LineWithContent();
      line.Name = 'Other products';
      line.Products = this.currentSite.Products;
      this.gridOptions.rowData.push(line);
      if (this.gridOptions.api) {
         this.gridOptions.api.setRowData(this.gridOptions.rowData);
      }      
   }
   public navigateToServiceBookForSite() {
      if(this.selectedLine && this.selectedLine.Id) {
         this.router.navigate(['portal', 'servicebook', 'overview', this.currentSite.SiteId, this.selectedLine.Id]);
      } else {
         this.router.navigate(['portal', 'servicebook', 'overview', this.currentSite.SiteId]);
      }      
   }
   public navigateToCreate247Support() {
      if(this.selectedLine) {
         this.router.navigate(['portal', 'servicebook', 'twenty47RequestForm', this.currentSite.SiteId, this.selectedLine.Id]);
      } else {
         this.router.navigate(['portal', 'servicebook', 'twenty47RequestForm', this.currentSite.SiteId]);
      }      
   }
   public navigateToSiteServiceRequest() {
      if(this.selectedLine) {
         this.router.navigate(['portal', 'servicebook', 'siteServiceRequestForm', this.currentSite.SiteId, this.selectedLine.Id]);
      } else {
         this.router.navigate(['portal', 'servicebook', 'siteServiceRequestForm', this.currentSite.SiteId]);
      }  
   }

}
