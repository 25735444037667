export class UserEntity {
   Id: string;
   FirstName: string;
   LastName: string;
   EmailAdress: string;
   JobTitle: string;
   ProfilePicture: string;
   ProfilePictureBase64: any;
   IsExternal: boolean;
}
export class UserAction {
   Name: string;
   Id: string;
   Domain: string;
}