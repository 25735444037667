<div style="width: 1000px">
        <button mat-stroked-button 
                [disabled]="!!!this.sparepartsUrl"
                *ngIf="!isOtherProductsLine"
                color="primary" 
                (click)="openSparepartsUrl($event)">
        Spareparts
        </button>
        <button mat-stroked-button 
                color="primary" 
                (click)="stopPropagation($event)"
                [disabled]="!!!line.HFMGAnalyticsUrl"
                *ngIf="!isOtherProductsLine"                   
                [matMenuTriggerFor]="menu">
                <mat-icon>insights</mat-icon> 
                HFMGDiagnostics
        </button>
        <mat-menu #menu="matMenu">       
           <button mat-menu-item
                   color="primary"
                   [routerLink]="'/portal/masterdata/analytics'"
                   [queryParams]="{url: getUrlForIFrame(line.HFMGAnalyticsUrl, 'alarmsovertime')}">
           Alarms Overtime
           </button>
           <button mat-menu-item
                    color="primary"
                    [routerLink]="'/portal/masterdata/analytics'"
                    [queryParams]="{url: getUrlForIFrame(line.HFMGAnalyticsUrl, 'alarmsfrequency')}">
           Alarms By Frequency
           </button>
           <button mat-menu-item
                    color="primary"
                    [routerLink]="'/portal/masterdata/analytics'"
                    [queryParams]="{url: getUrlForIFrame(line.HFMGAnalyticsUrl, 'statemode')}">
           Unit Availability
           </button>     
           <button mat-menu-item
                    color="primary"
                    [routerLink]="'/portal/masterdata/analytics'"
                    [queryParams]="{url: getUrlForIFrame(line.HFMGAnalyticsUrl, 'health')}">
           Health
           </button>   
           <button mat-menu-item
                    color="primary"
                    [routerLink]="'/portal/masterdata/analytics'"
                    [queryParams]="{url: getUrlForIFrame(line.HFMGAnalyticsUrl, 'ocm')}">
           OCM
           </button>  
        </mat-menu>                             
        <div class="clear-both"></div>
</div>