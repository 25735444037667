import { Subscription } from 'rxjs';
import { CustomerEntity } from '../entities/customer.entity';
import { QueryRequest } from '../input-data/query.request';

export abstract class CustomerRepository {
   public abstract getById(enterpriseId: string, renderOptions: boolean): Promise<CustomerEntity>;

   public abstract getByIdWithChangelog(enterpriseId: string,renderOptions: boolean): Promise<CustomerEntity[]>;

   public abstract searchEnterprises(query: QueryRequest, requestReference: {request: Subscription}): Promise<CustomerEntity[]>;
}
