import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { IUseCase } from "../../architecture/use-case";
import { EventItemEditRequest } from "../../input-data/service-book/event-item-edit.request";
import { ServiceBookItemDeleteRequest } from "../../input-data/service-book/service-book-item-delete.request";
import { SiteServiceRequestEditRequest } from "../../input-data/service-book/site-service-request-edit.request";
import { Twenty47RequestEditRequest } from "../../input-data/service-book/twenty47-request-edit.request";
import { ServiceBookRepository } from "../../repositories/service-book.repository";

@Injectable({providedIn: 'root'})
export class Twenty47RequestEditUseCaseService
       implements IUseCase<ServiceBookItemDeleteRequest, void> {
   public readonly presenter: void;

   requestCancelationToken: {request: Subscription } = {request: null};
   constructor(public serviceBookRepository: ServiceBookRepository) {}

   public async execute(request: Twenty47RequestEditRequest): Promise<void> {
      await this.serviceBookRepository.editTwenty47Request(request.Id, request.Twenty47RequestEntity);
   }
}
