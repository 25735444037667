import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { IUseCase } from "../../architecture/use-case";
import { ServiceBookItemDeleteRequest } from "../../input-data/service-book/service-book-item-delete.request";
import { ServiceBookRepository } from "../../repositories/service-book.repository";

@Injectable({providedIn: 'root'})
export class EventItemDeleteUseCaseService
       implements IUseCase<ServiceBookItemDeleteRequest, void> {
   public readonly presenter: void;

   requestCancelationToken: {request: Subscription } = {request: null};
   constructor(public serviceBookRepository: ServiceBookRepository) {}

   public async execute(request: ServiceBookItemDeleteRequest): Promise<void> {
      await this.serviceBookRepository.deleteEventItem(request.Id);
   }
}
