import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { showYesNoDialog } from 'datahive-ngx-ui';
import { MatomoTracker } from 'ngx-matomo';
import { SiteWithContent } from 'src/app/core/entities/site-with-content';
import { AdminRepository } from 'src/app/core/repositories/admin.repository';
import { MasterdataRepository } from 'src/app/core/repositories/masterdata.repository';
import { LayoutService } from 'src/app/core/services/layout.service';
import { MMRGlobals } from 'src/app/core/services/mmr-globals';
import { BreadCrumbMenu } from 'src/app/presentation/breadcrumb-menu';

@Component({
  selector: 'app-plant-overview',
  templateUrl: './plant-overview.component.html',
  styleUrls: ['./plant-overview.component.scss']
})
export class PlantOverviewComponent implements OnInit {
   allSites: SiteWithContent[];
   constructor(public globals: MMRGlobals,
               public layoutService: LayoutService,
               public dialog: MatDialog,
               public router: Router,
               public adminRepository: AdminRepository) { }

   public async ngOnInit() {
      this.globals.track();
      this.allSites = this.globals.allCustomerSites;
      this.buildBurgerMenu();
   }
   public buildBurgerMenu() {
      const burger = new BreadCrumbMenu();
      burger.addItem('All Plants', '/portal/masterdata/plant-overview');
      this.globals.setBurgerMenu(burger);
   }
   public ngOnDestroy() {
      this.globals.resetBurgerMenu();
   }
}
