<h3 *ngIf="comments && comments.length > 1">Ticket comments:</h3>    
<div  class="bs-tickets-comments-outer"
      *ngFor="let comment of comments; let i = index">
   <div *ngIf="i > 0">
      <div class="bs-comment-header">
         <div class="bs-comment-author">From <strong>{{comment.Author?.Name}}</strong></div>
         <div class="bs-comment-date">At {{getGoodDate(comment.CreationDate)}}</div>
      </div>
      <div [innerHtml]="comment.HtmlBody">
         
      </div>
   </div>
</div>
