import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fields',
  templateUrl: './fields.component.html',
  styleUrls: ['./fields.component.scss']
})
export class FieldsComponent 
       implements OnInit {

   constructor() { }

   ngOnInit(): void {
   }
   public goToComponentDefinitionEventItem() {
      window.open('https://collaboration.hfmg.com/product-structure/component-definitions/edit/60ec4fdc544d6a2bf52c408e', '_blank');
   }
   public goToComponentDefinitionTwenty47Request() {
      window.open('https://collaboration.hfmg.com/product-structure/component-definitions/edit/60ec5075544d6a2bf52c408f', '_blank');
   }
   public goToComponentDefinitionSiteServiceRequest() {
      window.open('https://collaboration.hfmg.com/product-structure/component-definitions/edit/60ec5134544d6a2bf52c4090', '_blank');
   } 
}
