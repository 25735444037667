import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-codedocs',
  templateUrl: './codedocs.component.html',
  styleUrls: ['./codedocs.component.scss']
})
export class CodeDocsComponent 
       implements OnInit {

   constructor() { }

   ngOnInit(): void {
   }
   public goToServiceBookAPI() {
      window.open('http://hfmg-mymixingroom-servicebook-prod.azurewebsites.net/index.html', '_blank');
   }
   public goToAzureDevOps() {
      window.open('https://dev.azure.com/mixinggroup/HFMG.CSP', '_blank');
   }
   public goToMasterdataAPI() {
      window.open('https://hfmg-mymixingroom-masterdata-prod.azurewebsites.net', '_blank');
   } 
}
