import { UserAction } from 'src/app/core/entities/user';
import { ComponentEntity } from './component.entity';
import { LineWithContent } from './line-with-content';
import { ProductEntity } from './product.entity';

export class SiteWithContent {
   Id: string;
   Name: string;
   EnterpriseName: string;
   CountryName: string;
   EnterpriseId: string;
   SiteId: string;
   Lines: LineWithContent[];
   Products: ProductEntity[];
   BasicInformation: ComponentEntity;   
   UserHasSupplierDocumentationReadAccess: boolean;
   FileLocation: string;
   CreatedBy: UserAction;
   CreatedDate: string;
   ModifiedBy: UserAction;
   ModifiedDate: string;  
   
   public isBuyerOrganization() {
      const isBuyer = this.BasicInformation['isbuyer__BO6L7'] === true;
      return isBuyer;
   }
   public getLineCount() {
      if(this.Lines) {
         return this.Lines.length;
      } else {
         return 0;
      }
   }   
   public getProductsCount() {
      let cnt = 0;
      if(this.Lines) {
         for(const line of this.Lines) {
            if(line.Products) {
               for(const product of line.Products) {
                  cnt++;
               }
            }
         }
      }
      if(this.Products) {
         for(const product of this.Products) {
            cnt++;
         }
      }
      return cnt;
   }
   public getProductById(productId: string) {
      let product = null;
      if(this.Lines) {
         for(const line of this.Lines) {
            product = line.getProductById(productId);
            if(product) {
               return product;
            }
         }
      }
      if(this.Products) {
         for(const prod of this.Products) {
            if(prod.ProductId === productId) {
               product = prod;
            }
         }
      }
      return product;
   }
   public getLineById(id: string) {
      if(this.Lines) {
         for(const line of this.Lines) {
            if(line.Id === id) {
               return line;
            }
         }
      }
      return null;
   }
   public getLineByName(name: string) {
      if(this.Lines) {
         for(const line of this.Lines) {
            if(line.Name === name) {
               return line;
            }
         }
      }
      return null;
   }   
   public hasLine(lineId: string) {
      for(const line of this.Lines) {
         if(line.Id === lineId) {
            return true;
         }
      }
      return false;
   }   
}
