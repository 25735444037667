/* eslint-disable no-case-declarations */
import { Injectable } from "@angular/core";
import { getNestedProperty, hasValidValue } from "datahive-basic";
import { Subscription } from "rxjs";
import { SharePointFile } from "../core/entities/sharepoint-file";
import { SharePointFileResults } from "../core/entities/sharepoint-file.results";
import { SharePointRepository } from "../core/repositories/sharepoint.repository";
import { MMRGlobals } from "../core/services/mmr-globals";
import { MMRHttpClient } from "../core/shared/mmr-http-client";

@Injectable()
export class SharePointRepositoryService
       implements SharePointRepository {
   constructor(public globals: MMRGlobals,
               public httpClient: MMRHttpClient) {

   }
   private getRequestBody(queryText: string, rowLimit: number) {
      return {
         'request' : {
                        'Querytext' : queryText,
                        'TrimDuplicates': 'False',
                        'RowLimit' : rowLimit,
                        'SortList': [
                           {
                              'Property': 'docid',
                              'Direction': 0
                           }
                        ],
                        'SelectProperties' : [
                           "Title",
                           "Path",
                           "Author",
                           "Size",
                           "LastModifiedTime",
                           "HFPlant",
                           "HFLine",
                           "HFProductType",
                           "HFSerialNumber",
                           "HFProductPart",
                           "HFDocumentType",
                           "ProductIdHFMGGlobal",
                           "Name"
                       ]
                     }
      };
   }
   public async getSearchResults(queryText: string, 
                                 site = '', 
                                 rowLimitPerRequest: number,
                                 requestReference: {request: Subscription},
                                 lastDocId: string=null,
                                 resultsTillNow: SharePointFileResults=null,
                                 maxResults = 5000,
                                 warningOfResultsExceed = true,
                                 totalsFromFirstCall = null): Promise<SharePointFileResults> {
      try {
         if(resultsTillNow && resultsTillNow.Results.length >= maxResults) {
            if(warningOfResultsExceed) {
               alert('The results exceed ' + maxResults + '. You might encounter missing files.');
            }         
            return resultsTillNow;
         }
         if(!lastDocId) {
            const firstBatch = await this.getSearchResult(queryText, site, rowLimitPerRequest, requestReference);                  
            if(firstBatch.Results.length === 0) { return firstBatch; }
            lastDocId = firstBatch.Results[firstBatch.Results.length-1].DocId;
            if(firstBatch.Results.length === rowLimitPerRequest) {
               return this.getSearchResults(queryText, site, rowLimitPerRequest, requestReference, lastDocId, firstBatch, maxResults, warningOfResultsExceed, firstBatch.Total);
            } else {
               return firstBatch;
            }         
         } else {
            const nextBatch = await this.getSearchResult(queryText + ' docid>' + lastDocId, site, rowLimitPerRequest, requestReference);
            if(nextBatch.Results.length === 0) { return resultsTillNow; }
            lastDocId = nextBatch.Results[nextBatch.Results.length-1].DocId;
            resultsTillNow.Results = resultsTillNow.Results.concat(nextBatch.Results);
            if(nextBatch.Results.length === rowLimitPerRequest) {
               return this.getSearchResults(queryText, site, rowLimitPerRequest, requestReference, lastDocId, resultsTillNow, maxResults, warningOfResultsExceed, totalsFromFirstCall);
            } else {
               resultsTillNow.Total = totalsFromFirstCall;
               return resultsTillNow;
            }         
         }   
      } catch(e) {
         return new SharePointFileResults(0);
      }                           
   }
   public async getSearchResult(queryText: string, 
                                site = '', 
                                rowLimit: number,
                                requestReference: {request: Subscription}): Promise<SharePointFileResults> {
      const result = new SharePointFileResults(0);      
      const spBasePath = 'https://mixinggroup.sharepoint.com/sites/CustomerPortalSharedWithAllCustomers';
      const results = await this.httpClient.post<any, any>(spBasePath + site + '/_api/search/postquery',
      this.getRequestBody(queryText, rowLimit), requestReference, false);
      const files: SharePointFile[] = [];
      const fileResults = getNestedProperty(results, ['PrimaryQueryResult', 'RelevantResults', 'Table', 'Rows']);
      result.Total = getNestedProperty(results, ['PrimaryQueryResult', 'RelevantResults', 'TotalRowsIncludingDuplicates']);
      if (hasValidValue(fileResults) === true) {
         fileResults.forEach(fileResult => {
            const file = new SharePointFile();
            fileResult.Cells.forEach(property => {
               switch(property.Key) {
                  case 'Author':
                     file.Author = property.Value;
                  break;
                  case 'HFPlant':
                     file.Plant = property.Value;
                  break;                  
                  case 'HFLine':
                     file.Line = property.Value;
                  break;       
                  case 'HFProductPart':
                     file.ProductPart = property.Value;
                  break;       
                  case 'HFSerialNumber':
                     file.SerialNumber = property.Value;
                  break;       
                  case 'ProductIdHFMGGlobal':
                     file.ProductId = property.Value;
                  break;                    
                  case 'HFProductType':
                     file.ProductType = property.Value;
                  break;                    
                  case 'HFDocumentType':
                     file.DocumentType = property.Value;
                  break;                                                                                             
                  case 'DocId':
                     file.DocId = property.Value;
                  break;   
                  case 'FileType':
                     file.Type = property.Value;
                  break;
                  case 'SPWebUrl':
                     file.WebUrl = property.Value;
                  break;
                  case 'Size':
                     file.Size = (property.Value / 1000) + 'kB';
                  break;
                  case 'LinkingUrl':
                     file.ApplicationUrl = property.Value;
                  break;
                  case 'ParentLink':
                     file.LocationUrl = property.Value;
                  break;
                  case 'Title':
                     file.Title = property.Value;
                  break;
                  case 'PictureThumbnailURL':
                     file.ThumbnailURL = property.Value;
                  break;
                  case 'OriginalPath':
                     file.Path = property.Value;
                  break;
                  case 'Description':
                     file.Description = property.Value;
                  break;
                  case 'Path':
                     const path = property.Value;
                     file.DownloadUrl = path;
                     const split = path.split('/');
                     if (split.length > 0) {
                        file.FileName = split[split.length - 1];
                     }
                  break;                                                                                                                                                                                                                                        
               }
            });
            if (hasValidValue(file.ApplicationUrl) === false) {
                  file.ApplicationUrl = file.Path;
            }
            /**
             *  SharePoint search does not provide a nice open-URL for files that are not native office files or pdf. Here I build this url
             *  out of the sharepoint rest result if the file is unknown.
             */
            if (file.Type !== 'docx' &&
                  file.Type !== 'doc'  &&
                  file.Type !== 'xls'  &&
                  file.Type !== 'xlsx'  &&
                  file.Type !== 'ppt'  &&
                  file.Type !== 'pptx'  &&
                  file.Type !== 'pdf') {
                  let filePath = file.DownloadUrl;
                  if (filePath.indexOf('/Forms/DispForm') !== -1) {
                     const loc = filePath.indexOf('/Forms/DispForm');
                     filePath = filePath.substr(0, loc)  + '/' + file.Title + '.' + file.Type;
                     file.DownloadUrl = filePath;
                     file.FileName = file.Title + '.' + file.Type;
                     file.ApplicationUrl = filePath;
                  }
            }
            files.push(file);
         });
         result.Results = files;
         return result;
      } else {
         return new SharePointFileResults(0);
      }
   }
}