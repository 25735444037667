import { ServiceBookItem } from "../entities/service-book/service-book-item";
import { GroupedByFilterResult } from "./grouped-by-filter-results";

export class ServiceBookAggregatedResponse {
   Results: ServiceBookItem[];
   Total: number;
   GroupedLineIds: GroupedByFilterResult[];
   GroupedSiteIds: GroupedByFilterResult[];
   GroupedProductIds: GroupedByFilterResult[];
   GroupedAffectedAreasIds: GroupedByFilterResult[];
}