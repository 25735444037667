import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from 'src/app/core/services/layout.service';
import { MMRGlobals } from 'src/app/core/services/mmr-globals';

@Component({
  selector: 'app-authentication-wrapper',
  templateUrl: './authentication-wrapper.component.html',
  styleUrls: ['./authentication-wrapper.component.scss']
})
export class AuthenticationWrapperComponent implements OnInit {
   showGlobalLoading = false;
   constructor(public globals: MMRGlobals,
               public router: Router,
               public layoutService: LayoutService) {

   }
   ngOnInit(): void {
      this.handleLoading();
      this.checkLoginState();
      this.layoutService.websiteActive = false;
      if(this.globals.currentCustomerPortalProfile.Internal) {
         this.layoutService.setNavigationtoCustomerPortalDefaultInternal();
      } else {
         this.layoutService.setNavigationtoCustomerPortalDefaultExternal();
      }
   }
   checkLoginState() {
      if(this.globals.currentUser && !this.globals.currentCustomerPortalProfile) {
         this.router.navigate(['']);
      }
   }   
   handleLoading() {
      this.globals.globalLoading.subscribe(show => {
         this.showGlobalLoading = show;
      });  
   }   
}
