import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { IUseCase } from "../../architecture/use-case";
import { GetEventItemFormRequest } from "../../input-data/service-book/get-event-item-form.request";
import { EventItemFormPresenter } from "../../output-boundaries/event-item-form.presenter";
import { GetEventItemFormResponse } from "../../output-data/get-event-item-form.response";
import { ComponentDefinitionRepository } from "../../repositories/component-definition.repository";
import { ServiceBookRepository } from "../../repositories/service-book.repository";
import { MasterdataCache } from "../../services/mmr-cache";
import { MMRGlobals } from "../../services/mmr-globals";

@Injectable({providedIn: 'root'})
export class GetEventItemFormUseCaseService
       implements IUseCase<GetEventItemFormRequest, EventItemFormPresenter<any>> {
          
   requestCancelationToken: {request: Subscription } = {request: null};
   constructor(public presenter: EventItemFormPresenter<any>,
               public cache: MasterdataCache,
               public globals: MMRGlobals,
               public componentDefinitionRepository: ComponentDefinitionRepository,
               public serviceBookRepository: ServiceBookRepository) {}

   public async execute(request: GetEventItemFormRequest): Promise<void> {
      this.presenter.reset();
      this.cancelRequest();      
      const response = new GetEventItemFormResponse();      

      if(request.EventItemId) {
         response.EventItemEntity = await this.serviceBookRepository.getEventItemById(request.EventItemId, false);               
         if(response.EventItemEntity.CustomerEvent) {
            response.SitesWithReadWritePermission = getSitesWithPermission('CustomerEvents', this.globals, request.IncludeRead);
         } else {
            response.SitesWithReadWritePermission = getSitesWithPermission('SupplierEvents', this.globals, request.IncludeRead);
         }         
         response.CanChangeEvent = this.globals.currentCustomerPortalProfile.IsGlobalAdmin || response.SitesWithReadWritePermission.map(site => site.SiteId).includes(response.EventItemEntity.SiteId);
         if(response.CanChangeEvent) {
            response.FormHeadline = 'Update event';
         } else {
            response.FormHeadline = 'View event';
         }

      } else {
         response.FormHeadline = 'Create event';
         response.SitesWithReadWritePermission = getSitesWithPermission('CustomerEvents', this.globals, request.IncludeRead);
      }      
      response.ComponentDefinition = await this.cache.getItemEventRequestComponentDefinition();
      this.presenter.showForm(response);
   }
   private cancelRequest() {
      if (this.requestCancelationToken && this.requestCancelationToken.request) {
         this.requestCancelationToken.request.unsubscribe();
      }
   }  
}
export function getSitesWithPermission(title: string, globals: MMRGlobals, includeRead: boolean) {
   if(globals.currentCustomerPortalProfile.IsGlobalAdmin) {
      return globals.allCustomerSites;
   }
   const readAssignment = globals.currentCustomerPortalProfile.getPermissionAssignment(title + '.Read');
   const readWriteAssignment = globals.currentCustomerPortalProfile.getPermissionAssignment(title + '.ReadWrite');
   const fullControlAssignment = globals.currentCustomerPortalProfile.getPermissionAssignment(title + '.FullControl');

   const sites = [];
   if(readWriteAssignment) {
      if(readWriteAssignment.GlobalScope) {
         return globals.allCustomerSites;
      }
      sites.push(...readWriteAssignment.SpecificScopeIds);         
   }
   if(fullControlAssignment) {
      if(fullControlAssignment.GlobalScope) {
         return globals.allCustomerSites;
      }
      sites.push(...fullControlAssignment.SpecificScopeIds);         
   }   
   if(readAssignment && includeRead) {
      if(readAssignment.GlobalScope) {
         return globals.allCustomerSites;
      }
      sites.push(...readAssignment.SpecificScopeIds);         
   }    
   const sitesWithReadWritePermission = [];
   for(const site of globals.allCustomerSites) {
      if(sites.includes(site.SiteId)) {
         sitesWithReadWritePermission.push(site);
      }
   }
   return sitesWithReadWritePermission;
}  
