import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { LayoutService } from 'src/app/core/services/layout.service';
import { MMRGlobals } from 'src/app/core/services/mmr-globals';
import { BreadCrumbMenu } from '../breadcrumb-menu';
import { NavigationLink } from '../modules/website/core/models/navigation-link';
import { NavigationService } from '../modules/website/navigation.service';
import gsap from 'gsap';
import { Router } from '@angular/router';
import { isTestSystem } from 'src/app/app.component';

@Component({
  selector: 'app-hfmg-header',
  templateUrl: './hfmg-header.component.html',
  styleUrls: ['./hfmg-header.component.scss']
})
export class HfmgHeaderComponent 
       implements OnInit {

   breadCrumbMenu: BreadCrumbMenu;
   sublinks: NavigationLink[];
   subsublinks: NavigationLink[];
   currentMainItem: NavigationLink;
   isTestSystem = isTestSystem;
   constructor(public layoutService: LayoutService,
               public globals: MMRGlobals,
               public router: Router,
               public navigationService: NavigationService,
               public authenticationService: AuthenticationService) { }

   ngOnInit(): void {
      this.globals.showGlobalLoading();
      this.setBreadcrumbNavigation();  
      this.globals.hideGlobalLoading();    
   }
   setBreadcrumbNavigation() {
      this.globals.burgerMenu.subscribe(menu => {
         this.breadCrumbMenu = menu;
      });
   }
   exitSimulation() {
      this.globals.exitSimulation();
      this.layoutService.setNavigationtoCustomerPortalDefaultInternal();
      this.router.navigate(['portal', 'admincenter', 'map-all-sites']);
   }
   navigationImage: string;
   showNavigationImage(item: NavigationLink) {
      this.navigationImage = item.ImagePath;
      gsap.fromTo('#hfmg-navigation-preview', {
         opacity: 0,
         height: 200,
         duration: 0.5,
         ease: 'back(10)'
      }, {
         opacity: 1,
         height: 230,
         duration: 0.5,
         ease: 'back(10)'
      });
   } 
   wasOpened = false; /** If the navigation is closed, the links should stay inside the array, because angular should not hide the items directly. 
                          For this reason the navigation will delete the links when the animation ends. If in this time (while the navigation is closing), 
                          another links is clicked and the items are populated again, the links should not be purged, but kept. 
                          This variable asks if the navigation was opened again while the closing animation has not finished. */
   showSubNavigationOrNavigate(mainItem: NavigationLink) {
      this.wasOpened = true;
      this.currentMainItem = mainItem;
      this.sublinks = mainItem.Sublinks;

      const navElement = document.getElementById('hfmg-sub-navigation');
      if(navElement) {
         const navHeight = navElement.offsetHeight;
         gsap.set('#hfmg-sub-navigation', {
            height: navHeight + 'rem',
         });
      }
      if(mainItem.Sublinks.length === 0) { 
         this.closeSubNavigation();
         this.navigationService.navigateTo(mainItem);
         return;
      }
      const gettingSmaller = this.sublinks && this.sublinks.length > 0;      
      gsap.to('#hfmg-sub-navigation', {
         height: 'auto',
         duration: 1,
         padding: '20rem',
         ease: 'back'
      });
      gsap.to('#blur-page', {
         duration: 1,
         opacity: 1,
         display: 'block'
      });               
   }
   closeSubNavigation() {
      this.wasOpened = false;
      gsap.to('#blur-page', {
         opacity: 0,
         duration: 1,
         display: 'none'
      });  
      gsap.to('#hfmg-sub-navigation', {
         height: 0,
         duration: 0.4,
         padding: '0',
         margin: '0',
         ease: 'linear',
         onComplete: _ => {            
            if(!this.wasOpened) {
               delete this.currentMainItem;
               this.sublinks = [];
               this.subsublinks = [];
            }
         }
      })       
   }   
   hideNavigationImage() {
      delete this.navigationImage;
   }
   logout() {
      this.authenticationService.logout();
   }      
}

