<mat-card class="mmr-white-card">
   <mat-card-header>
      <div mat-card-avatar>
         <mat-icon>api</mat-icon>
      </div>
      <mat-card-title>Documentation</mat-card-title>
      <mat-card-subtitle>Events and ticket forms can be changed externally in DataHive</mat-card-subtitle>
    </mat-card-header>      
   <mat-card-content>
   
      <mat-card class="mmr-action-card"
                (click)="goToServiceBookAPI()">
         <mat-card-header>
            <div mat-card-avatar>
               <mat-icon>api</mat-icon>
            </div>
            <mat-card-title>ServiceBook API</mat-card-title>
            <mat-card-subtitle>Click here to visit API definition</mat-card-subtitle>
         </mat-card-header>   
         <mat-card-content>
            
         </mat-card-content>
      </mat-card>      
      <mat-card class="mmr-action-card"
                (click)="goToMasterdataAPI()">
         <mat-card-header>
            <div mat-card-avatar>
               <mat-icon>api</mat-icon>
            </div>
            <mat-card-title>Masterdata API</mat-card-title>
            <mat-card-subtitle>Click here to visit API definition</mat-card-subtitle>
         </mat-card-header>   
         <mat-card-content>
            
         </mat-card-content>
      </mat-card>    
      <mat-card class="mmr-action-card"
                (click)="goToAzureDevOps()">
         <mat-card-header>
            <div mat-card-avatar>
               <mat-icon>code</mat-icon>
            </div>
            <mat-card-title>Source code</mat-card-title>
            <mat-card-subtitle>Click here to visit the Repository in Azure DevOps</mat-card-subtitle>
         </mat-card-header>   
         <mat-card-content>
            
         </mat-card-content>
      </mat-card>  
      <div class="clear-both"></div>
   </mat-card-content>
   <mat-card-actions>              
   </mat-card-actions>         
</mat-card>
