import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { AboutComponent } from './presentation/about/about.component';
import { AuthenticationWrapperComponent } from './presentation/authentication-wrapper/authentication-wrapper.component';
import { DashboardComponent } from './presentation/dashboard/dashboard.component';
import { CodeDocsComponent } from './presentation/documentation/codedocs.component';
import { FieldsComponent } from './presentation/fields/fields.component';
import { AnalyticsIframeComponent } from './presentation/modules/masterdata/analytics-iframe/analytics-iframe.component';
import { PlantDetailComponent } from './presentation/modules/masterdata/plant-detail/plant-detail.component';
import { PlantOverviewComponent } from './presentation/modules/masterdata/plant-overview/plant-overview.component';
import { ProductDetailComponent } from './presentation/modules/masterdata/product-detail/product-detail.component';
import { SparepartsIframeComponent } from './presentation/modules/masterdata/spareparts-iframe/spareparts-iframe.component';
import { MMRResolver } from './resolver/mmr-resolver';
import { GlobalResolver } from './resolver/global-resolver';
import { PlantOverviewChangeShoppingOrgComponent } from './presentation/modules/masterdata/plant-overview-change-shopping-org/plant-overview-change-shopping-org.component';
import { LandingPageComponent } from './presentation/landing-page/landing-page.component';

const routes: Routes = [
   {
      path: '',
      pathMatch: 'full',
      component: LandingPageComponent
   }, 
   {
      path: 'redirect',
      component: MsalRedirectComponent
   },        
   {         
      path: 'portal',
      pathMatch: 'prefix',
      component: AuthenticationWrapperComponent,
      canActivate: [
         MsalGuard
      ],        
      resolve: [
         GlobalResolver,
         MMRResolver
      ],                         
      children: [
         {
            path: '',
            pathMatch: 'full',
            component: DashboardComponent,   
         },
         {
            path: 'dashboard',
            pathMatch: 'full',
            component: DashboardComponent,       
         }, 
         {
            path: 'fields',
            pathMatch: 'full',
            component: FieldsComponent,
         }, 
         {
            path: 'codedocs',
            pathMatch: 'full',
            component: CodeDocsComponent,
         }, 
         {
            path: 'about',
            pathMatch: 'full',
            component: AboutComponent,
         }, 
         {
            path: 'masterdata',
            pathMatch: 'full',
            redirectTo: 'masterdata/plant-overview'
         }, 
         {
            path: 'masterdata/plant-overview-change-shopping-org',
            pathMatch: 'full',
            component: PlantOverviewChangeShoppingOrgComponent
         }, 
         {
            path: 'masterdata/plant-overview',
            pathMatch: 'full',
            component: PlantOverviewComponent
         }, 
         {
            path: 'masterdata/plant-detail/:id',
            component: PlantDetailComponent
         }, 
         {
            path: 'masterdata/product-detail/:id',
            component: ProductDetailComponent
         }, 
         {
            path: 'masterdata/spareparts/:url',
            component: SparepartsIframeComponent
         }, 
         {
            path: 'masterdata/analytics',
            component: AnalyticsIframeComponent
         }, 
         {
            path: 'documentation',
            pathMatch: 'prefix',
            loadChildren: () => import('./presentation/modules/documentation/documentation.module').then(m => m.DocumentationModule),
         }, 
         {
            path: 'admincenter',
            pathMatch: 'prefix',
            loadChildren: () => import('./presentation/modules/admin-center/admin-center.module').then(m => m.AdminCenterModule),
         }, 
         {
            path: 'usermanagement',
            pathMatch: 'prefix',
            loadChildren: () => import('./presentation/modules/user-management/user-management.module').then(m => m.UserManagementModule),
         }, 
         {
            path: 'servicebook',
            pathMatch: 'prefix',
            loadChildren: () => import('./presentation/modules/service-book/servicebook.module').then(m => m.ServicebookModule),
         }, 
         {
            path: 'smartTools',
            pathMatch: 'prefix',
            loadChildren: () => import('./presentation/modules/smart-tools/smart-tools.module').then(m => m.SmartToolsModule),
         }
      ]
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: []
})
export class AppRoutingModule { }
