import { ComponentDefinitionEntity, OptionModel } from "datahive-ngx-structure";
import { GroupedByFilterResult } from "../models/grouped-by-filter-results";
import { ServiceBookAggregatedResponse } from "../models/service-book-aggregated.response";

export class ServiceBookItemListResponse {
   ServiceBookAggregatedResponse: ServiceBookAggregatedResponse;
   GroupedSiteIds: GroupedByFilterResult[];
   EventItemComponentDefinition: ComponentDefinitionEntity;
   CanCreateEvents: boolean;
   CanCreateTickets: boolean;
}