import {
  MsalInterceptorConfiguration,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  LogLevel,
  IPublicClientApplication,
  PublicClientApplication,
  BrowserCacheLocation,
  InteractionType,
  AzureCloudOptions,
} from '@azure/msal-browser';
import { appConfiguration } from './core/app.configuration';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: appConfiguration.clientId,
      authority: appConfiguration.authority,
      redirectUri: '/',
      postLogoutRedirectUri: '/',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false,
      },
    },
  });
}
const representiveScope =
  'api://3c0d63b3-c2f9-4e4b-b0e8-b8fd0fa39e01/App.Use.Basic';
const representiveScopeDataHive =
  'api://0f5ff0f2-5a7f-430e-8ea1-6e133055990e/Products.PermissionGroup9';
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0', [
    'User.Read',
    'User.ReadBasic.All',
    'Files.ReadWrite.All',
    'Sites.ReadWrite.All',
  ]);
  protectedResourceMap.set('https://mixinggroup.sharepoint.com', [
    'https://mixinggroup.sharepoint.com/MyFiles.Read',
    'https://mixinggroup.sharepoint.com/Sites.Search.All',
  ]);
  protectedResourceMap.set('https://cp-admin-api-prod.azurewebsites.net', [
    representiveScope,
  ]);
  protectedResourceMap.set('https://cp-admin-api-test.azurewebsites.net', [
    representiveScope,
  ]);

  protectedResourceMap.set('https://cp-masterdata-api-prod.azurewebsites.net', [
    representiveScope,
  ]);
  protectedResourceMap.set('https://cp-masterdata-api-test.azurewebsites.net', [
    representiveScope,
  ]);

  protectedResourceMap.set(
    'https://cp-service-book-api-prod.azurewebsites.net',
    [representiveScope]
  );
  protectedResourceMap.set(
    'https://cp-service-book-api-test.azurewebsites.net',
    [representiveScope]
  );

  protectedResourceMap.set(
    'https://dh-product-structure-prod.azurewebsites.net',
    [representiveScopeDataHive]
  );
  protectedResourceMap.set('https://dh-enterprises-prod.azurewebsites.net', [
    representiveScopeDataHive,
  ]);

  protectedResourceMap.set('https://localhost:9300', [representiveScope]);
  protectedResourceMap.set('https://localhost:9200', [representiveScope]);
  protectedResourceMap.set('https://localhost:9400', [representiveScope]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
    },
    loginFailedRoute: '/login-failed',
  };
}
