<div [ngClass]="{ 'mmr-page-inner-hidden' : doneWithInitialLoading === false,
                  'mmr-page-inner-shown'  : doneWithInitialLoading === true }">

   <form [formGroup]="formGroup"
         *ngIf="eventItemFormPresenter.viewModel.GetEventItemFormResponse">
      <mat-card>
         <mat-card-header>
            <div mat-card-avatar>
               <mat-icon>question_answer</mat-icon>
            </div>
            <mat-card-title *ngIf="(formGroup.value.BasicInformation || formGroup.value.BasicInformation?.tickettype__LSP3Q === 0) && formGroup.value.BasicInformation?.tickettype__LSP3Q !== 1">View 24/7 Request</mat-card-title>
            <mat-card-title *ngIf="formGroup.value.BasicInformation?.tickettype__LSP3Q === 1">View site service request</mat-card-title>
            <mat-card-subtitle>View only mode</mat-card-subtitle>
         </mat-card-header>      
         <mat-card-content>
            <div class="bs-form">             
               <mat-form-field class="bs-default-control">
                  <mat-label>Select a plant</mat-label>
                  <mat-select formControlName="SiteId"
                              (selectionChange)="setSelectedSite()"
                              color="primary">
                     <ng-container *ngFor="let site of eventItemFormPresenter.viewModel.GetEventItemFormResponse.SitesWithReadWritePermission">
                        <mat-option [value]="site.SiteId">
                           {{site.Name}}
                        </mat-option>
                     </ng-container>
                  </mat-select>
               </mat-form-field>           
               <mat-form-field class="bs-default-control"
                              *ngIf="selectedSite">
                  <mat-label>Select a line</mat-label>
                  <mat-select formControlName="LineIds"
                              multiple
                              (selectionChange)="setSelectedLine()"
                              color="primary">
                     <ng-container *ngFor="let line of selectedSite.Lines" >
                        <mat-option [value]="line.Id">
                           {{line.Name}}
                        </mat-option>
                     </ng-container>
                  </mat-select>
               </mat-form-field>            
               <mat-form-field class="bs-default-control"
                              *ngIf="selectedLines">
                  <mat-label>Select a product</mat-label>
                  <mat-select formControlName="ProductIds"
                              multiple
                              color="primary">
                     <ng-container *ngFor="let product of availableProducts" >
                        <mat-option [value]="product.ProductId">
                           {{getProductName(product)}}
                        </mat-option>
                     </ng-container>
                  </mat-select>
               </mat-form-field>  
               
               <app-bs-textfield formControlName="Name"  
                                 [placeholder]="'Name'">
               </app-bs-textfield>  
               
               Description:<br>
               <textarea class="bs-textarea"
                         style="width: 100%;"
                         formControlName="Description"
                         matRipple>
               </textarea>               
               
               <app-component-definition-controls [componentDefinition]="eventItemFormPresenter.viewModel?.GetEventItemFormResponse?.ComponentDefinition"
                                                  [ignoreIds]="['status']"
                                                  formControlName="BasicInformation">  
               </app-component-definition-controls>      
               
               <ng-container>
                  <h3>
                     <a [href]="eventItemFormPresenter.viewModel.GetEventItemFormResponse.Twenty47RequestEntity?.FilesUrl" 
                         target="_blank">
                         Files:
                     </a>
                  </h3>    
                               
                  <mgt-file-list enable-file-upload
                                 [itemId]="eventItemFormPresenter.viewModel.GetEventItemFormResponse.Twenty47RequestEntity.FolderId"
                                 [driveId]="eventItemFormPresenter.viewModel.GetEventItemFormResponse.Twenty47RequestEntity.LibraryId"
                                 (itemClick)="openSharePoint($event)">
                  </mgt-file-list>  
               </ng-container>
            </div>
            <br>
            <app-ticket-comments [comments]="eventItemFormPresenter.viewModel.GetEventItemFormResponse.Twenty47RequestEntity.Comments">
            </app-ticket-comments>
         </mat-card-content>
         <mat-card-actions>             
            <button mat-raised-button 
                  type="button"
                  color="primary"                 
                  (click)="goBack()">
               Go back
            </button>                         
         </mat-card-actions>
      </mat-card>
   </form>

</div>
<div *ngIf="doneWithInitialLoading === false"
   class="bs-page-loading">
   <mat-spinner [diameter]="40" class="mmr-page-loading-animation"></mat-spinner>
</div>