import { EventItemFormPresenter } from "src/app/core/output-boundaries/event-item-form.presenter";
import { ServiceBookItemsPresenter } from "src/app/core/output-boundaries/service-book-items.presenter";
import { GetEventItemFormResponse } from "src/app/core/output-data/get-event-item-form.response";
import { EventItemFormViewModel } from "./event-item-view.view-model";

export class EventItemViewPresenterService
       extends EventItemFormPresenter<EventItemFormViewModel> {
   constructor() {
      super(EventItemFormViewModel);
   }
   public showForm(response: GetEventItemFormResponse): void {
      this.viewModel.GetEventItemFormResponse = response;
   }
   public showCreateResult(id: string) {
      this.viewModel.NewId = id;
   }
}
