import { LineWithContent } from "../entities/line-with-content";
import { ProductEntity } from "../entities/product.entity";
import { SiteWithContent } from "../entities/site-with-content";
import { MMRGlobals } from "./mmr-globals";

export class DocumentFilterConfiguration {
   filterText = '';
   enableMaintenanceDocumentation = false;
   selectedDocumentTypeFilterFlags: boolean[] = [];
   selectedTagFilterFlags: boolean[] = [];
   selectedPlantFlags: boolean[] = [];
   selectedLineFlags: boolean[] = [];
   selectedProductFlags: boolean[] = [];
   selectedProductPartFlags: boolean[] = [];    
   
   preselectedLineId: string;
   preselectedProductId: string;
   cacheContainsSingleSite = false;
   constructor() {
      this.selectedDocumentTypeFilterFlags = [];
      this.selectedTagFilterFlags = [];
      this.selectedPlantFlags = [];
      this.selectedLineFlags = [];
      this.selectedProductFlags = [];
      this.selectedProductPartFlags = [];
   }
   public reset() {
      if(!this.cacheContainsSingleSite) {
         this.selectedPlantFlags = [];
      }
      this.selectedDocumentTypeFilterFlags = [];
      this.selectedTagFilterFlags = [];
      this.selectedLineFlags = [];
      this.selectedProductFlags = [];
      this.selectedProductPartFlags = [];
   }
   public isSet() {
      if(this.cacheContainsSingleSite) {
         return this.selectedDocumentTypeFilterFlags.filter(flag => flag).length > 0 ||
         this.selectedLineFlags.filter(flag => flag).length > 0 ||
         this.selectedTagFilterFlags.filter(flag => flag).length > 0 ||
         this.selectedProductFlags.filter(flag => flag).length > 0 ||
         this.selectedProductPartFlags.filter(flag => flag).length > 0;
      }
      return this.selectedDocumentTypeFilterFlags.filter(flag => flag).length > 0 ||
             this.selectedPlantFlags.filter(flag => flag).length > 0 ||
             this.selectedLineFlags.filter(flag => flag).length > 0 ||
             this.selectedTagFilterFlags.filter(flag => flag).length > 0 ||
             this.selectedProductFlags.filter(flag => flag).length > 0 ||
             this.selectedProductPartFlags.filter(flag => flag).length > 0;
   }
   public setForProductId(productId: string, allSites: SiteWithContent[]) {
      let cnt = 0;
      let selectedSite: SiteWithContent;
      let selectedProduct: ProductEntity;
      for(const site of allSites) {
         const product = site.getProductById(productId);
         if(product) {
            selectedSite = site;
            selectedProduct = product;
            break;
         }
         cnt++;
      }
      for(let i = 0; i < cnt; i++) {
         this.selectedPlantFlags.push(false);                   
      }
      this.selectedPlantFlags.push(true);
      let selectedLine: LineWithContent;      
      if(selectedSite.Lines) {
         for(const line of selectedSite.Lines) {
            if(line.Id === selectedProduct.LineId) {
               selectedLine = line;
               break;
            }
            cnt++;
         }
         cnt = 0;
         for(let i = 0; i <= cnt; i++) {
            this.selectedLineFlags.push(false);          
         }
         /** The 'no line' */
         this.selectedLineFlags.push(true);
      } 
      if(selectedLine) {
         cnt = 0;
         for(const product of selectedLine.Products) {
            if(product.ProductId === productId) {
               break;
            }
            cnt++;
         }
         for(let i = 0; i < cnt; i++) {
            this.selectedProductFlags.push(false);          
         }
         this.selectedProductFlags.push(true);          
      }      
   }
}