import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, UntypedFormControl } from '@angular/forms';
import { GroupedByFilterResult } from 'src/app/core/models/grouped-by-filter-results';

@Component({
  selector: 'app-filter-result-control',
  templateUrl: './filter-result-control.component.html',
  styleUrls: ['./filter-result-control.component.scss'],
  providers: [
     {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => FilterResultControlComponent),
        multi: true,
     },
     {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => FilterResultControlComponent),
        multi: true
     }
  ]
})
export class FilterResultControlComponent 
       implements OnInit {
   @Input() options: GroupedByFilterResult[];
   @Input() label = '';
   @Output() selectionChanged = new EventEmitter();       
   flags: boolean[] = [];
   constructor() { }

   ngOnInit(): void {
   }
   public publish() {
      const resultIds = [];
      let cnt = 0;
      for(const flag of this.flags) {
         if(flag) {
            resultIds.push(this.options[cnt].Id);
         }
         cnt++;
      }
      this.propagateChange(resultIds);
      this.selectionChanged.emit(resultIds);
   }
   public propagateChange = _ => { };
   public writeValue(selectedIds: string[]) {
      if(selectedIds && this.options) {
         this.flags = [];
         let cnt = 0;
         for(const option of this.options) {
            if(selectedIds.filter(id => id === option.Id).length > 0) {
               this.flags[cnt] = true;
            } else {
               this.flags[cnt] = false;
            }            
            cnt++;
         }
      }
   }
   public validate(formControl: UntypedFormControl) {
      return null;
   }
   public registerOnChange(fn: any) {
      this.propagateChange = fn;
   }
   public registerOnTouched() { }
}
