import { PortalUserEntity } from "../portal-user.entity";
import { ServiceBookItem } from "./service-book-item";

export class SiteServiceRequestEntity  
       extends ServiceBookItem {
   public hasWritePermission(portalUser: PortalUserEntity) {
      return false;
   }
   public hasReadPermission(portalUser: PortalUserEntity) {
      return false;
   }
   public returnName() {
      return 'site service request';
   }
   public returnIcon() {
      return 'send';
   }
   public returnRoute() {
      return 'siteServiceRequestForm';
   }
}