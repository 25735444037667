import { PortalUserEntity } from "../portal-user.entity";
import { ServiceBookItem } from "./service-book-item";

export class EventItemEntity 
       extends ServiceBookItem {
   public hasWritePermission(portalUser: PortalUserEntity) {
      if(portalUser.Internal) {
         return this.hasWritePermissionInternal(portalUser);
      } else {
         return this.hasWritePermissionExternal(portalUser);
      }      
   }
   private hasWritePermissionInternal(portalUser: PortalUserEntity) {
      if(portalUser.IsGlobalAdmin) { return true; }
      return this.hasWritePermissionExternal(portalUser);
   }
   private hasWritePermissionExternal(portalUser: PortalUserEntity) {
      if(this.CustomerEvent) {
         return portalUser.hasPermissionForSite('CustomerEvents.ReadWrite', this.SiteId);
      } else {
         return portalUser.hasPermissionForSite('SupplierEvents.ReadWrite', this.SiteId);
      }   
   }
   public hasReadPermission(portalUser: PortalUserEntity) {
      if(portalUser.Internal) {
         return this.hasReadPermissionInternal(portalUser);
      } else {
         return this.hasReadPermissionExternal(portalUser);
      } 
   }
   private hasReadPermissionInternal(portalUser: PortalUserEntity) {
      if(portalUser.IsGlobalAdmin) { return true; }
      return this.hasReadPermissionExternal(portalUser);
   }
   private hasReadPermissionExternal(portalUser: PortalUserEntity) {
      if(this.CustomerEvent) {
         let readWrite = portalUser.hasPermissionForSite('CustomerEvents.ReadWrite', this.SiteId);
         let read = portalUser.hasPermissionForSite('CustomerEvents.Read', this.SiteId);
         return readWrite || read;
      } else {
         let readWrite =  portalUser.hasPermissionForSite('SupplierEvents.ReadWrite', this.SiteId);
         let read =  portalUser.hasPermissionForSite('SupplierEvents.Read', this.SiteId);
         return readWrite || read;
      }   
   }   
   public returnName() {
      return 'event';
   }
   public returnIcon() {
      return 'event_available';
   }
   public returnRoute() {
      return 'eventItemForm';
   }    
}