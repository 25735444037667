import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { IUseCase } from "../../architecture/use-case";
import { Twenty47RequestCreateRequest } from "../../input-data/service-book/twenty47-request-create.request";
import { EventItemFormPresenter } from "../../output-boundaries/event-item-form.presenter";
import { Twenty47RequestFormPresenter } from "../../output-boundaries/twenty47-request-form.presenter";
import { ServiceBookRepository } from "../../repositories/service-book.repository";

@Injectable({providedIn: 'root'})
export class Twenty47RequestCreateUseCaseService
       implements IUseCase<Twenty47RequestCreateRequest, Twenty47RequestFormPresenter<any>> {

   requestCancelationToken: {request: Subscription } = {request: null};
   constructor(public serviceBookRepository: ServiceBookRepository,
               public presenter: Twenty47RequestFormPresenter<any>) {}

   public async execute(request: Twenty47RequestCreateRequest): Promise<void> {
      const result = await this.serviceBookRepository.createTwenty47Request(request.Twenty47RequestEntity);
      this.presenter.showCreateResult(result);
   }
}
