<div class="mmr-text-content mmr-min-page-min-height">
   <h1>About MyMixingRoom</h1>
   
   <br>
   <h2>App Version 1.0.0 - Team 4.1</h2>

   <ul>
      <li>Reinhard Jenne</li>
      <li>Stefano Canegallo</li>
      <li>Georg Linde</li>
      <li>David Elsner</li>
      <li>Sebastian Schwolle</li>
      <li>Peter Hummel</li>
   </ul>
   <br>
   <h2>App Version 0.2.0 - Team 3.1</h2>
   <ul>
      <li>Reinhard Jenne</li>
      <li>Burkhard Wien</li>
      <li>David Elsner</li>
      <li>Stefano Canegallo</li>
      <li>Gareth  Davies</li>
      <li>Dominic Weinaug</li>
      <li>Yury Kosykh</li>
      <li>Natalia Reyes</li>
   </ul> 
   <br>
   <h2>Team Concept:</h2>
   
   <ul>
      <li>Reinhard Jenne</li>
      <li>David Elsner</li>
      <li>Burkhard Wien</li>
   </ul>
</div>