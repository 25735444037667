import { Injectable } from "@angular/core";
import { ComponentDefinitionEntity } from "datahive-ngx-structure";
import { ProductCategoryEntity } from "../entities/product-category.entity";
import { ComponentDefinitionRepository } from "../repositories/component-definition.repository";
import { ProductCategoryRepository } from "../repositories/product-category.repository";

@Injectable({providedIn: 'root'})
export class MasterdataCache {
   siteServiceRequestComponentDefinition: ComponentDefinitionEntity;
   twenty47RequestComponentDefinition: ComponentDefinitionEntity;
   itemEventRequestComponentDefinition: ComponentDefinitionEntity;

   allComponentDefinitionEntities: ComponentDefinitionEntity[];

   allProductCategories: ProductCategoryEntity[];
   constructor(public componentDefinitionRepository: ComponentDefinitionRepository,
               public productCategoryRepository: ProductCategoryRepository) {
      
   }
   public async initCache() {
      this.allComponentDefinitionEntities = await this.componentDefinitionRepository.getAll();

      if(!this.itemEventRequestComponentDefinition) {
         this.itemEventRequestComponentDefinition = this.allComponentDefinitionEntities.filter(def => def.EntityId === 'mmrevent')[0];
      }
      if(!this.siteServiceRequestComponentDefinition) {
         this.siteServiceRequestComponentDefinition = this.allComponentDefinitionEntities.filter(def => def.EntityId === 'mmrsiteservicerequest')[0];
      }
      if(!this.twenty47RequestComponentDefinition) {
         this.twenty47RequestComponentDefinition = this.allComponentDefinitionEntities.filter(def => def.EntityId === 'mmr247support')[0];
      }    
      if(!this.allProductCategories) {
         this.allProductCategories = await this.productCategoryRepository.getAll();
      }  
   }
   public getProductCategoryById(id: string) {
      return this.allProductCategories.filter(cat => (cat as any).Id === id)[0];
   }
   public getComponentDefinitionByEntityId(id: string) {
      return this.allComponentDefinitionEntities.filter(def => def.EntityId === id)[0];
   }
   public async getSiteServiceRequestComponentDefinition() {
      await this.initCache();
      return this.siteServiceRequestComponentDefinition;
   }
   public async getTwenty47RequestComponentDefinition() {
      await this.initCache();
      return this.twenty47RequestComponentDefinition;
   }
   public async getItemEventRequestComponentDefinition() {
      await this.initCache();
      return this.itemEventRequestComponentDefinition;
   }
}