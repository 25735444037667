import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-map-stats-indicator',
  templateUrl: './map-stats-indicator.component.html',
  styleUrls: ['./map-stats-indicator.component.scss']
})
export class MapStatsIndicatorComponent implements OnInit {

   @Input() numberOfCustomers;
   @Input() numberOfCustomerSites;
   @Input() numberOfLines;
   @Input() numberOfProducts;
   @Input() numberOfUsers;

   constructor() { }

   ngOnInit(): void {
   }

}
