<mat-card class="mmr-flex-card mmr-white-card">
   <mat-card-header>
      <div mat-card-avatar>
         <mat-icon>location_city</mat-icon>
      </div>
      <mat-card-title *ngIf="allSites && allSites.length > 0">Change shopping organization for {{allSites[0].EnterpriseName}}</mat-card-title>
      <mat-card-subtitle>Click on the plant you want to set as shopping organization</mat-card-subtitle>
   </mat-card-header>      
   <mat-card-content>
      <div class="mmr-plant-outer">
         <mat-card *ngFor="let site of allSites"
                  class="mmr-action-card"
                  (click)="updateShopOrganizationForUser(site.SiteId, site.Name, $event)">
            <mat-card-header>
               <div mat-card-avatar>
                  <mat-icon>corporate_fare</mat-icon>
               </div>
               <mat-card-title>{{site.Name}}</mat-card-title>
               <mat-card-subtitle>{{site.getLineCount()}} Lines | {{site.getProductsCount()}} Products</mat-card-subtitle>
            </mat-card-header>   
            <mat-card-content>
               <mat-icon class="mmr-shopping-indicator mmr-shopping-indicator-active"
                         *ngIf="globals.currentCustomerPortalProfile.AppSettings?.ActiveSiteIdForShop === site.SiteId">
                  shopping_cart
               </mat-icon>
               <mat-icon class="mmr-shopping-indicator mmr-shopping-indicator-inactive"                         
                         *ngIf="globals.currentCustomerPortalProfile.AppSettings?.ActiveSiteIdForShop !== site.SiteId &&
                                isBuyer(site)">
                  shopping_cart
               </mat-icon>             
            </mat-card-content>
         </mat-card>
         <div class="clear-both"></div>
      </div>

   </mat-card-content>
</mat-card>
