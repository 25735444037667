import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar-error',
  templateUrl: './snack-bar-error.component.html',
  styleUrls: ['./snack-bar-error.component.scss']
})
export class SnackBarErrorComponent
       implements OnInit {
    errorMessage = '';
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }

    ngOnInit(): void {
      this.errorMessage = this.data.errorMessage;
    }

}
