import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MsalModule,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FooterComponent } from './presentation/footer/footer.component';
import { SharedModule } from './presentation/modules/shared/shared.module';
import { DashboardComponent } from './presentation/dashboard/dashboard.component';
import { MMRGlobals } from './core/services/mmr-globals';
import { SharePointRepository } from './core/repositories/sharepoint.repository';
import { SharePointRepositoryService } from './data/sharepoint-repository.service';
import { MasterdataRepository } from './core/repositories/masterdata.repository';
import { MasterdataRepositoryService } from './data/masterdata.repository.service';
import { MMRHttpClient } from './core/shared/mmr-http-client';
import { CustomerRepository } from './core/repositories/customer.repository';
import { CustomerRepositoryService } from './data/customer-repository.service';
import { PlantRepositoryService } from './data/plant-repository.service';
import { PlantRepository } from './core/repositories/plant.repository';
import { AboutComponent } from './presentation/about/about.component';
import { NotfoundComponent } from './presentation/notfound/notfound.component';
import { ServiceBookRepository } from './core/repositories/service-book.repository';
import { ServiceBookRepositoryService } from './data/service-book.repository.service';
import { ComponentDefinitionRepository } from './core/repositories/component-definition.repository';
import { ComponentDefinitionRepositoryService } from './data/component-definition.repository.service';
import { FieldsComponent } from './presentation/fields/fields.component';
import { MasterdataSiteRepository } from './core/repositories/masterdata-site.repository';
import { MasterdataSiteRepositoryService } from './data/masterdata-site.repository-service';
import { AreaRepositoryService } from './data/area-repository.service';
import { AreaRepository } from './core/services/area.repository';
import { CodeDocsComponent } from './presentation/documentation/codedocs.component';
import { ProductCategoryRepository } from './core/repositories/product-category.repository';
import { ProductCategoryRepositoryService } from './data/product-category.repository.service';
import { PlantOverviewComponent } from './presentation/modules/masterdata/plant-overview/plant-overview.component';
import { PlantDetailComponent } from './presentation/modules/masterdata/plant-detail/plant-detail.component';
import { ProductDetailComponent } from './presentation/modules/masterdata/product-detail/product-detail.component';
import { ProductDetailsComponent } from './presentation/modules/masterdata/product-details/product-details.component';
import { ProductTileComponent } from './presentation/modules/masterdata/product-tile/product-tile.component';
import { AgLineActionsCellRendererComponent } from './presentation/modules/masterdata/plant-detail/ag-line-actions-cell-renderer/ag-line-actions-cell-renderer.component';
import { AnalyticsIframeComponent } from './presentation/modules/masterdata/analytics-iframe/analytics-iframe.component';
import { AdminRepository } from './core/repositories/admin.repository';
import { AdminRepositoryService } from './data/admin.repository.service';
import { SparepartsIframeComponent } from './presentation/modules/masterdata/spareparts-iframe/spareparts-iframe.component';
import { LandingPageComponent } from './presentation/landing-page/landing-page.component';
import { AuthenticationWrapperComponent } from './presentation/authentication-wrapper/authentication-wrapper.component';
import { GraphRepository } from './core/repositories/graph.repository';
import { GraphRepositoryService } from './data/graph-repository.service';
import { ServicebookModule } from './presentation/modules/service-book/servicebook.module';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import {
  MSALInstanceFactory,
  MSALGuardConfigFactory,
  MSALInterceptorConfigFactory,
} from './msal-functions';
import { MMRResolver } from './resolver/mmr-resolver';
import { GlobalResolver } from './resolver/global-resolver';
import { HfmgHeaderComponent } from './presentation/hfmg-header/hfmg-header.component';
import { PlantOverviewChangeShoppingOrgComponent } from './presentation/modules/masterdata/plant-overview-change-shopping-org/plant-overview-change-shopping-org.component';
import { AgGridModule } from 'ag-grid-angular';
import { MatomoModule } from 'ngx-matomo';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    DashboardComponent,
    AboutComponent,
    NotfoundComponent,
    FieldsComponent,
    CodeDocsComponent,

    /** Masterdata */
    PlantOverviewComponent,
    PlantOverviewChangeShoppingOrgComponent,
    ProductDetailsComponent,
    PlantDetailComponent,
    ProductDetailComponent,
    ProductTileComponent,
    SparepartsIframeComponent,

    AgLineActionsCellRendererComponent,
    AnalyticsIframeComponent,
    LandingPageComponent,
    AuthenticationWrapperComponent,
    HfmgHeaderComponent,
  ],
  imports: [
    AgGridModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MsalModule,
    RouterModule,
    SharedModule,
    ServicebookModule,
    MatomoModule.forRoot({
      scriptUrl: '//mymixingroom.matomo.cloud/matomo.js',
      trackers: [
        {
          trackerUrl: 'https://mymixingroom.matomo.cloud/matomo.php',
          siteId: 1,
        },
      ],
      routeTracking: {
        enable: true,
      },
    }),
  ],
  providers: [
    GlobalResolver,
    MMRResolver,
    MMRHttpClient,
    MMRGlobals,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    { provide: SharePointRepository, useClass: SharePointRepositoryService },
    { provide: MasterdataRepository, useClass: MasterdataRepositoryService },
    { provide: CustomerRepository, useClass: CustomerRepositoryService },
    { provide: AdminRepository, useClass: AdminRepositoryService },
    {
      provide: MasterdataSiteRepository,
      useClass: MasterdataSiteRepositoryService,
    },
    { provide: AreaRepository, useClass: AreaRepositoryService },
    { provide: GraphRepository, useClass: GraphRepositoryService },
    { provide: PlantRepository, useClass: PlantRepositoryService },
    {
      provide: ProductCategoryRepository,
      useClass: ProductCategoryRepositoryService,
    },
    { provide: ServiceBookRepository, useClass: ServiceBookRepositoryService },
    {
      provide: ComponentDefinitionRepository,
      useClass: ComponentDefinitionRepositoryService,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
