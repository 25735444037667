<div class="mmr-item-outer">
   <div class="mmr-icon">
      <mat-icon>{{icon}}</mat-icon>
   </div>
   <div class="mmr-attachment"
        *ngIf="item.HasAttachment">
      <mat-icon>attach_file</mat-icon>
   </div>      
   <div class="mmr-title"
        [class.mmr-deleted]="item?.DeletedByCustomer"
        [class.mmr-supplier-event]="item?.CustomerEvent">
      <ng-container *ngIf="item?.DeletedByCustomer">
         [DELETED]
      </ng-container>        
      <a (click)="goToView()">{{title}}</a>
   </div>   
   <div class="clear-both"></div>
</div>