import { Injectable } from "@angular/core";
import { ProductCategoryEntity } from "../core/entities/product-category.entity";
import { ProductCategoryRepository } from "../core/repositories/product-category.repository";
import { MMRGlobals } from "../core/services/mmr-globals";
import { MMRHttpClient } from "../core/shared/mmr-http-client";

@Injectable()
export class ProductCategoryRepositoryService
       implements ProductCategoryRepository {
    
    private apiPrefix = 'productcategories';
    constructor(private bsGlobals: MMRGlobals,
                private http: MMRHttpClient) {

    }
    public async getById(id: string, renderOptions: boolean): Promise<ProductCategoryEntity> {
        const productCategoryDefinition = await this.http.get<ProductCategoryEntity>(this.bsGlobals.baseUriProductStructure + this.apiPrefix + '/' + id + '?renderOptions=' + renderOptions);
        return productCategoryDefinition;
    }
    public async getAll(): Promise<ProductCategoryEntity[]> {
        const productCategoryDefinitions = await this.http.get<ProductCategoryEntity[]>(this.bsGlobals.baseUriProductStructure + this.apiPrefix);
        return productCategoryDefinitions;
    }
}
