
import { ComponentDefinitionEntity } from "datahive-ngx-structure";
import { EventItemEntity } from "../entities/service-book/event-item";
import { SiteWithContent } from "../entities/site-with-content";

export class GetEventItemFormResponse {
   EventItemEntity: EventItemEntity;
   FormHeadline: string;
   ComponentDefinition: ComponentDefinitionEntity;
   SitesWithReadWritePermission: SiteWithContent[];
   CanChangeEvent: boolean;
}