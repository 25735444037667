import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GridOptions } from 'ag-grid-community';
import { objectAsIterableArray } from 'datahive-basic';
import { BehaviorSubject, Subscription } from 'rxjs';

export abstract class AGGridBaseOverviewComponent {
   gridOptions: GridOptions;
   quickFilter: string;
   protected requestCancelationToken: {request: Subscription } = {request: null};
   frameworkComponents;
   /** For master detail: Stores in an array of booleans which rows have been expanded. The object is used to render the arrow in the correct way. */
   expandedIds: any;
   /** Is always fired if the grid data is changed, to collapse all expanded rows automatically. 
    * Only needed for grids that can be expanded.
   */
   gridDataChanged = new BehaviorSubject<void>(null);
   constructor(public dialog: MatDialog = null) {
      this.quickFilter = ''; 
   }
   abstract initGridOptions();
   public async storeSettings() { }     
   async onSelect(grid) {}
   abstract refreshView(param: string|any);
   public refreshQuickfilter() {
      this.expandedIds = {};
      if (this.gridOptions && this.gridOptions.api) {
         this.gridOptions.api.setQuickFilter(this.quickFilter);
      }
   }
   public cancelRequest() {
      if (this.requestCancelationToken && this.requestCancelationToken.request) {
         this.requestCancelationToken.request.unsubscribe();
      }
   }      
   protected getFilterModel() {
      const newFilterModel = {};
      if(!this.gridOptions.api) {
         return newFilterModel;
      }
      const agFilterModel = this.gridOptions.api.getFilterModel();      
      if(agFilterModel) {              
         objectAsIterableArray(agFilterModel).forEach((filter: any) => {
            const newColId = filter.dicKey.split('.');
            const propertyId = newColId[newColId.length - 1]
            newFilterModel[propertyId] = filter.dicValue;
         });
      }      
      return newFilterModel;
   }
   protected getSortModel() {
      const state = this.gridOptions.columnApi.getColumnState();
      const newSortModel = {};
      state.forEach(state => {
         if(state.sort) {
            newSortModel[state.colId] = state.sort;
         }         
      });
      return newSortModel;
   }   
   public isFullWidth(node): boolean {
      if (node && node.data && node.data.isFullWidth) {
         return true;
      }
      return false;
   }
}
