<div class="mmr-product-detail-outer">   
   <mat-card class="mmr-product-info-tile">
      <mat-card-header>
         <div mat-card-avatar>
            <mat-icon>info</mat-icon>
         </div>
         <mat-card-title>{{product.ProductTypeName}}</mat-card-title>
         <mat-card-subtitle>Find your technical data and documentation of {{product.ProductTypeName}}</mat-card-subtitle>
      </mat-card-header>         
      <mat-card-content class="mmr-product-info">
         <div class="mmr-product-image">
            <img [src]="product.ImagePath" class="mmr-product-image">
         </div>
         <div class="mmr-product-labels">
            <div class="mmr-product-values">
               <div class="mmr-product-label">Serial number</div>
               <div class="mmr-product-value">{{getSerialNumberFromProduct(product)}}</div>
            </div>
      
            <div class="mmr-product-values">
               <div class="mmr-product-label">Category</div>
               <div class="mmr-product-value">{{product.ProductCategoryName}}</div>      
            </div>
         </div>
         <!-- <app-product-details [product]="product"
                              [changelogItems]="changelogItems">
         </app-product-details> -->         
      </mat-card-content>
      <mat-card-actions>
         <button mat-raised-button
                 color="primary"
                 (click)="navigateToServiceBookForProduct()">
            Service Book
         </button>
         <button mat-raised-button
                 color="primary"   
                 [disabled]="!(results && results.Results && results.Results.length > 0)"
                 (click)="navigateToMaintenanceDocumentation(globals, router, product.SiteId, product.LineId, product.ProductId)">
            Maintenance Documentation
         </button>
         <button mat-raised-button
                 color="primary"
                 (click)="navigateToCreate247Support()">
            24/7 Support
         </button>
         <button mat-raised-button
                 color="primary"
                 (click)="navigateToSiteServiceRequest()">
            Site Service
         </button>                            
      </mat-card-actions>      
   </mat-card>
   <mat-card class="mmr-product-documents mmr-flex-card">
      <mat-card-header>
         <div mat-card-avatar>
            <mat-icon>folder_open</mat-icon>
         </div>
         <mat-card-title>Documents</mat-card-title>
         <mat-card-subtitle>Details of this {{product.ProductTypeName}}</mat-card-subtitle>
      </mat-card-header> 
      <div class="mmr-filter-inner">
         <div class="mmr-filter-head">
            <button mat-raised-button 
                  color="primary"  
                  [disabled]="!product.FileLocation"
                  (click)="openSharePointLib()">
               <mat-icon>upload_file</mat-icon>               
               Open Library
            </button>
            <!-- <button mat-raised-button 
                  color="primary"  
                  class="mmr-button-margin"
                  (click)="showInFilter()">
               <mat-icon>fullscreen</mat-icon>               
               Fullscreen
            </button>  -->
         </div>         
         <div class="mmr-documents">
            <mat-form-field class="mmr-search-input"
                           appearance="fill">
               <mat-label>Search</mat-label>
               <input matInput 
                     type="text" 
                     #searchText 
                     (keyup)="search(searchText.value)">
            </mat-form-field>  
            <ag-grid-angular class="ag-theme-material"
                             style="width: 100%; height:500rem;"
                             [gridOptions]="gridOptions">
            </ag-grid-angular> 
         </div>         
      </div>

   </mat-card>
</div>
