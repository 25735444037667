import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/core/services/layout.service';
import { NavigationService } from '../modules/website/navigation.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent
       implements OnInit {

   year: number = new Date().getFullYear();
   constructor(public layoutService: LayoutService,
               public navigationService: NavigationService) { }

   ngOnInit(): void {

   }
}
