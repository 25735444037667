
import { ComponentDefinitionEntity } from "datahive-ngx-structure";
import { Twenty47RequestEntity } from "../entities/service-book/twenty47-request";
import { SiteWithContent } from "../entities/site-with-content";

export class GetTwenty47RequestFormResponse {
   Twenty47RequestEntity: Twenty47RequestEntity;
   FormHeadline: string;
   ComponentDefinition: ComponentDefinitionEntity;
   SitesWithReadWritePermission: SiteWithContent[];
}