import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MMRGlobals } from 'src/app/core/services/mmr-globals';

@Component({
  selector: 'app-analytics-iframe',
  templateUrl: './analytics-iframe.component.html',
  styleUrls: ['./analytics-iframe.component.scss']
})
export class AnalyticsIframeComponent 
       implements OnInit {
  
   url='';
   constructor(public activatedRoute: ActivatedRoute,
               public globals: MMRGlobals) { }

   ngOnInit(): void {
   this.setUrl();
   }
   setUrl() {
   const urlParam = this.activatedRoute.snapshot.queryParams.url;
   this.url = decodeURIComponent(urlParam);
   }
}
