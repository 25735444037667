import {Component} from '@angular/core';
import { Router } from '@angular/router';
import { LineWithContent } from 'src/app/core/entities/line-with-content';
import { MMRGlobals } from 'src/app/core/services/mmr-globals';

@Component({
   templateUrl : './ag-line-actions-cell-renderer.component.html',
   styleUrls: ['./ag-line-actions-cell-renderer.component.scss']
})
export class AgLineActionsCellRendererComponent {
   line: LineWithContent;
   isOtherProductsLine = false;
   sparepartsUrl: string;
   constructor(public globals: MMRGlobals,
               public router: Router) {

   }
   public agInit(params: any): void {
      if (params) {
         this.line = params.data;     
         if(this.line.BasicInformation && this.line.BasicInformation['spareparts_url']) {
            this.sparepartsUrl = this.line.BasicInformation['spareparts_url'];
         }     
         if(!this.line.Id) {
            this.isOtherProductsLine = true;
         }
      }
   }
   public openSparepartsUrl(event) {
      if(event) {
         event.stopPropagation();
         event.preventDefault();
      }      
      //this.router.navigate(['masterdata', 'spareparts', this.sparepartsUrl]);
      this.globals.trackEvent('OpenSpareParts', this.line.Name);
      window.open(this.sparepartsUrl, '_blank');      
   }
   public stopPropagation(event) {
      if(event) {
         event.stopPropagation();
         event.preventDefault();
      }
   }
   public getUrlForIFrame(url: string, fragment: string) {
      if(!url) return '';
      //Only to show technical center in Demo:
      {
         url = url.replace('processcell=5f92beda33c63d166489d981', 'processcell=607823832965ac354004f99e');
      }        
      url = url.replace('PAGE_NAME_TO_REPLACE_BY_CLIENT', fragment);
      return encodeURIComponent(url);
   }
}
