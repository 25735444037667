import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { CustomerEntity } from '../core/entities/customer.entity';
import { QueryRequest } from '../core/input-data/query.request';
import { CustomerRepository } from '../core/repositories/customer.repository';
import { MMRGlobals } from '../core/services/mmr-globals';
import { MMRHttpClient } from '../core/shared/mmr-http-client';

@Injectable()
export class CustomerRepositoryService
       implements CustomerRepository {

   constructor(private globals: MMRGlobals,
               private http: MMRHttpClient) {}

   public async searchEnterprises(query: QueryRequest, requestReference: {request: Subscription}): Promise<CustomerEntity[]> {
      const enterprises = await this.http.post<QueryRequest, CustomerEntity[]>(this.globals.baseUriEnterprises + 'customers/search', query, requestReference);            
      return enterprises;
   }  
   public async getById(enterpriseId: string, renderOptions = true): Promise<CustomerEntity> {
      const enterprise: CustomerEntity = await this.http.get<CustomerEntity>(this.globals.baseUriEnterprises + 'customers/' + enterpriseId + '?renderOptions=' + renderOptions);            
      return enterprise;
   }
   public async getByIdWithChangelog(enterpriseId: string, renderOptions = true): Promise<CustomerEntity[]> {
      const enterprises: CustomerEntity[] = await this.http.get<CustomerEntity[]>(this.globals.baseUriEnterprises + 'customers/' + enterpriseId + '/changelog?renderOptions=' + renderOptions);            
      return enterprises;
   }   
}
