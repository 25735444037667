<div [ngClass]="{ 'mmr-page-inner-hidden' : doneWithInitialLoading === false,
                  'mmr-page-inner-shown' : doneWithInitialLoading === true }">

   <mat-card class="mmr-flex-card">
      <mat-card-header>
         <div mat-card-avatar>
            <mat-icon>menu_book</mat-icon>
         </div>
         <mat-card-title>Service book</mat-card-title>
         <mat-card-subtitle>Open tickets, add events, request a site service</mat-card-subtitle>
      </mat-card-header>      
      <mat-card-content>
         <div class="mmr-service-book-item-list-head">
            <button mat-raised-button 
                    color="primary"   
                    *ngIf="presenter.viewModel?.response?.CanCreateEvents"
                    (click)="createEventItem()">
               <mat-icon>event</mat-icon>                  
               New event
            </button>         
            <button mat-raised-button 
                    color="primary" 
                    *ngIf="globals.currentCustomerPortalProfile?.Internal === false && presenter.viewModel?.response?.CanCreateTickets"
                    class="mmr-button-margin"
                    [matMenuTriggerFor]="menu">
               <mat-icon>add_circle_outline</mat-icon>
               Open Ticket
            </button>
            <!-- <button (click)="getColumnState()">
               <mat-icon>add_circle_outline</mat-icon>
               Print column state
            </button>             -->
            <mat-menu #menu="matMenu">
               <button mat-menu-item                    
                       (click)="createNewSiteServiceRequest()">
                  <span>Site service request</span>
               </button>       
               <button mat-menu-item                    
                       (click)="createNewTwenty47Request()">
                  <span>24/7 Support</span>
               </button>                                 
            </mat-menu>              
         </div>
         
         <div class="mmr-filter-inner">
            <div class="mmr-filter-head">
               <mat-form-field class="mmr-search-input"
                               appearance="fill">
                  <mat-label>Search</mat-label>
                  <input matInput 
                         type="text" 
                         #searchText
                         [(ngModel)]="quickFilter"
                         (keyup)="refreshFilter(FilterLevel.QuickFilter)">
               </mat-form-field>
               <app-filter-result-control label="Sites"
                                          [(ngModel)]="filteredSiteIds"
                                          (selectionChanged)="updatedSite()"
                                          [options]="filterOptionsSites">
               </app-filter-result-control>     
               <app-filter-result-control label="Lines"
                                          [(ngModel)]="filteredLineIds"
                                          (selectionChanged)="updatedLine()"
                                          [options]="filterOptionsLines">
               </app-filter-result-control>    
               <app-filter-result-control label="Products"
                                          [(ngModel)]="filteredProductIds"
                                          (selectionChanged)="updatedProduct()"
                                          [options]="filterOptionsProducts">
               </app-filter-result-control>
               <app-filter-result-control label="Affected area"
                                          [(ngModel)]="filteredAffectedAreaIds"
                                          (selectionChanged)="updatedAffectedArea()"
                                          [options]="filterOptionsAffectedAreas">
               </app-filter-result-control>

               <mat-button-toggle-group name="event-ownership-filter" 
                                        aria-label="Event Ownership Filter"
                                        [(ngModel)]="eventOwnershipFilter"
                                        appearance="legacy"
                                        (change)="refreshFilter(FilterLevel.QuickFilter)">
                  <mat-button-toggle [value]="0">All</mat-button-toggle>
                  <mat-button-toggle [value]="1">HFMG</mat-button-toggle>
                  <mat-button-toggle [value]="2">Customer</mat-button-toggle>
               </mat-button-toggle-group>

               <div class="mmr-date-range">
                  <mat-form-field appearance="fill">
                     <mat-label>Filter by date range</mat-label>
                     <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate 
                              [(ngModel)]="dateRangeFilterStart" 
                              placeholder="Start date">

                        <input matEndDate 
                              (dateChange)="refreshFilter(FilterLevel.QuickFilter)"
                              [(ngModel)]="dateRangeFilterEnd" 
                              placeholder="End date">
                     </mat-date-range-input>
                     <mat-datepicker-toggle matSuffix 
                                          [for]="picker">
                     </mat-datepicker-toggle>
                     <mat-date-range-picker #picker></mat-date-range-picker>
                  </mat-form-field>
                  <div class="mmr-date-range-button"
                       (click)="setNextDays(30)"
                       style="left: 0">
                     next 30 days
                  </div>
                  <div class="mmr-date-range-button"
                       (click)="setNextDays(90)"
                       style="left: 100rem">
                     next 90 days
                  </div>
               </div>

               <button mat-stroked-button (click)="reset()"
                                          color="primary">
                  Reset filter
               </button>                  
               <div class="clear-both"></div>
               <h2>{{resultsCount}} Results</h2>
            </div>
            <div class="mmr-service-book">
               <ag-grid-angular class="ag-theme-material"
                                id="mmr-current-main-list" 
                                style="width: 100%; max-height: 20000rem;"
                                [gridOptions]="gridOptions">
               </ag-grid-angular> <!-- List height is set in source code according to item count -->
            </div>
         </div>
      </mat-card-content>
   </mat-card>

</div>
<div *ngIf="doneWithInitialLoading === false"
   class="bs-page-loading">
   <mat-spinner [diameter]="40" class="mmr-page-loading-animation"></mat-spinner>
</div>
