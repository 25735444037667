import { PortalUserEntity } from "../entities/portal-user.entity";
import { PermissionMatrixEntity } from "../entities/portal-users/permission-matrix.entity";
import { CreateExternalPortalUserApiRequest } from "./api-requests/create-external-portal-user.api-request";
import { CreateInternalPortalUserApiRequest } from "./api-requests/create-internal-portal-user.api-request";
import { DeletePortalUserApiRequest } from "./api-requests/delete-portal-user.api-request";
import { EditExternalPortalUserApiRequest } from "./api-requests/edit-external-portal-user.api-request";
import { EditInternalPortalUserApiRequest } from "./api-requests/edit-internal-portal-user.api-request";
import { GetPortalUserApiRequest } from "./api-requests/get-portal-user.api-requests";
import { GenericResultSet } from "./api-responses/generic-result-set";
import { PortalUserCreateApiResponse } from "./api-responses/portal-user-create.api-response";
import { PortalUserEditApiResponse } from "./api-responses/portal-user-edit.api.response";

export abstract class AdminRepository {
  // public abstract getNews(): Promise<PortalUserEntity>;<
   public abstract getMyself(): Promise<PortalUserEntity>;
   public abstract getPermissionMatrixForInternalUser(): Promise<PermissionMatrixEntity>;
   public abstract getPermissionMatrixForExternalUserAsExternalUser(): Promise<PermissionMatrixEntity>;
   public abstract getPermissionMatrixForExternalUserAsInternalUser(customerId: string): Promise<PermissionMatrixEntity>;

   public abstract getInternalPortalUsers(request: GetPortalUserApiRequest): Promise<GenericResultSet<PortalUserEntity>>;
   public abstract getExternalPortalUsers(request: GetPortalUserApiRequest): Promise<GenericResultSet<PortalUserEntity>>;

   public abstract getPortalUserById(id: string): Promise<PortalUserEntity>;
   public abstract getPortalUserByUserId(userId: string): Promise<PortalUserEntity>;

   public abstract createExternalPortalUserAsInternal(request: CreateExternalPortalUserApiRequest): Promise<PortalUserCreateApiResponse>;
   public abstract createExternalPortalUserAsExternal(request: CreateExternalPortalUserApiRequest): Promise<PortalUserCreateApiResponse>;
   public abstract createInternalPortalUser(request: CreateInternalPortalUserApiRequest): Promise<PortalUserCreateApiResponse>;

   public abstract editExternalPortalUserAsExternal(request: EditExternalPortalUserApiRequest, id: string): Promise<PortalUserEditApiResponse>;
   public abstract editInternalPortalUser(request: EditInternalPortalUserApiRequest, id: string): Promise<PortalUserEditApiResponse>;

   public abstract deleteExternalPortalUser(request: DeletePortalUserApiRequest, id: string);
   public abstract deleteInternalPortalUser(id: string);

   public abstract manualDocumentsRefreshUserLevel(userId: string);
   public abstract manualDocumentsRefreshCustomerLevel(customerId: string);

   public abstract updateShopOrganizationForUser(siteId: string);
}