import { Component, Input, OnInit } from '@angular/core';
import { getNiceDate, stringToNiceDateTime } from 'datahive-basic';
import { TicketComment } from 'src/app/core/entities/service-book/ticket-comment';

@Component({
  selector: 'app-ticket-comments',
  templateUrl: './ticket-comments.component.html',
  styleUrls: ['./ticket-comments.component.scss']
})
export class TicketCommentsComponent 
       implements OnInit {

   @Input() comments: TicketComment[];
   constructor() { }

   ngOnInit(): void {
   }
   getGoodDate(date: any) {
      const result = stringToNiceDateTime(date);
      return result;
   }

}
