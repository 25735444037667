import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GetEventItemFormRequest } from 'src/app/core/input-data/service-book/get-event-item-form.request';
import { GetTwenty47RequestFormRequest } from 'src/app/core/input-data/service-book/get-twenty47-request-form.request';
import { EventItemFormPresenter } from 'src/app/core/output-boundaries/event-item-form.presenter';
import { Twenty47RequestFormPresenter } from 'src/app/core/output-boundaries/twenty47-request-form.presenter';
import { LayoutService } from 'src/app/core/services/layout.service';
import { MMRGlobals } from 'src/app/core/services/mmr-globals';
import { ServiceBookItemCheckAttachmentUseCaseService } from 'src/app/core/use-cases/service-book/event-item-check-attachment.use-case';
import { EventItemCreateUseCaseService } from 'src/app/core/use-cases/service-book/event-item-create.use-case';
import { EventItemEditUseCaseService } from 'src/app/core/use-cases/service-book/event-item-edit.use-case';
import { GetEventItemFormUseCaseService } from 'src/app/core/use-cases/service-book/get-event-item-form.use-case';
import { GetTwenty47RequestFormUseCaseService } from 'src/app/core/use-cases/service-book/get-twenty47-request-form.use-case';
import { ServiceBookItemFormComponent } from '../base-service-book-item.form.component';
import { EventItemFormViewModel } from '../event-item-form/event-item-form.view-model';
import { EventItemFormModel } from '../event-item-form/event-item.form-model';
import { EventItemViewPresenterService } from '../event-item-view/event-item-view.presenter.service';
import { Twenty47RequestFormModel } from '../twenty47-request-form/twenty47-request.form-model';
import { Twenty47RequestViewPresenterService } from './twenty47-request-view.presenter.service';
import { Twenty47RequestFormViewModel } from './twenty47-request-view.view-model';

@Component({
  selector: 'app-twenty47-request-view',
  templateUrl: './twenty47-request-view.component.html',
  styleUrls: ['./twenty47-request-view.component.scss'],
  providers: [
   GetTwenty47RequestFormUseCaseService,
   { provide: Twenty47RequestFormPresenter, useClass: Twenty47RequestViewPresenterService},
]
})
export class Twenty47RequestViewComponent 
       extends ServiceBookItemFormComponent
       implements OnInit {
   
   editorOptions = {
      hideIcons: ['Image']
   };
   constructor(public globals: MMRGlobals,
               public activatedRoute: ActivatedRoute,
               public router: Router,
               public formBuilder: UntypedFormBuilder,
               public checkAttachmentUseCaseService: ServiceBookItemCheckAttachmentUseCaseService,
               public getTwenty47RequestFormUseCase: GetTwenty47RequestFormUseCaseService,
               public layoutService: LayoutService,
               public eventItemFormPresenter: Twenty47RequestFormPresenter<Twenty47RequestFormViewModel>) { 
      super(globals, 
            activatedRoute, 
            router, 
            layoutService,
            formBuilder, 
            checkAttachmentUseCaseService);
   }   
   openSharePoint(e) {
      this.globals.trackEvent('OpenLibrary', e.detail.webUrl);
      window.open(e.detail.webUrl);
   }  
   public async ngOnInit() {
      this.globals.track();
      super.ngOnInit(true);
   }  
   public async loadFormData() {
      const request = new GetTwenty47RequestFormRequest();
      request.Twenty47RequestId = this.currentItemId;
      await this.getTwenty47RequestFormUseCase.execute(request);
      const event = this.eventItemFormPresenter.viewModel.GetEventItemFormResponse.Twenty47RequestEntity;
      if(event) {
         const formModel = {} as EventItemFormModel;
         formModel.Name = event.Name;
         formModel.Description = event.Description;
         formModel.SiteId = event.SiteId;
         formModel.LineIds = event.Lines.map(line => line.MasterdataId);
         formModel.ProductIds = event.Products.map(line => line.MasterdataId);
         formModel.BasicInformation = event.BasicInformation;
         this.formGroup.patchValue(formModel);
         this.setSelectedSite();         
         this.setSelectedLine();         
         this.setSelectedProduct();         
      }
      if(!this.edit) {
         this.setPreselectedFields(Twenty47RequestFormModel);
      }
   }
   public goBack() {
      this.router.navigate(['portal', 'servicebook', 'overview']);
   }
   public async editControllerAction() {}        
   public createControllerAction() {}
}
