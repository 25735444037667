import {Component} from '@angular/core';
import { MMRGlobals } from 'src/app/core/services/mmr-globals';

@Component({
   templateUrl : './ag-document-title-renderer.component.html',
   styleUrls: ['./ag-document-title-renderer.component.scss']
})
export class AgDocumentTitleCellRendererComponent {
   url: string;
   name: string; 
   constructor(public globals: MMRGlobals) {

   }
   public agInit(params: any): void {
      if (params) {
         this.url = params.data.ApplicationUrl;
         this.name = params.data.FileName;
      }
   }
}
