import { Subscription } from "rxjs";
import { SharePointFileResults } from "../entities/sharepoint-file.results";

export abstract class SharePointRepository {
   public abstract getSearchResults(queryText: string, 
                                    site: string, 
                                    rowLimit: number,
                                    requestReference: {request: Subscription},
                                    lastDocId: string,
                                    resultsTillNow: SharePointFileResults,
                                    maxResults: number,
                                    warningOfResultsExceed: boolean,
                                    totalsFromFirstCall: number): Promise<SharePointFileResults>
}