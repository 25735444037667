import { Injectable } from "@angular/core";
import { MasterdataSiteEntity } from "../core/entities/masterdata-site.entity";
import { MasterdataSiteRepository } from "../core/repositories/masterdata-site.repository";
import { MMRGlobals } from "../core/services/mmr-globals";
import { MMRHttpClient } from "../core/shared/mmr-http-client";
import { SiteLocationCollection } from "../core/models/site-location-collection";
import { GetSiteLocationsApiModel } from "../core/api-models/get-site-locations.api-model";

@Injectable()
export class MasterdataSiteRepositoryService
       extends MasterdataSiteRepository {

   apiPrefix = 'masterdataSites';
   constructor(public globals: MMRGlobals,
               public http: MMRHttpClient) {
         super();
   }          
   public async getMasterdataSiteByMasterdataIdAsync(masterdataId: string): Promise<MasterdataSiteEntity> {
      const site = await this.http.get<MasterdataSiteEntity>(this.globals.baseUriGroups + this.apiPrefix + '?masterdataId=' + masterdataId);
      return site;
   }
}