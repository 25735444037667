<div class="mmr-plant-detail-outer">
   <div class="mmr-line-list">
      <mat-card class="mmr-flex-card">
         <mat-card-header>
            <div mat-card-avatar>
               <mat-icon fontIcon="view_list"></mat-icon>
            </div>
            <mat-card-title>{{currentSite?.Name}}</mat-card-title>
            <mat-card-subtitle>All production lines</mat-card-subtitle>
         </mat-card-header>      
         <mat-card-content>      
            <ag-grid-angular class="ag-theme-material"
                             style="width: 100%; height:500px;"
                             [gridOptions]="gridOptions">
            </ag-grid-angular>
         </mat-card-content>  
         <mat-card-actions>
            <button mat-raised-button
                    color="primary"
                    (click)="navigateToServiceBookForSite()">
               Service Book
            </button>
            <button mat-raised-button
                    color="primary"   
                    (click)="navigateToMaintenanceDocumentation(globals, router, currentSite.SiteId, selectedLine.Id, null)">
               Maintenance Documentation
            </button>
            <button mat-raised-button
                    color="primary"
                    (click)="navigateToCreate247Support()">
               24/7 Support
            </button>     
            <button mat-raised-button
                    color="primary"
                    (click)="navigateToSiteServiceRequest()">
               Site service request
            </button>                                         
         </mat-card-actions>
      </mat-card>
   </div>
   <div class="mmr-product-list">
      <mat-card class="mmr-flex-card" 
                *ngIf="selectedLine">
         <mat-card-header>
            <div mat-card-avatar>
               <mat-icon>dns</mat-icon>
            </div>
            <mat-card-title>{{selectedLine.Name}}</mat-card-title>
            <mat-card-subtitle>Scope of supply</mat-card-subtitle>
         </mat-card-header>
         <mat-card-content>
            <div *ngFor="let product of selectedLine.Products">
               <app-product-tile [product]="product"></app-product-tile>
            </div>
         </mat-card-content>              
      </mat-card>
   </div>
</div>
