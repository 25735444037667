import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BreadCrumbMenu } from 'src/app/presentation/breadcrumb-menu';
import { getCustomerPortalTopNavigationExternal, getCustomerPortalTopNavigationInternal } from 'src/app/presentation/cp-top-navigation';
import { NavigationLink } from 'src/app/presentation/modules/website/core/models/navigation-link';
import { Posting } from 'src/app/presentation/modules/website/core/models/posting';
import { StrapiService } from 'src/app/presentation/modules/website/strapi.service';
import { MMRGlobals } from './mmr-globals';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
   topNavigation: NavigationLink[];
   footerNavigation: NavigationLink[];
   postings: Posting[];
   cpExternalActive = false;
   cpInternalActive = false;
   websiteActive = true;   
   topNavigationSubject = new BehaviorSubject<NavigationLink[]>(null);
   constructor(public strapiService: StrapiService,
               public globals: MMRGlobals) { 
      this.topNavigationSubject = new BehaviorSubject<NavigationLink[]>(null);
      this.listenForTopNavigation();
   }  
   getNavigationItemByIdRecursively(id: string, navigationItems: NavigationLink[] = null): NavigationLink  {
      const target = navigationItems ? navigationItems : this.topNavigation;
      for(const item of target) {
         if(item.OriginalPath.toLowerCase().includes(id.toLowerCase())) {
            item.IsActive = true;
            return item;
         }
         if(item.Sublinks.length > 0) {
            const result = this.getNavigationItemByIdRecursively(id, item.Sublinks);
            if(result) { return result; }
         }
      }
      return null;
   }
   listenForTopNavigation() {
      this.topNavigationSubject.subscribe(async navItems => {
         this.topNavigation = navItems;
      });      
   }     
   getAndActivateNavigationItemById(id: string): NavigationLink {
      this.setAllItemsToInactive();
      const result = this.getNavigationItemByIdRecursively(id);      
      this.setParentsToActiveRecursively(id);
      return result;
   } 
   setAllItemsToInactive(items: NavigationLink[] = null) {
      const target = items ? items : this.topNavigation;
      for(const item of target) {
         item.IsActive = false;
         if(item.Sublinks.length > 0) {
            this.setAllItemsToInactive(item.Sublinks);
         }
      }
   }
   setParentsToActiveRecursively(id: string, 
                                 items: NavigationLink[] = null) {
      const target = items ? items : this.topNavigation;
      for(const item of target) {
         if(item.OriginalPath.toLowerCase().includes(id.toLowerCase())) {
            item.IsActive = true;
            continue;
         }
         if(item.HasActiveChild()) {
            item.IsActive = true;
         }
         if(item.Sublinks && item.Sublinks.length > 0) {
            this.setParentsToActiveRecursively(id, item.Sublinks);
         }         
      }
   }
   async setNavigationtoWebsiteDefault() {
      this.websiteActive = true;
      this.cpExternalActive = false;
      this.cpInternalActive = false;
      //const websiteItems = await this.strapiService.getTopNavigation();
      //this.topNavigationSubject.next(websiteItems);
   }
   async setNavigationtoCustomerPortalDefaultExternal() {
      this.websiteActive = false;
      this.cpExternalActive = true;
      this.cpInternalActive = false;

      const cpItems = getCustomerPortalTopNavigationExternal();
      this.topNavigationSubject.next(cpItems);
   }
   async setNavigationtoCustomerPortalDefaultInternal() {
      this.websiteActive = false;
      this.cpExternalActive = false;
      this.cpInternalActive = true;

      const cpItems = getCustomerPortalTopNavigationInternal();
      if(this.globals.simulationActive) {
         const simulatedItems = getCustomerPortalTopNavigationExternal().filter(item => item.Title != 'Documentation');
         for(const item of simulatedItems) {
            item.Highlighted = true;
         }
         cpItems.push(...simulatedItems);
      }      
      this.topNavigationSubject.next(cpItems);
   }   
}
