import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { showYesNoDialog } from 'datahive-ngx-ui';
import { LineWithContent } from 'src/app/core/entities/line-with-content';
import { ProductEntity } from 'src/app/core/entities/product.entity';
import { SiteWithContent } from 'src/app/core/entities/site-with-content';
import { LayoutService } from 'src/app/core/services/layout.service';
import { MMRGlobals } from 'src/app/core/services/mmr-globals';
import { BaseFormComponent } from 'src/app/core/shared/base-form.component';
import { ServiceBookItemCheckAttachmentUseCaseService } from 'src/app/core/use-cases/service-book/event-item-check-attachment.use-case';
import { getProductName } from '../shared/product-helpers';
import { ServiceBookItemFormModel } from './service-book-item.form-model';

@Component({ template: '' })
export abstract class ServiceBookItemFormComponent extends BaseFormComponent {
  currentItemId: string;

  getProductName = getProductName;

  selectedSite: SiteWithContent;
  selectedLines: LineWithContent[];
  selectedProducts: ProductEntity[];
  availableProducts: ProductEntity[];
  preselectedSiteId: string;
  preselectedLineId: string;
  preselectedProductId: string;
  change: ChangeDetectorRef;
  public abstract loadFormData();
  public loadData() {}
  public abstract editControllerAction();
  public abstract createControllerAction();

  constructor(
    public globals: MMRGlobals,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public layoutService: LayoutService,
    public formBuilder: FormBuilder,
    public checkAttachmentUseCaseService: ServiceBookItemCheckAttachmentUseCaseService
  ) {
    super();
  }
  public async onSubmit() {
    if (this.edit) {
      await this.editControllerAction();
    } else {
      await this.createControllerAction();
    }
  }
  public async ngOnInit(allDisabled = false) {
    this.determineCurrentItem();
    this.determineSelectedEntities();
    this.initForm(allDisabled);
    await this.loadFormData();
    this.doneWithInitialLoading = true;
    this.checkForAttachments();
    this.clearFileCache();
  }
  private async determineSelectedEntities() {
    const siteIdAccessor = 'siteId';
    const lineIdAccessor = 'lineId';
    const productIdaccessor = 'productId';

    const snapshot = this.activatedRoute.snapshot;
    this.preselectedSiteId = snapshot.params[siteIdAccessor];
    this.preselectedLineId = snapshot.params[lineIdAccessor];
    this.preselectedProductId = snapshot.params[productIdaccessor];
  }
  public initForm(allDisabled = false) {
    this.formGroup = this.formBuilder.group({
      Name: [{ value: '', disabled: allDisabled }, Validators.required],
      Description: [{ value: '', disabled: allDisabled }, Validators.required],
      SiteId: [
        { value: '', disabled: this.edit || allDisabled },
        Validators.required,
      ],
      LineIds: [
        { value: [], disabled: this.edit || allDisabled },
        Validators.required,
      ],
      ProductIds: [
        { value: [], disabled: this.edit || allDisabled },
        Validators.required,
      ],
      BasicInformation: [null],
    });
  }
  protected setPreselectedFields<T extends ServiceBookItemFormModel>(
    type: new () => T
  ) {
    const formModel = new type();
    if (this.preselectedSiteId) {
      formModel.SiteId = this.preselectedSiteId;
    }
    if (this.preselectedLineId) {
      formModel.LineIds = [this.preselectedLineId];
    }
    if (this.preselectedProductId) {
      formModel.ProductIds = [this.preselectedProductId];
    }
    this.formGroup.patchValue(formModel);
    if (this.preselectedSiteId) {
      this.setSelectedSite();
    }
    if (this.preselectedLineId) {
      this.setSelectedLine();
    }
    if (this.preselectedProductId) {
      this.setSelectedLine();
    }
  }
  protected determineCurrentItem() {
    const accessor = 'id';
    const snapshot = this.activatedRoute.snapshot;
    this.currentItemId = snapshot.params[accessor];
    if (this.currentItemId) {
      this.edit = true;
    } else {
      this.edit = false;
    }
  }
  public setSelectedSite() {
    delete this.selectedLines;
    delete this.selectedProducts;
    const id = this.formGroup.get('SiteId').value;
    this.selectedSite = this.globals.allCustomerSites.filter(
      (site) => site.SiteId === id
    )[0];
  }
  public setSelectedLine() {
    delete this.selectedProducts;
    const ids = this.formGroup.get('LineIds').value;
    this.selectedLines = [];
    this.availableProducts = [];
    for (const id of ids) {
      const line = this.selectedSite.Lines.filter((line) => line.Id === id)[0];
      this.availableProducts = this.availableProducts.concat(line.Products);
      this.selectedLines.push(line);
    }
    this.availableProducts = this.removeProductsWithoutServiceBookFlag();
  }
  private removeProductsWithoutServiceBookFlag() {
    const result = [];
    for (const product of this.availableProducts) {
      if (
        (product.Components.general_products[0] as any)
          .servicebookenabled__AH10F === 'Yes'
      ) {
        result.push(product);
      }
    }
    return result;
  }
  public setSelectedProduct() {
    const ids = this.formGroup.get('ProductIds').value;
    this.selectedProducts = [];
    for (const id of ids) {
      for (const selectedLine of this.selectedLines) {
        const product = selectedLine.Products.filter(
          (product) => product.ProductId === id
        )[0];
        this.selectedProducts.push(product);
      }
    }
  }
  public cancel() {
    showYesNoDialog(
      'Leaving this page...',
      'Are you sure you want to cancel creating a new event?',
      this.globals.matDialog,
      (_) => {
        this.navigateBackAfterTimeout(0);
      }
    );
  }
  protected navigateBackAfterTimeout(timeout = 1600) {
    setTimeout((_) => {
      this.router.navigate(['portal', 'servicebook', 'overview']);
    }, timeout);
  }
  public async checkForAttachments() {
    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd) {
        if (!this.currentItemId) {
          return;
        }
        this.checkAttachmentUseCaseService.execute(this.currentItemId);
      }
    });
  }
  clearFileCache() {
    const thisObj = this;
    setTimeout((_) => {
      const uploader = document.getElementsByTagName('mgt-file-list')[0] as any;
      this.change.detectChanges();
      if (typeof uploader.reload === 'function') {
        uploader.reload(true);
        console.log('Reloaded');
      } else {
        console.log('Trying again to load...');
        thisObj.clearFileCache();
      }
    }, 1000);
  }
}
