<div class="mmr-filter-optionblock" *ngIf="options && options.length > 0">
   <div class="mmr-filter-label">{{label}}</div>
   <div *ngFor="let option of options; let i = index">
      <mat-checkbox color="primary"
                    [(ngModel)]="flags[i]"
                    (change)="publish()">
         {{option.Name}} 
      </mat-checkbox>        
   </div>
</div>  

<!-- [{{option.Total}}] Behind {{option.Name}} to show totals-->