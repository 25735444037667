import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { IUseCase } from "../../architecture/use-case";
import { EventItemCreateRequest } from "../../input-data/service-book/event-item-create.request";
import { EventItemFormPresenter } from "../../output-boundaries/event-item-form.presenter";
import { ServiceBookRepository } from "../../repositories/service-book.repository";

@Injectable({providedIn: 'root'})
export class EventItemCreateUseCaseService
       implements IUseCase<EventItemCreateRequest, EventItemFormPresenter<any>> {

   requestCancelationToken: {request: Subscription } = {request: null};
   constructor(public serviceBookRepository: ServiceBookRepository,
               public presenter: EventItemFormPresenter<any>) {}

   public async execute(request: EventItemCreateRequest): Promise<void> {
      const newId = await this.serviceBookRepository.createEventItem(request.EventItemEntity);
      this.presenter.showCreateResult(newId);
   }
}
