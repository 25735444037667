import { Twenty47RequestFormPresenter } from "src/app/core/output-boundaries/twenty47-request-form.presenter";
import { GetTwenty47RequestFormResponse } from "src/app/core/output-data/get-twenty47-request-form.response";
import { Twenty47RequestFormViewModel } from "./twenty47-request-form.view-model";


export class Twenty47RequestFormPresenterService
       extends Twenty47RequestFormPresenter<Twenty47RequestFormViewModel> {
   constructor() {
      super(Twenty47RequestFormViewModel);
   }
   public showForm(response: GetTwenty47RequestFormResponse): void {
      this.viewModel.GetTwenty47RequestFormResponse = response;
   }
   public showCreateResult(id: string) {
      this.viewModel.NewId = id;
   }   
}
