<div class="hfmg-footer">
   <!-- <div class="logos">
      <img src="https://cpstrapi.blob.core.windows.net/strapi-uploads/strapi-uploads/assets/freudenberg_92b6934531.jpg?updated_at=2022-09-08T05:45:29.615Z">
      <img src="https://cpstrapi.blob.core.windows.net/strapi-uploads/strapi-uploads/assets/farrel_4e0a1b6996.jpg?updated_at=2022-09-08T05:44:40.577Z">
      <img src="https://cpstrapi.blob.core.windows.net/strapi-uploads/strapi-uploads/assets/farrelpomini_227957c8ef.jpg?updated_at=2022-09-08T05:44:40.501Z">
      <img src="https://cpstrapi.blob.core.windows.net/strapi-uploads/strapi-uploads/assets/pomini_61d949a2f4.jpg?updated_at=2022-09-08T05:44:40.437Z">
   </div> -->
   <nav class="hfmg-sitemap">
      <ul *ngIf="layoutService.websiteActive">
         <ng-container *ngFor="let item of layoutService.topNavigation; let i = index">
            <li *ngIf="i !== 0">
               <a>
                  {{item.Title}}
               </a>
               <ul>
                  <li *ngFor="let item of item.Sublinks">
                     <a (click)="navigationService.navigateTo(item)">
                        {{item.Title}}
                     </a>  
                  </li>
               </ul>
            </li>
         </ng-container>
      </ul>        
   </nav>
   <div class="hfmg-footer-bottom">
      <div class="footer-logo">
         <img src="/assets/img/hfmg-logo.svg">
      </div>
      <nav class="footer-navigation">
         <ul>
            <li>
               <a href="https://www.hf-mixinggroup.com/en/imprint/" target="_blank">Imprint</a>
            </li>
            <li>
               <a href="https://public-cdn.azurewebsites.net/legal/Terms%20of%20Use%20MyMixingRoom%20(20230531).pdf" target="_blank">Terms of use</a>
            </li>
            <li>
               <a href="https://public-cdn.azurewebsites.net/legal/HFMG 20220308 Privacy Policy_EN.pdf" target="_blank">Data protection policy</a>
            </li>                        
         </ul>         
         <div class="copyright">© {{year}} Harburg-Freudenberger Maschinenbau GmbH. All rights reserved.</div>
      </nav>
      <div class="footer-right">
      </div>
   </div>
</div>