export class NavigationLink {
   id: number;
   Title: string;
   Identifier: string;
   External: boolean;
   Template: string;
   Value: string;
   OriginalPath: string;
   Sublinks: NavigationLink[]
   Parent: NavigationLink;
   ImagePath: string;
   Highlighted: boolean; //ie for Simulation
   IsActive: boolean;
   HasActiveChild(links: NavigationLink[] = null): boolean {
      const targets = links ? links : this.Sublinks;
      if(!targets || targets.length === 0) { return false; }

      for(const child of targets) {
         if(child.IsActive === true) {
            return true;
         }
         if(child.Sublinks && child.Sublinks.length > 0) {
            const result = this.HasActiveChild(child.Sublinks);
            if(result === true) { return true; }
         }
      }
      return false;
   }
}