import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ServiceBookItemListComponent } from './service-book-item-list-component/service-book-item-list.component';
import { ServicebookRoutingModule } from './servicebook-routing.module';
import { Twenty47RequestFormComponent } from './twenty47-request-form/twenty47-request-form.component';
import { AgServiceBookItemActionsCellRendererComponent } from './service-book-item-list-component/ag-service-book-item-actions-cell-renderer/ag-service-book-item-actions-renderer.component';
import { AgServiceBookItemTitleCellRendererComponent } from './service-book-item-list-component/ag-service-book-item-title-cell-renderer/ag-service-book-item-title-cell-renderer.component';
import { FilterResultControlComponent } from './filter-result-control/filter-result-control.component';
import { DatahiveNgxStructureModule } from 'datahive-ngx-structure';
import { DatahiveNgxUiModule } from 'datahive-ngx-ui';
import { EventItemFormComponent } from './event-item-form/event-item-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EventItemViewComponent } from './event-item-view/event-item-view.component';
import { Twenty47RequestViewComponent } from './twenty47-request-view/twenty47-request-view.component';
import { TicketCommentsComponent } from './ticket-comments/ticket-comments.component';

@NgModule({
  declarations: [
    ServiceBookItemListComponent,
    Twenty47RequestFormComponent,
    Twenty47RequestViewComponent,
    EventItemFormComponent,    
    EventItemViewComponent,
    AgServiceBookItemTitleCellRendererComponent,
    AgServiceBookItemActionsCellRendererComponent,
    FilterResultControlComponent,
    TicketCommentsComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],  
  imports: [  
   CommonModule,
   ServicebookRoutingModule,
   DatahiveNgxStructureModule,
   DatahiveNgxUiModule,
   SharedModule,
   RouterModule,
   ReactiveFormsModule,
   FormsModule
  ]
})
export class ServicebookModule { }
