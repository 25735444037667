import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BehaviorSubject } from "rxjs";
import { BreadCrumbMenu } from "src/app/presentation/breadcrumb-menu";
import { NavigationLink } from "src/app/presentation/modules/website/core/models/navigation-link";
import { CustomerEntity } from "../entities/customer.entity";
import { PortalUserEntity } from "../entities/portal-user.entity";
import { SharePointFile } from "../entities/sharepoint-file";
import { SharePointFileResults } from "../entities/sharepoint-file.results";
import { SiteWithContent } from "../entities/site-with-content";
import { UserEntity } from "../entities/user";
import { UrlMatrix } from "../models/url-matrix";
import { MasterdataCache } from "./mmr-cache";
import { DocumentFilterConfiguration } from "./mmr-documentfilter-configuration";
import { SiteLocationCollection } from "../models/site-location-collection";
import { MatomoTracker } from "ngx-matomo";
import { Router } from "@angular/router";

@Injectable({providedIn: 'root'})
export class MMRGlobals {
   private graphBasePath = 'https://graph.microsoft.com/v1.0/';
   appLoaded = false;
   breadcrumb: BreadCrumbMenu;
   currentUser: UserEntity;
   currentCustomerPortalProfile: PortalUserEntity;
   hasAccountInDirectoryButNoAccountInThePortal = false;
   cachedPreviewDocuments: SharePointFileResults;
   documentFilterConfiguration: DocumentFilterConfiguration;
   currentRole: string;
   simulationActive = false;
   currentlySimulatedCustomerId: string;
   currentlySimulatedPlantId: string;   

   allCustomerSites: SiteWithContent[];
   allSiteLocations: SiteLocationCollection;
   currentCustomerId = '';
   currentCustomer: CustomerEntity;

   baseUriSmartFinalMix = 'https://hfmg-advise-intelligent-mixer-api.azurewebsites.net/';

   urlMatrix: UrlMatrix;

   baseUriEnterprises = 'https://dh-enterprises-prod.azurewebsites.net/';
   baseUriProductStructure = 'https://dh-product-structure-prod.azurewebsites.net/';
   baseUriGroups = 'https://dh-groups-prod.azurewebsites.net/';
   globalLoading = new BehaviorSubject<boolean>(false);
   documentationPreviewArrived = new BehaviorSubject<boolean>(null);
   burgerMenu = new BehaviorSubject<BreadCrumbMenu>(null);
   isCurrentlyLoadingSomething = false;
   constructor(private http: HttpClient,
               public matDialog: MatDialog,
               public router: Router,
               private matomoTracker: MatomoTracker,
               public matSnackbar: MatSnackBar) {
      this.documentFilterConfiguration = new DocumentFilterConfiguration();
      this.documentationPreviewArrived = new BehaviorSubject<boolean>(null);

      this.urlMatrix = new UrlMatrix();
      this.urlMatrix.setPaths();
   }
   public track() {
      this.matomoTracker.setUserId(this.currentUser.EmailAdress);
      this.matomoTracker.trackPageView(this.router.url);
   }
   public trackEvent(category: string, name: string) {
      this.matomoTracker.setUserId(this.currentUser.EmailAdress);
      this.matomoTracker.trackEvent(category, name);
   }
   public setBurgerMenu(menu: BreadCrumbMenu) {
      this.burgerMenu.next(menu);
   }
   public resetBurgerMenu() {
      this.burgerMenu.next(new BreadCrumbMenu());
   }
   public exitSimulation() {
      this.simulationActive = false;
      this.currentlySimulatedCustomerId = null;
      this.currentlySimulatedPlantId = null;
   }
   public getCachedLineById(lineId: string) {
      for(const site of this.allCustomerSites) {
         const line = site.getLineById(lineId);
         if(line) {
            return line;
         }         
      }
      return null;
   }
   public getCachedProductById(productId: string) {
      for(const site of this.allCustomerSites) {
         const product = site.getProductById(productId);
         if(product) {
            return product;
         }         
      }
      return null;
   }
   public getCachedSiteById(siteId: string) {
      for(const site of this.allCustomerSites) {
         if(site.SiteId === siteId) {
            return site;
         }         
      }
      return null;
   }
   public async getCurrentUser(): Promise<UserEntity> {   
      const user = await this.http.get<any>(this.graphBasePath + 'me/').toPromise();
      const realUser = this.mapFromGraphUserToUserEntity(user);     
      // try {
      //    const blob = await this.http.get<any>(this.graphBasePath + 'me/photos/96x96/$value', {responseType: 'blob'  as 'json'}).toPromise();
      //    realUser.ProfilePictureBase64 = await this.createImageFromBlob(blob);
      //    localStorage.setItem(user.Id, JSON.stringify(user));
      // } catch(error) {
      //    realUser.ProfilePictureBase64 = null;
      // }      
      return realUser;
   }
   private mapFromGraphUserToUserEntity(graphUser: any) {
      const user = new UserEntity();
      user.Id = graphUser.id;
      user.FirstName = graphUser.givenName;
      user.LastName = graphUser.surname;
      user.EmailAdress = graphUser.mail;
      user.IsExternal = graphUser.userPrincipalName.includes('EXT');
      if(graphUser.jobTitle) {
         user.JobTitle = graphUser.jobTitle;
      } else {
         user.JobTitle = '';
      }
      return user; 
   }
   public showGlobalLoading() {
      this.isCurrentlyLoadingSomething = true;
      this.globalLoading.next(true);
   }
   public hideGlobalLoading() {
      this.isCurrentlyLoadingSomething = false;
      this.globalLoading.next(false);
   }  
   public isUserExperience() {
      if(!this.currentCustomerPortalProfile) { return false; }
      const exp = !this.currentCustomerPortalProfile.Internal || this.simulationActive;
      return exp;
   } 
   buildBreadcrumb(nav: NavigationLink) {
      delete this.breadcrumb;
      this.buildBreadCrumbRecursively(nav);
      this.burgerMenu.next(this.breadcrumb);
   }   
   private buildBreadCrumbRecursively(nav: NavigationLink) {
      if(!this.breadcrumb) {
         this.breadcrumb = new BreadCrumbMenu();
      }
      this.breadcrumb.addItemInverse(nav.Title, nav.Value);
      if(nav.Parent) {
         this.buildBreadCrumbRecursively(nav.Parent);
      }
   }   
}