import { ComponentEntity } from './component.entity';
import { ProductEntity } from './product.entity';

export class LineWithContent {
   Name: string;
   Id: string;
   BasicInformation: ComponentEntity;
   Products: ProductEntity[];
   FileLocation: string;
   HFMGAnalyticsUrl: string;
   UserHasSupplierDocumentationReadAccess: boolean;
   
   public getProductById(productId: string) {
      if(!this.Products) {
         return null;
      }
      for(const product of this.Products) {
         if(product.ProductId === productId) {
            return product;
         }
      }
      return null;
   }
}
