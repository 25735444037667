<div class="bs-landing-page">
   <div class="bs-landing-page-content">
      <div class="bs-landing-page-content-info">
         <img src="/assets/img/hfmglogo.png">

         <h2>MyMixingRoom</h2>

         <p>The Portal MyMixingRoom is the new Industry 4.0 and IoT-Plattform by HF MIXING GROUP.</p>
         <p>As a registered company, you get access to the machine and system information of your mixed-use system, contracts and documentation and business support services.</p>

         <ng-container *ngIf="!message">
            <br>
         </ng-container>
         <span class="notification-message"
              *ngIf="message">
            {{message}}
         </span>         
         <p>As a registered user, you can access the login here.</p>
         <button mat-raised-button color="primary"
                 routerLink="/portal"
                 *ngIf="!message">
            Login
         </button> 
         <button mat-raised-button color="primary"
                 (click)="logout()"
                 *ngIf="message">
            Logout
         </button>          
         <button mat-raised-button color="warn"
                 (click)="navigateToSignup()">
            Sign Up
         </button>          
      </div>      
      <div class="bs-landing-page-content-image"></div>
   </div>
</div>