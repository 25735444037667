export class UrlMatrix {
   ServiceBookApiUrl: string;
   MasterdataApiUrl: string;
   AdminApiUrl: string;


   setPaths() {
      if (location.hostname === `localhost` || location.hostname === `127.0.0.1`) {         

         this.MasterdataApiUrl = 'https://cp-masterdata-api-test.azurewebsites.net/';
         this.AdminApiUrl = 'https://cp-admin-api-test.azurewebsites.net/';
         this.ServiceBookApiUrl = 'https://cp-service-book-api-test.azurewebsites.net/';         

         this.MasterdataApiUrl = 'https://cp-masterdata-api-prod.azurewebsites.net/';
         this.AdminApiUrl = 'https://cp-admin-api-prod.azurewebsites.net/';
         this.ServiceBookApiUrl = 'https://cp-service-book-api-prod.azurewebsites.net/';

         //this.MasterdataApiUrl = 'https://localhost:9200/';
         //this.AdminApiUrl = 'https://localhost:9300/';
         //this.ServiceBookApiUrl = 'https://localhost:9400/';

      } else if(location.hostname.includes('cp-webapp-test')) {         

         this.MasterdataApiUrl = 'https://cp-masterdata-api-test.azurewebsites.net/';
         this.AdminApiUrl = 'https://cp-admin-api-test.azurewebsites.net/';
         this.ServiceBookApiUrl = 'https://cp-service-book-api-test.azurewebsites.net/';
         
      } else if(location.hostname.includes('mymixingroom')) {

         this.MasterdataApiUrl = 'https://cp-masterdata-api-prod.azurewebsites.net/';
         this.AdminApiUrl = 'https://cp-admin-api-prod.azurewebsites.net/';
         this.ServiceBookApiUrl = 'https://cp-service-book-api-prod.azurewebsites.net/';
         
      }
   }
}