import { Component, Input, OnInit } from '@angular/core';
import { ProductEntity } from 'src/app/core/entities/product.entity';
import { SubscriptionDurationIndicator } from 'src/app/core/enums/subscription-duration-indicator';
import { StringDecoder } from 'string_decoder';
import { getSerialNumberFromProduct } from '../../shared/product-helpers';

@Component({
  selector: 'app-product-tile',
  templateUrl: './product-tile.component.html',
  styleUrls: ['./product-tile.component.scss']
})
export class ProductTileComponent 
       implements OnInit {
   @Input() product: ProductEntity;
   SubscriptionDurationIndicator = SubscriptionDurationIndicator;
   getSerialNumberFromProduct = getSerialNumberFromProduct;
   constructor() { }

   public ngOnInit(): void {

   }
   public openSharePointLib() {
      if(!this.product.UserHasSupplierDocumentationReadAccess) {
         alert('You have no permission to access the documentation');
         return;
      }
      window.open(this.product.FileLocation + "/01 HFMGDocumentation", '_blank');
   }   
   public getUrlForIFrame(url: string, fragment: string) {      
      if(!url) return '';

      //Only to show technical center in Demo:
      {
         url = url.replace('processcell=5f92beda33c63d166489d981', 'processcell=d00000000000000000000619');
         url = url.replace('unit=5f92bde633c63d166489d97e', 'unit=a00000000000000000001548');
      }      
      url = url.replace('PAGE_NAME_TO_REPLACE_BY_CLIENT', fragment);
      return encodeURIComponent(url);
   }   
}
