import { SharePointFile } from "./sharepoint-file";
import { SiteWithContent } from "./site-with-content";

export class SharePointFileResults {
   constructor(total: number) {
      this.Total = total;
      this.Results = [];
   }
   Total: number;
   Results: SharePointFile[];
   public enrichFilesWithFileLocations(allCustomerSites: SiteWithContent[]) {
      for(const file of this.Results) {
         this.enrichFileWithFileLocations(file, allCustomerSites);
      }
   }   
   private enrichFileWithFileLocations(file: SharePointFile, allCustomerSites: SiteWithContent[]) {
      file.setFileLocationsFromMasterdata(allCustomerSites);
   }   
}