import { UntypedFormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { MMRGlobals } from '../services/mmr-globals';

export abstract class BaseFormComponent {
   formGroup: UntypedFormGroup;
   doneWithInitialLoading = false;
   edit: boolean;
   location: Location;   
   waitingForResponse = false;
   globals: MMRGlobals;   
   public abstract loadFormData();
   public abstract initForm(any: any|undefined);
   public abstract loadData();
   public abstract editControllerAction(stayOnThisPage: boolean);
   public abstract createControllerAction();
   public onSubmit(stayOnThisPage = false) {
      if (this.edit === true) {
         this.editControllerAction(stayOnThisPage);
      } else {
         this.createControllerAction();
      }
   }
   public cancelAndBack() {
      this.location.back();
   }
   public enableSubmit() {
      this.globals.hideGlobalLoading();
      this.waitingForResponse = false;
   }
   public disableSubmit() {
      this.globals.showGlobalLoading();
      this.waitingForResponse = true;
   }
}
