import { EventOwnership } from "../enums/event-ownership";

export class QueryRequest {
   QuickFilter: string;
   FilterModel: any;
   SortModel: any;
   Limit: number;
   CustomerId: string;
   CategoryDefinitionDescriptiveId: string;
   RequestingUserId: string;
   EventOwnershipFilter: EventOwnership;
   OnlyFetchFilters: boolean;
   FilteredSiteIds: string[];
   FilteredLineIds: string[];
   FilteredProductIds: string[];
   FilteredAffectedAreaIds: string[];
   StartDateFilter: Date;
   EndDateFilter: Date;
}