import { ProductEntity } from "src/app/core/entities/product.entity";

export function getProductName(productEntity: ProductEntity) {
   if(!productEntity) {
      return '';
   }
   const sn = getSerialNumberFromProduct(productEntity);
   return productEntity.ProductTypeName + ' S/N: ' + sn;
}
export function getSerialNumberFromProduct(productEntity: ProductEntity) {
   try {
      if((productEntity.Components.general_products[0] as any).SerialNumber) {
         return (productEntity.Components.general_products[0] as any).SerialNumber;
      } else {
         return '-';
      }      
   } catch(e) {
      return '-';
   }     
}
export function getProductNameFromList(productEntities: ProductEntity[]) {
   return productEntities.map(product => getProductName(product)).join(',');
}
export function removePathFromProductPart(path: string) {
   if(!path) {
      return '';
   }
   path = path.split(":").pop();
   return path;
}  