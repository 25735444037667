import { Injectable } from "@angular/core";
import { ComponentDefinitionRepository } from "../core/repositories/component-definition.repository";
import { MMRGlobals } from "../core/services/mmr-globals";
import { MMRHttpClient } from "../core/shared/mmr-http-client";

@Injectable()
export class ComponentDefinitionRepositoryService
       implements ComponentDefinitionRepository {
    
   private apiPrefix = 'components';
   constructor(private mmrGlobals: MMRGlobals,
               private http: MMRHttpClient) {

   }
   public async getAll(): Promise<any[]> {
      const componentDefinitions = await this.http.get<any[]>(this.mmrGlobals.baseUriProductStructure + this.apiPrefix);
      return componentDefinitions;
   }
   public async getById(id: string, renderOptions = true): Promise<any> {
      const componentDefinition = await this.http.get<any>(this.mmrGlobals.baseUriProductStructure + this.apiPrefix + '/' + id + '?renderOptions=' + renderOptions);
      return componentDefinition;
   }  
}
