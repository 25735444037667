import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LineWithContent } from 'src/app/core/entities/line-with-content';
import { ProductEntity } from 'src/app/core/entities/product.entity';
import { EventItemEntity } from 'src/app/core/entities/service-book/event-item';
import { SiteWithContent } from 'src/app/core/entities/site-with-content';
import { EventItemCreateRequest } from 'src/app/core/input-data/service-book/event-item-create.request';
import { EventItemEditRequest } from 'src/app/core/input-data/service-book/event-item-edit.request';
import { GetEventItemFormRequest } from 'src/app/core/input-data/service-book/get-event-item-form.request';
import { EventItemFormPresenter } from 'src/app/core/output-boundaries/event-item-form.presenter';
import { LayoutService } from 'src/app/core/services/layout.service';
import { MMRGlobals } from 'src/app/core/services/mmr-globals';
import { ServiceBookItemCheckAttachmentUseCaseService } from 'src/app/core/use-cases/service-book/event-item-check-attachment.use-case';
import { EventItemCreateUseCaseService } from 'src/app/core/use-cases/service-book/event-item-create.use-case';
import { EventItemEditUseCaseService } from 'src/app/core/use-cases/service-book/event-item-edit.use-case';
import { GetEventItemFormUseCaseService } from 'src/app/core/use-cases/service-book/get-event-item-form.use-case';
import { showSnackBar, showSnackBarError } from '../../shared/material-helpers';
import { ServiceBookItemFormComponent } from '../base-service-book-item.form.component';
import { EventItemFormPresenterService } from './event-item-form.presenter.service';
import { EventItemFormViewModel } from './event-item-form.view-model';
import { EventItemFormModel } from './event-item.form-model';

@Component({
  selector: 'app-event-item-form',
  templateUrl: './event-item-form.component.html',
  styleUrls: ['./event-item-form.component.scss'],
  providers: [
    GetEventItemFormUseCaseService,
    EventItemCreateUseCaseService,
    {
      provide: EventItemFormPresenter,
      useClass: EventItemFormPresenterService,
    },
  ],
})
export class EventItemFormComponent
  extends ServiceBookItemFormComponent
  implements OnInit
{
  editorOptions = {
    hideIcons: ['Image'],
  };
  constructor(
    public globals: MMRGlobals,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public change: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder,
    public checkAttachmentUseCaseService: ServiceBookItemCheckAttachmentUseCaseService,
    public getEventItemFormUseCase: GetEventItemFormUseCaseService,
    public eventItemCreateUseCase: EventItemCreateUseCaseService,
    public eventItemEditUseCase: EventItemEditUseCaseService,
    public layoutService: LayoutService,
    public eventItemFormPresenter: EventItemFormPresenter<EventItemFormViewModel>
  ) {
    super(
      globals,
      activatedRoute,
      router,
      layoutService,
      formBuilder,
      checkAttachmentUseCaseService
    );
    this.change = change;
  }
  openSharePoint(e) {
    window.open(e.detail.webUrl);
    this.globals.trackEvent('OpenLibrary', e.detail.webUrl);
  }
  public async loadFormData() {
    const request = new GetEventItemFormRequest();
    request.EventItemId = this.currentItemId;
    await this.getEventItemFormUseCase.execute(request);
    const event =
      this.eventItemFormPresenter.viewModel.GetEventItemFormResponse
        .EventItemEntity;
    if (event) {
      const formModel = new EventItemFormModel();
      formModel.Name = event.Name;
      formModel.Description = event.Description;
      formModel.SiteId = event.SiteId;
      formModel.LineIds = event.Lines.map((line) => line.MasterdataId);
      formModel.ProductIds = event.Products.map((line) => line.MasterdataId);
      formModel.BasicInformation = event.BasicInformation;
      this.formGroup.patchValue(formModel);
      this.setSelectedSite();
      this.setSelectedLine();
      this.setSelectedProduct();
    }
    if (!this.edit) {
      this.setPreselectedFields(EventItemFormModel);
    }
  }
  public async editControllerAction() {
    this.disableSubmit();
    const eventItemEditRequest = new EventItemEditRequest();
    eventItemEditRequest.EventItemEntity = this.formGroup.value;
    eventItemEditRequest.Id = this.currentItemId;
    try {
      await this.eventItemEditUseCase.execute(eventItemEditRequest);
      this.globals.trackEvent(
        'EventUpdated',
        eventItemEditRequest.EventItemEntity.Name
      );
      showSnackBar('Successfully updated event', this.globals.matSnackbar);
      this.navigateBackAfterTimeout();
    } catch (e) {
      showSnackBarError('Error: ' + e, this.globals.matSnackbar);
    }
    this.enableSubmit();
  }
  public async createControllerAction() {
    this.disableSubmit();
    const eventItemCreateRequest = new EventItemCreateRequest();
    eventItemCreateRequest.EventItemEntity = this.formGroup.value;
    try {
      showSnackBar('Inserting...', this.globals.matSnackbar);
      await this.eventItemCreateUseCase.execute(eventItemCreateRequest);
      this.globals.trackEvent(
        'EventAdded',
        eventItemCreateRequest.EventItemEntity.Name
      );
      showSnackBar(
        'Successfully inserted event. You can add some files now.',
        this.globals.matSnackbar
      );
      this.router.navigate([
        'portal',
        'servicebook',
        'eventItemForm',
        'edit',
        this.eventItemFormPresenter.viewModel.NewId,
      ]);
    } catch (e) {
      showSnackBarError('Error: ' + e, this.globals.matSnackbar);
    }
    this.enableSubmit();
  }
}
