import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserRepository } from 'datahive-ngx-graph';
import { GetExternalPortalUserRequest } from 'src/app/core/input-data/admin/get-external-portal-user.request';
import { AdminRepository } from 'src/app/core/repositories/admin.repository';
import { GetPortalUserApiRequest } from 'src/app/core/repositories/api-requests/get-portal-user.api-requests';
import { LayoutService } from 'src/app/core/services/layout.service';
import { MMRGlobals } from 'src/app/core/services/mmr-globals';

declare let jQuery: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],  
})
export class DashboardComponent  
       implements OnInit {   
   markers: any[];
   numberOfCustomerSites = 0;
   numberOfLines = 0;
   numberOfProducts = 0;
   numberOfUsers = 0;

   constructor(public globals: MMRGlobals,
               public router: Router,
               public adminRepository: AdminRepository,
               public layoutService: LayoutService) { }
   
   public ngOnInit(): void {
      this.globals.track();
      this.navigateToLandingPageIfNoValidAccountWasFound();   
      this.initStas();
   }   
   private async initStas() {
      if(!this.globals.allCustomerSites) { return; }
      this.numberOfCustomerSites = this.globals.allCustomerSites.length;
      const req = new GetPortalUserApiRequest();
      req.CustomerId = this.globals.currentCustomerId;
      const allUsers = await this.adminRepository.getExternalPortalUsers(req);
      this.numberOfUsers = allUsers.Total;
      for(const site of this.globals.allCustomerSites) {
         this.numberOfLines += site.Lines.length;
         this.numberOfProducts += site.getProductsCount();         
      }
   }
   public navigateToLandingPageIfNoValidAccountWasFound() {
      if(!this.globals.currentCustomerPortalProfile || this.globals.hasAccountInDirectoryButNoAccountInThePortal) {
         this.router.navigate(['']);
      } else {
         this.intersectingExternalOrInternalUser();
      }
   }   
   private intersectingExternalOrInternalUser() {
      if(!this.globals.isUserExperience()) {
         this.router.navigate(['portal', 'admincenter']);
      } else {
         this.drawMap();
      }           
   }
   setMapSize() {
      let maxWidth = Math.floor(window.innerWidth);
      if(maxWidth > 1350) {
         maxWidth = 1350;
      }
      const map = document.getElementById('world-map');
      if(map) {
         map.style.width = maxWidth + 'rem';
         map.style.height = Math.floor(window.innerHeight-200) + 'rem';
      }
   }
   private drawMap() {
      const map = this.getMap();
      window.onresize = _ => {
         this.setMapSize();
      }
      this.setMapSize();
      setTimeout(_ => {
         jQuery('#world-map').vectorMap(map);
      }, 100);      
   }
   private onMarkerClick(event, index) {
      const clickedPlant = this.globals.allCustomerSites[index];
      this.router.navigate(['portal', 'masterdata', 'plant-detail', clickedPlant.SiteId]);
      this.removeAllMarkers();      
   }
   private removeAllMarkers() {
      setTimeout(()=> 
      { 
         Array.from(document.getElementsByClassName("jvectormap-tip")).forEach((el) => { (el as any).style.display = 'none' }); 
      },0);      
   }
   private getMap() {
      const globals = this.globals;
      const thisCom = this;
      return { 
         map: 'world_mill',
         zoomButtons : false,
         backgroundColor:'#fff',
         attribute: 'image',      
         regionStyle: {
            initial: {
               fill: '#003671',
               "fill-opacity": 1,
               stroke: 'none',
               "stroke-width": 0,
               "stroke-opacity": 1
            },
            hover: {
               "fill-opacity": 0.8,
               cursor: 'pointer'
            }
         },
         onMarkerClick: function(event, index) {
            thisCom.onMarkerClick.call(thisCom, event, index);
         },
         onMarkerTipShow: function(event, label, index){
            label.css('z-index', 11);
            label.html(
               globals.allCustomerSites[index].Name
            );
         },        
         onRegionTipShow: function(event, label, index){
            label.css('z-index', 11);
            label.html(null);
         },     
         markersSelectable: true,
         markersSelectableOne: true,
         markerStyle: {
            initial: {
               fill: '#ff9800',
               stroke: '#fff',
               r: 10
            },
         },                                       
         markers: globals.allCustomerSites.map(function(site) {
            const latitude = site.BasicInformation['latitude__24KT6'];
            const longitude = site.BasicInformation['longitude__AIS8X'];    
            return { latLng : [latitude, longitude], name: site.Name };  
         }),
         markerLabelStyle: {
            initial: {
               'font-family': 'Verdana',
               'font-size': '15',
               'font-weight': 'bold',
               'background-color': '#fff',
               cursor: 'default',
               fill: '#fff'
            },
            hover: {
               cursor: 'pointer'
            }
         }
      }      
   }
}
