import { Injectable } from "@angular/core";
import { clonePOJO } from "datahive-basic";
import { Subscription } from "rxjs";
import { getProductName } from "src/app/presentation/modules/shared/product-helpers";
import { IUseCase } from "../../architecture/use-case";
import { LineWithContent } from "../../entities/line-with-content";
import { ProductEntity } from "../../entities/product.entity";
import { FilterLevel } from "../../enums/filter-level";
import { QueryRequest } from "../../input-data/query.request";
import { GetServiceBookItemsRequest } from "../../input-data/service-book/get-service-book-items.request";
import { GroupedByFilterResult } from "../../models/grouped-by-filter-results";
import { ServiceBookItemsPresenter } from "../../output-boundaries/service-book-items.presenter";
import { ServiceBookItemListResponse } from "../../output-data/service-book-item-list.response";
import { ServiceBookRepository } from "../../repositories/service-book.repository";
import { MasterdataCache } from "../../services/mmr-cache";
import { MMRGlobals } from "../../services/mmr-globals";

@Injectable({providedIn: 'root'})
export class ServiceBookItemsGetUseCaseService
       implements IUseCase<GetServiceBookItemsRequest, ServiceBookItemsPresenter<any>> {
   
   storedFilterResultsLine: GroupedByFilterResult[];
   storedFilterResultsProduct: GroupedByFilterResult[];

   requestCancelationToken: {request: Subscription } = {request: null};
   constructor(public presenter: ServiceBookItemsPresenter<any>,
               public globals: MMRGlobals,
               public cache: MasterdataCache,
               public serviceBookRepository: ServiceBookRepository) {}

   public async execute(request: GetServiceBookItemsRequest): Promise<void> {
      this.presenter.reset();
      this.cancelRequest();
      if(request.FilterLevel === FilterLevel.Reset) {
         this.clearFilter();
      }      
      const response = new ServiceBookItemListResponse();
      
      const queryRequest = new QueryRequest();
      queryRequest.QuickFilter = request.QuickFilter;
      queryRequest.OnlyFetchFilters = request.OnlyFetchFilters;
      queryRequest.FilteredSiteIds = request.FilteredSiteIds;
      queryRequest.FilteredLineIds = request.FilteredLineIds;
      queryRequest.FilteredProductIds = request.FilteredProductIds;
      queryRequest.FilteredAffectedAreaIds = request.FilteredAffecteAreaIds;
      queryRequest.CustomerId = request.CustomerId;
      queryRequest.EventOwnershipFilter = request.EventOwnershipFilter;
      queryRequest.StartDateFilter = request.StartDateFilter;
      queryRequest.EndDateFilter = request.EndDateFilter;

      response.ServiceBookAggregatedResponse = await this.serviceBookRepository.searchServiceBook(queryRequest, this.requestCancelationToken);
      response.EventItemComponentDefinition = await this.cache.getItemEventRequestComponentDefinition();
      this.setCanCreateFlags(response);
      this.setAffectedAreas(response);
      this.setSiteFilter(response);
      this.setLineFilter(request, response);
      this.setProductFilter(request, response);

      this.storeFilter(response);   
      this.presenter.showList(response);
   }
   private setCanCreateFlags(result: ServiceBookItemListResponse) {
      if(this.globals.currentCustomerPortalProfile.IsGlobalAdmin) {
         result.CanCreateEvents = true;
         result.CanCreateTickets = false;
         return;
      }
      const readWriteTickets = this.globals.currentCustomerPortalProfile.getPermissionAssignment('Tickets.ReadWrite');
      const fullControlTickets = this.globals.currentCustomerPortalProfile.getPermissionAssignment('Tickets.FullControl');
      const readWriteEvents = this.globals.currentCustomerPortalProfile.getPermissionAssignment('CustomerEvents.ReadWrite');
      const fullControlEvents = this.globals.currentCustomerPortalProfile.getPermissionAssignment('CustomerEvents.FullControl');      
      result.CanCreateEvents = !!readWriteEvents || !!fullControlEvents;
      result.CanCreateTickets = !!readWriteTickets || !!fullControlTickets;
   }
   private clearFilter() {
      this.storedFilterResultsProduct = [];
      this.storedFilterResultsLine = [];
   }
   private storeFilter(result: ServiceBookItemListResponse) {
      this.storedFilterResultsLine = clonePOJO(result.ServiceBookAggregatedResponse.GroupedLineIds);
      this.storedFilterResultsProduct = clonePOJO(result.ServiceBookAggregatedResponse.GroupedProductIds);
   }
   private setSiteFilter(response: ServiceBookItemListResponse) {
      response.GroupedSiteIds = this.globals.allCustomerSites.map(site => {
         const re = new GroupedByFilterResult();
         re.Id = site.SiteId;
         re.Name = site.Name;
         const foundSites = response.ServiceBookAggregatedResponse.GroupedSiteIds.filter(res => res.Id === site.SiteId);
         if(foundSites.length > 0) {
            re.Total = foundSites[0].Total;            
         } else {
            re.Total = 0;
         }
         return re;
      });
   }
   private setAffectedAreas(response: ServiceBookItemListResponse) {
      const affectedAreaOptions = response.EventItemComponentDefinition.Segments[0].Properties.find(p => p.Id === 'affectedarea').Options;
      response.ServiceBookAggregatedResponse.GroupedAffectedAreasIds = [];
      for(const option of affectedAreaOptions) {
         const re = new GroupedByFilterResult();
         re.Id = option.Index + '';
         re.Name = option.Value;
         re.Total = 0;
         response.ServiceBookAggregatedResponse.GroupedAffectedAreasIds.push(re);
      }
   }
   private setLineFilter(request: GetServiceBookItemsRequest, response: ServiceBookItemListResponse) {      
      for(const lineOption of response.ServiceBookAggregatedResponse.GroupedLineIds) {
         lineOption.Name = this.globals.getCachedLineById(lineOption.Id).Name;
      }
      const resultingLineOptions = [];
      for(const lineOption of response.ServiceBookAggregatedResponse.GroupedLineIds) {
         const entity = this.globals.getCachedLineById(lineOption.Id);
         if(this.isSiteOfLineSelected(request.FilteredSiteIds, entity)) {
            resultingLineOptions.push(lineOption);
         }
      }            
      if(request.FilterLevel === FilterLevel.Site) {
         response.ServiceBookAggregatedResponse.GroupedLineIds = resultingLineOptions;
      } else {
         response.ServiceBookAggregatedResponse.GroupedLineIds = this.storedFilterResultsLine;
         this.onlyUpdateTotals(response.ServiceBookAggregatedResponse.GroupedLineIds, resultingLineOptions);
      }
   }
   private onlyUpdateTotals(targets: GroupedByFilterResult[], sources: GroupedByFilterResult[]) {
      if(!targets || !sources) {
         return;
      }
      for(const target of targets) {
         const source = sources.filter(s => s.Id === target.Id)[0];
         if(source) {
            target.Total = source.Total;
         } else {
            target.Total = 0;
         }
      }
   }
   private setProductFilter(request: GetServiceBookItemsRequest, response: ServiceBookItemListResponse) {      
      const resultingProductOptions = [];
      for(const productOption of response.ServiceBookAggregatedResponse.GroupedProductIds) {
         const product = this.globals.getCachedProductById(productOption.Id);
         productOption.Name = getProductName(product);
         if(this.isLineOfProductSelected(request.FilteredLineIds, product)) {
            resultingProductOptions.push(productOption);
         }
      }
      if(request.FilterLevel === FilterLevel.Site || request.FilterLevel === FilterLevel.Line) {         
         response.ServiceBookAggregatedResponse.GroupedProductIds = resultingProductOptions;
      } else {
         response.ServiceBookAggregatedResponse.GroupedProductIds = this.storedFilterResultsProduct;
         this.onlyUpdateTotals(response.ServiceBookAggregatedResponse.GroupedProductIds, resultingProductOptions);
      }
   }
   private isSiteOfLineSelected(siteIds: string[], lineEnity: LineWithContent) {
      for(const siteId of siteIds) {
         const siteEntity = this.globals.getCachedSiteById(siteId);
         if(siteEntity.hasLine(lineEnity.Id)) {
            return true;
         }
      }
      return false;
   }  
   private isLineOfProductSelected(lineIds: string[], productEntity: ProductEntity) {
      for(const lineId of lineIds) {
         const lineEntity = this.globals.getCachedLineById(lineId);
         if(lineEntity.getProductById(productEntity.ProductId)) {
            return true;
         }
      }
      return false;
   }    
   private cancelRequest() {
      if (this.requestCancelationToken && this.requestCancelationToken.request) {
         this.requestCancelationToken.request.unsubscribe();
      }
   }    
}
