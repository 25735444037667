import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EventItemFormComponent } from './event-item-form/event-item-form.component';
import { EventItemViewComponent } from './event-item-view/event-item-view.component';
import { ServiceBookItemListComponent } from './service-book-item-list-component/service-book-item-list.component';
import { Twenty47RequestFormComponent } from './twenty47-request-form/twenty47-request-form.component';
import { Twenty47RequestViewComponent } from './twenty47-request-view/twenty47-request-view.component';

@NgModule({
   imports: [
      RouterModule.forChild([
         {
            path: '',
            pathMatch: 'full',
            redirectTo: 'overview'
         }, {
            path: 'overview',
            pathMatch: 'full',
            component: ServiceBookItemListComponent
         }, {
            path: 'overview/:siteId',
            pathMatch: 'full',
            component: ServiceBookItemListComponent
         }, {
            path: 'overview/:siteId/:lineId',
            pathMatch: 'full',
            component: ServiceBookItemListComponent
         }, {
            path: 'overview/:siteId/:lineId/:productId',
            pathMatch: 'full',
            component: ServiceBookItemListComponent
         },
         /*** VIEW FORMS */  
         {
            path: 'eventItemForm/view/:id',
            component: EventItemViewComponent
         },         
         {
            path: 'twenty47RequestForm/view/:id',
            component: Twenty47RequestViewComponent
         },          
         /*** EDIT FORMS */  
         {
            path: 'eventItemForm/edit/:id',
            component: EventItemFormComponent
         },
         {
            path: 'twenty47RequestForm/edit/:id',
            component: Twenty47RequestFormComponent
         },                    
         /*** CREATE FORMS */  
         {
            path: 'eventItemForm',
            component: EventItemFormComponent
         }, 
         {
            path: 'eventItemForm/:siteId',
            component: EventItemFormComponent
         }, 
         {
            path: 'eventItemForm/:siteId/:lineId',
            component: EventItemFormComponent
         }, 
         {
            path: 'eventItemForm/:siteId/:lineId/:productId',
            component: EventItemFormComponent
         },      
         /*** CREATE FORMS */  
         {
            path: 'twenty47RequestForm',
            component: Twenty47RequestFormComponent
         },
         {
            path: 'twenty47RequestForm/:siteId',
            component: Twenty47RequestFormComponent
         },
         {
            path: 'twenty47RequestForm/:siteId/:lineId',
            component: Twenty47RequestFormComponent
         },
         {
            path: 'twenty47RequestForm/:siteId/:lineId/:productId',
            component: Twenty47RequestFormComponent
         },         
         {
            path: 'siteServiceRequestForm',
            component: Twenty47RequestFormComponent
         },
         {
            path: 'siteServiceRequestForm/:siteId',
            component: Twenty47RequestFormComponent
         },
         {
            path: 'siteServiceRequestForm/:siteId/:lineId',
            component: Twenty47RequestFormComponent
         },
         {
            path: 'siteServiceRequestForm/:siteId/:lineId/:productId',
            component: Twenty47RequestFormComponent
         }
       ]
      )]
})
export class ServicebookRoutingModule {

}
