import { AgLineActionsCellRendererComponent } from "./ag-line-actions-cell-renderer/ag-line-actions-cell-renderer.component";
import { getProductNameFromList } from "../../shared/product-helpers";

export function getColumnDefinitionLineList() {
   return [{
         headerName: 'Name',  
         field: 'Name',
         width: 150
      },         
      {
         headerName: 'Quantity',
         valueFormatter: params => {
            return params.data.Products.length;
         },
         width: 110
      },      
      {
         headerName: 'Actions',
         cellRenderer: AgLineActionsCellRendererComponent,
         width: 900
      }    
   ];
}
