<div class="mmr-global-loading">
   <mat-progress-bar mode="indeterminate" *ngIf="globals.isCurrentlyLoadingSomething === true"></mat-progress-bar>
</div> 

<div class="hfmg-wrapper">
   <app-hfmg-header></app-hfmg-header>
   <section class="hfmg-main">                
      <router-outlet></router-outlet>
   </section>
   <footer>
      <app-footer></app-footer>
   </footer>
</div>
