import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page } from './core/models/page';
import { NavigationLink } from './core/models/navigation-link';
import { clonePOJO } from 'datahive-basic';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

   constructor(public router: Router) { }

   public navigateTo(item: NavigationLink) {      
      if(item.External) {
         window.open(item.Value, '_self');
         return '';
      }
      if(item.Template === 'page') {
         this.router.navigate(['website', 'article', item.Value]);
      } else if(item.Template === 'home') {
         this.router.navigate(['website']);
      } else if(item.Template === 'portal') {
         return this.router.navigate(['/' + item.Value]);
      }
      return '';
   }
}
