import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MMRGlobals } from 'src/app/core/services/mmr-globals';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent 
       implements OnInit {
   private readonly _destroying$ = new Subject<void>();
   message: string;

   constructor(public globals: MMRGlobals,
               public router: Router,
               private authService: MsalService,
               private msalBroadcastService: MsalBroadcastService) { }

   ngOnInit(): void {
      this.globals.appLoaded = true;
      this.checkLoginState();      
      this.checkAccountMissing();
   }
   checkAccountMissing() {
      if(this.globals.hasAccountInDirectoryButNoAccountInThePortal) {
         this.message = 'Your user ' + this.globals.currentUser.FirstName + ' ' + this.globals.currentUser.LastName + ' successfully logged in, but has no account yet.';
      }      
   }
   checkLoginState() {
      this.msalBroadcastService.inProgress$
      .pipe(
         filter((status: InteractionStatus) => status === InteractionStatus.None),
         takeUntil(this._destroying$)
      )
      .subscribe(() => {
         const account = this.authService.instance.getActiveAccount();
         if(account && !this.globals.hasAccountInDirectoryButNoAccountInThePortal) {
            this.router.navigate(['portal', 'dashboard']);
         }
      });  
   }
   navigateToSignup() {
      window.open('https://www.hf-mixinggroup.com/kontakt/anfrage/', '_blank');
   }
   logout(popup?: boolean) {
      if (popup) {
      this.authService.logoutPopup({
         mainWindowRedirectUri: "/"
      });
      } else {
      this.authService.logoutRedirect();
      }
   }
}
