import { AgDocumentTitleCellRendererComponent } from "../../documentation/documentation-filter/ag-document-title-cell-renderer/ag-document-title-renderer.component";

export function getColumnDefinitionDocumentationFilterProductDetails() {
   return [
      {
         headerName: 'Filename',  
         field: 'FileName',
         cellRenderer: AgDocumentTitleCellRendererComponent,
         width: 400
      },              
      {
         headerName: 'Filesize',
         field: 'Size',
         valueFormatter: params => {
            const size = params.data.Size.replace('kB', '');
            const sizeInt = parseInt(size);
            const inMegabytes = sizeInt / 1000;
            return inMegabytes + ' MB';
         }
      },      
      {
         headerName: 'Document type',
         field: 'DocumentType'
      },          
      {
         headerName: 'Product part',
         field: 'ProductPart'
      }
   ];
}
