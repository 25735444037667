<mat-card class="mmr-white-card">
   <mat-card-header>
      <div mat-card-avatar>
         <mat-icon>admin_panel_settings</mat-icon>
      </div>
      <mat-card-title>Edit forms</mat-card-title>
      <mat-card-subtitle>Events and ticket forms can be changed externally in DataHive</mat-card-subtitle>
    </mat-card-header>      
   <mat-card-content>
   
      <mat-card class="mmr-action-card"
                (click)="goToComponentDefinitionEventItem()">
         <mat-card-header>
            <div mat-card-avatar>
               <mat-icon>event_available</mat-icon>
            </div>
            <mat-card-title>Update event item form</mat-card-title>
            <mat-card-subtitle>Click here to visit form editor</mat-card-subtitle>
         </mat-card-header>   
         <mat-card-content>
            
         </mat-card-content>
      </mat-card>      
      <mat-card class="mmr-action-card"
                (click)="goToComponentDefinitionSiteServiceRequest()">
         <mat-card-header>
            <div mat-card-avatar>
               <mat-icon>send</mat-icon>
            </div>
            <mat-card-title>Update site service requests form</mat-card-title>
            <mat-card-subtitle>Click here to visit form editor</mat-card-subtitle>
         </mat-card-header>   
         <mat-card-content>
            
         </mat-card-content>
      </mat-card>    
      <mat-card class="mmr-action-card"
                (click)="goToComponentDefinitionTwenty47Request()">
         <mat-card-header>
            <div mat-card-avatar>
               <mat-icon>question_answer</mat-icon>
            </div>
            <mat-card-title>Update 27/7 Request form</mat-card-title>
            <mat-card-subtitle>Click here to visit form editor</mat-card-subtitle>
         </mat-card-header>   
         <mat-card-content>
            
         </mat-card-content>
      </mat-card>  
      <div class="clear-both"></div>
      <img src="/assets/img/fields.gif" style="width: 700px">
   </mat-card-content>
   <mat-card-actions>              
   </mat-card-actions>         
</mat-card>
