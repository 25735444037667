import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LineWithContent } from 'src/app/core/entities/line-with-content';
import { ProductEntity } from 'src/app/core/entities/product.entity';
import { SharePointFileResults } from 'src/app/core/entities/sharepoint-file.results';
import { SiteWithContent } from 'src/app/core/entities/site-with-content';
import { MasterdataSiteRepository } from 'src/app/core/repositories/masterdata-site.repository';
import { SharePointRepository } from 'src/app/core/repositories/sharepoint.repository';
import { AreaRepository } from 'src/app/core/services/area.repository';
import { LayoutService } from 'src/app/core/services/layout.service';
import { MMRGlobals } from 'src/app/core/services/mmr-globals';
import { AGGridBaseOverviewComponent } from 'src/app/core/shared/ag-grid/ag-grid-base-overview.component';
import { getGridOptions } from 'src/app/core/shared/ag-grid/helpers';
import { BreadCrumbMenu } from 'src/app/presentation/breadcrumb-menu';
import { getProductName, getSerialNumberFromProduct } from '../../shared/product-helpers';
import { getColumnDefinitionDocumentationFilterProductDetails } from './documentation-filter.column-definition';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent 
       extends AGGridBaseOverviewComponent
       implements OnInit {

   results: SharePointFileResults;
   product: ProductEntity;
   changelogItems = [[]];
   getSerialNumberFromProduct = getSerialNumberFromProduct;
   getProductName = getProductName;
   navigateToMaintenanceDocumentation = navigateToMaintenanceDocumentation;
   constructor(public globals: MMRGlobals,
               public sharePointRepository: SharePointRepository,
               public masterdataSiteRepository: MasterdataSiteRepository,
               public layoutService: LayoutService,
               public areaRepository: AreaRepository,
               public router: Router,
               public activatedRoute: ActivatedRoute) {
      super();
   }
   public async ngOnInit() {
      this.globals.track();
      this.determineCurrentProduct();
      this.buildBurgerMenu();
      await this.initGridOptions();
      await this.reloadSearchResults();
      this.refreshView();      
   }
   public ngOnDestroy() {
      this.globals.resetBurgerMenu();
   }
   public buildBurgerMenu() {
      const burger = new BreadCrumbMenu();
      burger.addItem('All Plants', '/portal/masterdata/plant-overview');
      burger.addItem('Plant: <strong>' + this.product.SiteName + '</strong>', '/portal/masterdata/plant-detail/' + this.product.SiteId);
      burger.addItem('Product: <strong>' + getProductName(this.product) + '</strong>', '/portal/masterdata/product-detail/' + this.product.ProductId);
      this.globals.setBurgerMenu(burger);
   }
   public openSharePointLib() {
      this.globals.trackEvent('OpenLibrary', this.product.FileLocation);
      window.open(this.product.FileLocation + '/01 HFMGDocumentation', '_blank');
   }
   public showInFilter() {
      this.globals.documentFilterConfiguration.reset();
      this.globals.documentFilterConfiguration.setForProductId(this.product.ProductId, this.globals.allCustomerSites);
      this.router.navigate(['portal', 'documentation']);
   }
   private determineCurrentProduct() {
      const accessor = 'id';
      const snapshot = this.activatedRoute.snapshot;
      const productId = snapshot.params[accessor];

      this.product = this.globals.getCachedProductById(productId);
      // for(let hierarchy of this.globals.allCustomerSites) {
      //    this.product = hierarchy.getProductById(productId);
      //    if(this.product) {
      //       break;
      //    }
      // }   
   }
   timeOut : any;
   public search(search: string) {
      this.globals.showGlobalLoading();
      if(this.timeOut) {
         clearTimeout(this.timeOut);
      }
      this.timeOut = setTimeout(_ => {
         this.reloadSearchResults(search);
         this.refreshView();
         this.globals.hideGlobalLoading();
      }, 1000);      
   }   
   public async initGridOptions() {
      this.gridOptions = getGridOptions(false, this.globals, this, false);
      this.gridOptions.context = this;
      this.gridOptions.cacheQuickFilter = true;
      this.gridOptions.isFullWidthRow = this.isFullWidth;

      this.gridOptions.singleClickEdit = false;
      this.gridOptions.suppressRowClickSelection = true;  
      this.gridOptions.suppressChangeDetection = true;
      this.gridOptions.getRowHeight = params => {
         if (params.data.isFullWidth) {
           return 200;
         } else {
            return 40;
         }
      };
      this.gridOptions.columnDefs = getColumnDefinitionDocumentationFilterProductDetails();
      const prom = new Promise<void>(resolve => {
         this.gridOptions.onGridReady = () => {
            resolve();
         };
      });                  
      return prom;
   }   
   public async reloadSearchResults(search = '') {
      const query = 'HFProductIdHFMGGlobal: ' + this.product.ProductId + ' ' + search;
      this.cancelRequest();
      this.results = await this.sharePointRepository.getSearchResults(query, '', 200, this.requestCancelationToken, null, null, 5000, false, null);
   }     
   public refreshView() {
      this.gridOptions.rowData = [];
      for(const result of this.results.Results) {
         this.gridOptions.rowData.push(result);
      }
      if (this.gridOptions.api) {
         this.gridOptions.api.setRowData(this.gridOptions.rowData);
      }      
   }     
   public navigateToServiceBookForProduct() {
      if(this.product && this.product.LineId) {
         this.router.navigate(['portal', 'servicebook', 'overview', this.product.SiteId, this.product.LineId, this.product.ProductId]);
      } else {
         this.router.navigate(['portal', 'servicebook', 'overview', this.product.SiteId]);
      }  
   }   
   public navigateToCreate247Support() {
      if(this.product.LineId) {
         this.router.navigate(['portal', 'servicebook', 'twenty47RequestForm', this.product.SiteId, this.product.LineId, this.product.ProductId]);
      } else {
         this.router.navigate(['portal', 'servicebook', 'twenty47RequestForm', this.product.SiteId, 'noline', this.product.ProductId]);
      } 
   }
   public navigateToSiteServiceRequest() {
      if(this.product.LineId) {
         this.router.navigate(['portal', 'servicebook', 'siteServiceRequestForm', this.product.SiteId, this.product.LineId, this.product.ProductId]);
      } else {
         this.router.navigate(['portal', 'servicebook', 'siteServiceRequestForm', this.product.SiteId, 'noline', this.product.ProductId]);
      }  
   }
   public navigateToInspections() {
      
   }
}
export function navigateToMaintenanceDocumentation(globals: MMRGlobals, router: Router, siteId: string, lineId: string, productId: string) {
   globals.documentFilterConfiguration.enableMaintenanceDocumentation = true;
   const count = [];
   let currentSite: SiteWithContent;
   for(const site of globals.allCustomerSites) {
      if(site.SiteId === siteId) {
         count.push(true);  
         currentSite = site;
      } else {
         count.push(false);
      }
   }
   globals.documentFilterConfiguration.selectedPlantFlags = count;

   globals.documentFilterConfiguration.preselectedLineId = lineId;
   globals.documentFilterConfiguration.preselectedProductId = productId;
   const lineCount = [];
   const productCount = [];

   // if(lineId) {
   //    lineCount.push(false); //No line indicator
   //    let currentLine: LineWithContent;
   //    for(const line of currentSite.Lines) {
   //       if(line.Id === lineId) {
   //          lineCount.push(true);  
   //          currentLine = line;
   //       } else {
   //          lineCount.push(false);
   //       }            
   //    }
   //    if(productId) {
   //       for(const product of currentLine.Products) {
   //          if(product.ProductId === productId) {
   //             productCount.push(true);
   //          } else {
   //             productCount.push(false);
   //          }
   //       }  
   //    }       
   // } else {
   //    lineCount.push(true); //No line indicator
   //    if(productId) {
   //       for(const product of currentSite.Products) {
   //          if(product.ProductId === productId) {
   //             productCount.push(true);
   //          } else {
   //             productCount.push(false);
   //          }
   //       }
   //    }
   // }
   globals.documentFilterConfiguration.selectedProductFlags = productCount;         
   globals.documentFilterConfiguration.selectedLineFlags = lineCount;    
   router.navigate(['portal', 'documentation', 'filter']);
}