import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { CacheService, Msal2Provider, Providers } from '@microsoft/mgt';
import { ApplyColumnStateParams } from 'ag-grid-community';
import { clonePOJO } from 'datahive-basic';
import { getColDefinitionsForComponentDefinition, removeGeneralColumnDefinition } from 'datahive-ngx-structure';
import { showYesNoDialog } from 'datahive-ngx-ui';
import { ServiceBookItem } from 'src/app/core/entities/service-book/service-book-item';
import { EventOwnership } from 'src/app/core/enums/event-ownership';
import { FilterLevel } from 'src/app/core/enums/filter-level';
import { ServiceBookItemType } from 'src/app/core/enums/service-book-item-type';
import { GetServiceBookItemsRequest } from 'src/app/core/input-data/service-book/get-service-book-items.request';
import { ServiceBookItemDeleteRequest } from 'src/app/core/input-data/service-book/service-book-item-delete.request';
import { GroupedByFilterResult } from 'src/app/core/models/grouped-by-filter-results';
import { ServiceBookItemsPresenter } from 'src/app/core/output-boundaries/service-book-items.presenter';
import { LayoutService } from 'src/app/core/services/layout.service';
import { MMRGlobals } from 'src/app/core/services/mmr-globals';
import { AGGridBaseOverviewComponent } from 'src/app/core/shared/ag-grid/ag-grid-base-overview.component';
import { getDetailColumn, getGridOptions } from 'src/app/core/shared/ag-grid/helpers';
import { EventItemDeleteUseCaseService } from 'src/app/core/use-cases/service-book/event-item-delete.use-case';
import { EventItemRestoreUseCaseService } from 'src/app/core/use-cases/service-book/event-item-restore.use-case';
import { ServiceBookItemsGetUseCaseService } from 'src/app/core/use-cases/service-book/service-book-items-get.use-case';
import { SiteServiceRequestDeleteUseCaseService } from 'src/app/core/use-cases/service-book/site-service-request-delete.use-case';
import { Twenty47RequestDeleteUseCaseService } from 'src/app/core/use-cases/service-book/twenty47-request-delete.use-case';
import { showSnackBar, showSnackBarError } from '../../shared/material-helpers';
import { getDefaultColumnStateServiceBook } from './column-state';
import { getColumnDefinitionServiceBookItem } from './service-book-item-filter.column-definition';
import { ServiceBookItemListPresenterService } from './service-book-item-list.presenter.service';
import { ServiceBookItemListViewModel } from './service-book-item-list.view-model';

@Component({
  selector: 'app-service-book-item-list-component',
  templateUrl: './service-book-item-list.component.html',
  styleUrls: ['./service-book-item-list.component.scss'],
  providers: [
     ServiceBookItemsGetUseCaseService,
     EventItemDeleteUseCaseService,
     { provide: ServiceBookItemsPresenter, useClass: ServiceBookItemListPresenterService},
  ]
})
export class ServiceBookItemListComponent 
       extends AGGridBaseOverviewComponent
       implements OnInit {

   doneWithInitialLoading = false;
   resultsCount;

   filteredSiteIds: string[] = [];
   filteredLineIds: string[] = [];
   filteredProductIds: string[] = [];
   filteredAffectedAreaIds: string[] = [];

   filterOptionsSites: GroupedByFilterResult[];
   filterOptionsLines: GroupedByFilterResult[];
   filterOptionsProducts: GroupedByFilterResult[];
   filterOptionsAffectedAreas: GroupedByFilterResult[];

   FilterLevel = FilterLevel;

   preselectedSiteId: string;
   preselectedLineId: string;
   preselectedProductId: string;
   eventOwnershipFilter = 0;
   dateRangeFilterStart: any;
   dateRangeFilterEnd: any;
   
   constructor(public globals: MMRGlobals,
               public matDialog: MatDialog,
               public router: Router,
               public ngZone: NgZone,
               public snackbar: MatSnackBar,
               public activatedRoute: ActivatedRoute,
               public msalService: MsalService,
               public layoutService: LayoutService,
               public changeDetectorRef: ChangeDetectorRef,
               public serviceBookItemsGet: ServiceBookItemsGetUseCaseService,
               public eventItemDeleteUseCase: EventItemDeleteUseCaseService,
               public eventItemRestoreUseCase: EventItemRestoreUseCaseService,
               public siteServiceRequestDeleteUseCase: SiteServiceRequestDeleteUseCaseService,
               public twenty47RequestDeleteUseCase: Twenty47RequestDeleteUseCaseService,
               public presenter: ServiceBookItemsPresenter<ServiceBookItemListViewModel>) {
      super();
   }
   public async ngOnInit() {   
      this.globals.track();
      this.alertIfNoCustomerSelectedInSimulation();
      await this.initGridOptions();   
      this.determineSelectedEntities();
      let alreadyFetchedData = false;
      if(this.preselectedSiteId) {
         await this.setAndLoadPreselectedItems();
         alreadyFetchedData = true;
      }
      if(!alreadyFetchedData) {
         await this.refreshFilter(FilterLevel.JustRefresh);
      }
      this.setColDefs();
      this.doneWithInitialLoading = true;
      /** If navigating here with a siteId set in the url, the site filter flag is NOT set for some reason. I do not get why - so I have to 
       *  do it again here. Please research whats wrong and please correct that.
       */
      setTimeout(_ => {
         const ref = clonePOJO<string[]>(this.filteredSiteIds);
         this.filteredSiteIds = [];
         this.filteredSiteIds = ref;
      }, 0);
   }   
   public getColumnState() {
      const state = this.gridOptions.columnApi.getColumnState();
      console.log(JSON.stringify(state));
   }
   private alertIfNoCustomerSelectedInSimulation() {
      if(!this.globals.allCustomerSites) {
         showSnackBarError('You have not selected any customer to simulate.', this.snackbar);
         this.doneWithInitialLoading = true;
         throw 'Wrong navigation';
      }
   }
   private async determineSelectedEntities() {      
      const siteIdAccessor = 'siteId';
      const lineIdAccessor = 'lineId';
      const productIdaccessor = 'productId';

      const snapshot = this.activatedRoute.snapshot;
      this.preselectedSiteId = snapshot.params[siteIdAccessor];
      this.preselectedLineId = snapshot.params[lineIdAccessor];
      this.preselectedProductId = snapshot.params[productIdaccessor];   
   }   
   private async setAndLoadPreselectedItems() {
      if(this.preselectedSiteId) {
         this.filteredSiteIds = [];
         this.filteredSiteIds.push(this.preselectedSiteId);
         let alsoFetchNextLevel = !!this.preselectedLineId;
         await this.refreshFilter(FilterLevel.Site, alsoFetchNextLevel);
         if(alsoFetchNextLevel) {
            this.filteredLineIds = [];
            this.filteredLineIds.push(this.preselectedLineId);
            alsoFetchNextLevel = !!this.preselectedProductId;
            await this.refreshFilter(FilterLevel.Line, alsoFetchNextLevel);
            if(alsoFetchNextLevel){
               this.filteredProductIds = [];               
               this.filteredProductIds.push(this.preselectedProductId);
               await this.refreshFilter(FilterLevel.Product);
            }            
         }         
      }
   }
   public async updatedSite() {
      await this.refreshFilter(FilterLevel.Site);
   }
   public async updatedLine() {
      await this.refreshFilter(FilterLevel.Line);
   }
   public async updatedProduct() {
      await this.refreshFilter(FilterLevel.Product);
   }
   public async updatedAffectedArea() {
      await this.refreshFilter(FilterLevel.JustRefresh);
   }
   public reset() {
      this.filteredSiteIds = [];
      this.filteredLineIds = [];
      this.filteredProductIds = [];
      this.filterOptionsAffectedAreas = [];
      delete this.dateRangeFilterEnd;
      delete this.dateRangeFilterStart;
      this.eventOwnershipFilter = 0;
      this.quickFilter = '';
      this.refreshFilter(FilterLevel.Reset);
   }
   public onDelete(serviceBookItem: ServiceBookItem, type: ServiceBookItemType) { 
      showYesNoDialog('Deleting ' + serviceBookItem.returnName(), 'Are you sure you want to delete \'' + serviceBookItem.Name + '\'?', this.matDialog, async _ => {
         const request = new ServiceBookItemDeleteRequest();
         request.Id = serviceBookItem.Id;           
         try {
            showSnackBar('Deleting', this.globals.matSnackbar);
            if(type === ServiceBookItemType.EventItem) {
               await this.eventItemDeleteUseCase.execute(request);
            } else if(type === ServiceBookItemType.SiteServiceRequest) {
               await this.siteServiceRequestDeleteUseCase.execute(request);
            } else if(type === ServiceBookItemType.Twenty47Request) {
               await this.twenty47RequestDeleteUseCase.execute(request);
            }            
            await this.refreshFilter(FilterLevel.JustRefresh);
            showSnackBar('Successfully deleted', this.globals.matSnackbar);
         } catch(e) {
            showSnackBarError('Error: ' + e, this.globals.matSnackbar);
         }                  
      });
   }   
   public onRestore(serviceBookItem: ServiceBookItem) { 
      showYesNoDialog('Restoring ' + serviceBookItem.returnName(), 'Are you sure you want to restore \'' + serviceBookItem.Name + '\'?', this.matDialog, async _ => {
         const request = new ServiceBookItemDeleteRequest();
         request.Id = serviceBookItem.Id;           
         try {
            showSnackBar('Restoring', this.globals.matSnackbar);
            await this.eventItemRestoreUseCase.execute(request);        
            await this.refreshFilter(FilterLevel.JustRefresh);
            showSnackBar('Successfully restored', this.globals.matSnackbar);
         } catch(e) {
            showSnackBarError('Error: ' + e, this.globals.matSnackbar);
         }                  
      });
   }
   public add23Hours(endDate: Date) {
      if(!endDate) { return null; }
      const newEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endDate.getHours() + 24, endDate.getMinutes(), endDate.getSeconds());
      return newEndDate;
   }   
   public setNextDays(nextDays: number) {
      const today = new Date();
      const thirtyMinutesBeforeYesterdayEnds = new Date(today.getFullYear(), today.getMonth(), today.getDate() -1, 23, 30, 0);
      this.dateRangeFilterStart = thirtyMinutesBeforeYesterdayEnds; /** Events that are created for today, will get a date at midnight of today and should still be shown */
      this.dateRangeFilterEnd = new Date();
      this.dateRangeFilterEnd.setDate(this.dateRangeFilterStart.getDate() + nextDays);
      this.refreshFilter(FilterLevel.QuickFilter);
   }
   public onEdit(id: string, route: string) {      
      this.router.navigate(['/portal/servicebook', route, 'edit', id]);
   }
   private resetHigherLevelFilters(filterLevel: FilterLevel) {
      if(filterLevel === FilterLevel.Site) {
         this.filteredProductIds = [];
         this.filteredLineIds = [];
      }      
      if(filterLevel === FilterLevel.Line) {
         this.filteredProductIds = [];
      }
   }
   public async refreshFilter(filterLevel: FilterLevel, onlyFetchFilters = false) {
      this.resetHigherLevelFilters(filterLevel);  
      this.globals.showGlobalLoading();
      const request = new GetServiceBookItemsRequest();
      request.QuickFilter = this.quickFilter;
      request.FilterLevel = filterLevel;
      request.OnlyFetchFilters = onlyFetchFilters;
      request.FilteredSiteIds = this.filteredSiteIds;
      request.FilteredLineIds = this.filteredLineIds;
      request.FilteredProductIds = this.filteredProductIds;           
      request.FilteredAffecteAreaIds = this.filteredAffectedAreaIds; 
      request.EventOwnershipFilter = this.eventOwnershipFilter;
      request.StartDateFilter = this.dateRangeFilterStart;   
      request.EndDateFilter = this.add23Hours(this.dateRangeFilterEnd);
      if(this.globals.simulationActive) {
         request.CustomerId = this.globals.currentlySimulatedCustomerId;
      }
      await this.serviceBookItemsGet.execute(request);
      this.refreshView();
      this.globals.hideGlobalLoading();
   }   
   public createNewSiteServiceRequest() {      
      this.navigateToFormWithEntityPath('siteServiceRequestForm');      
   }
   public createNewTwenty47Request() {
      this.navigateToFormWithEntityPath('twenty47RequestForm');
   }
   public createEventItem() {
      this.navigateToFormWithEntityPath('eventItemForm');      
   }
   private navigateToFormWithEntityPath(formRoute: string) {
      const isSingleSiteFilterActive = this.filteredSiteIds.filter(id => id).length === 1;
      const isSingleLineFilterActive = this.filteredLineIds.filter(id => id).length === 1;
      const isSingleProductFilterActive = this.filteredProductIds.filter(id => id).length === 1;

      const isOnlySingleSiteFilterActive = isSingleSiteFilterActive && !isSingleLineFilterActive && !isSingleProductFilterActive;
      const areOnlySingleSiteAndLineFiltersActive = isSingleSiteFilterActive && isSingleLineFilterActive && !isSingleProductFilterActive;
      const areAllEntityFiltersActive = isSingleSiteFilterActive && isSingleLineFilterActive && isSingleProductFilterActive;

      if(isOnlySingleSiteFilterActive) {
         this.router.navigate(['/portal/servicebook', formRoute, this.filteredSiteIds[0]]);
      } else if(areOnlySingleSiteAndLineFiltersActive) {
         this.router.navigate(['/portal/servicebook', formRoute, this.filteredSiteIds[0], this.filteredLineIds[0]]);
      } else if(areAllEntityFiltersActive) {
         this.router.navigate(['/portal/servicebook', formRoute, this.filteredSiteIds[0], this.filteredLineIds[0], this.filteredProductIds[0]]);
      } else{
         this.router.navigate(['/portal/servicebook', formRoute]);
      }    
   }
   public async initGridOptions() {
      this.gridOptions = getGridOptions(false, this.globals, this, false);
      this.gridOptions.context = this;
      this.gridOptions.cacheQuickFilter = true;
      this.gridOptions.isFullWidthRow = this.isFullWidth;
      this.gridOptions.groupHeaderHeight = 0,
      this.gridOptions.singleClickEdit = false;
      this.gridOptions.suppressRowClickSelection = true;  
      this.gridOptions.suppressChangeDetection = true;
      this.gridOptions.defaultColDef.suppressMenu = true;
      this.gridOptions.columnDefs = getColumnDefinitionServiceBookItem(this);
      this.gridOptions.getRowHeight = params => {
         if (params.data.isFullWidth) {
           return 200;
         } else {
            return 40;
         }
      };
      const prom = new Promise<void>(resolve => {
         this.gridOptions.onGridReady = () => {
            resolve();
         };
      });
      return prom;
   }
   public setColDefs() {
      const componentDefinitionColumns = getColDefinitionsForComponentDefinition(this.presenter.viewModel.response.EventItemComponentDefinition);
      removeGeneralColumnDefinition(this.gridOptions as any);
      this.gridOptions.columnDefs.push(...componentDefinitionColumns);
      this.gridOptions.columnDefs.push(getDetailColumn());
      this.gridOptions.api.setColumnDefs(this.gridOptions.columnDefs);
      const params = {
         state: getDefaultColumnStateServiceBook(),
         applyOrder: true
      } as ApplyColumnStateParams;
      this.gridOptions.columnApi.applyColumnState(params);
   }
   public refreshView() {
      this.gridOptions.rowData = [];

      this.filterOptionsSites = this.presenter.viewModel.response.GroupedSiteIds;
      this.filterOptionsLines = this.presenter.viewModel.response.ServiceBookAggregatedResponse.GroupedLineIds;
      this.filterOptionsProducts = this.presenter.viewModel.response.ServiceBookAggregatedResponse.GroupedProductIds;
      this.filterOptionsAffectedAreas = this.presenter.viewModel.response.ServiceBookAggregatedResponse.GroupedAffectedAreasIds;
      const results = this.presenter.viewModel.response.ServiceBookAggregatedResponse.Results;
      for(const result of results) {
         this.gridOptions.rowData.push(result);     
      }
      this.resultsCount = this.presenter.viewModel.response.ServiceBookAggregatedResponse.Total;
      if (this.gridOptions.api) {
         this.gridOptions.api.setRowData(this.gridOptions.rowData);
         setListHeightAccordingToItemCount(this.gridOptions.rowData.length);
      }      
   }
   public onDeleteAg(item: ServiceBookItem, context: ServiceBookItemListComponent) {
      context.ngZone.run(() => {
         context.onDelete.call(context, item, item.ServiceBookItemType);
      });
   }
   public onEditAg(item: ServiceBookItem, context: ServiceBookItemListComponent) {  
      context.ngZone.run(() => {
         context.onEdit.call(context, item.Id, item.returnRoute());
      });      
   }
   public onRestoreAg(item: ServiceBookItem, context: ServiceBookItemListComponent) {  
      context.ngZone.run(() => {
         context.onRestore.call(context, item);
      });      
   }
}

export function setListHeightAccordingToItemCount(numberOfItems: number) {
   const rowHeightInRem = 40;
   document.getElementById('mmr-current-main-list').style.height = (numberOfItems * rowHeightInRem) + 100 + 'rem';
}