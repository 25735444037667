import { LineWithContent } from "./line-with-content";
import { ProductEntity } from "./product.entity";
import { SiteWithContent } from "./site-with-content";

export class SharePointFile {
   public FileName: string;
   public WebUrl: string;
   public Author: string;
   public Size: string;
   public DownloadUrl: string;
   public ApplicationUrl: string;
   public LocationUrl: string;
   public People: string;
   public Type: string;
   public Title: string;
   public ThumbnailURL: string;
   public Path: string;
   public Description: string; 
   public DocId: string;
   public Plant: string;  
   public Line: string;
   public ProductType: string;
   public SerialNumber: string;
   public ProductId: string;
   public ProductPart: string;
   public DocumentType: string;

   public PlantFileLocation: string;
   public LineFileLocation: string;
   public ProductFileLocation: string;
   public OrderFileLocation: string;

   public Tags: string;

   public setFileLocationsFromMasterdata(sites: SiteWithContent[]) {
      let site: SiteWithContent;
      if(this.Plant) {
         site = sites.find(s => s.Name === this.Plant);
         this.PlantFileLocation = site.FileLocation;
      }
      let line: LineWithContent;
      if(this.Line) {
         line = site.getLineByName(this.Line);
         if(line.FileLocation) {
            this.LineFileLocation = line.FileLocation;
         }         
      }      
      let product: ProductEntity;
      if(this.ProductId) {
         product = site.getProductById(this.ProductId);
         this.ProductFileLocation = product.FileLocation;
      }
   }
}
