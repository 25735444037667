import { Subscription } from 'rxjs';
import { PlantEntity } from '../entities/plant.entity';
import { QueryRequest } from '../input-data/query.request';

export abstract class PlantRepository {
   public abstract getById(plantId: string, renderOptions: boolean): Promise<PlantEntity>;

   public abstract getByIdWithChangelog(plantId: string,renderOptions: boolean): Promise<PlantEntity[]>;

   public abstract searchPlants(query: QueryRequest, requestReference: {request: Subscription}): Promise<PlantEntity[]>;

   public abstract getByCustomerId(enterpriseId: string, renderOptions: boolean): Promise<PlantEntity[]>;
}
