import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-spareparts-iframe',
  templateUrl: './spareparts-iframe.component.html',
  styleUrls: ['./spareparts-iframe.component.scss']
})
export class SparepartsIframeComponent 
       implements OnInit {
  
    url='';
    constructor(public activatedRoute: ActivatedRoute,) { }

    ngOnInit(): void {
      this.determineLineAndProductId();      
    }
    determineLineAndProductId() {
      const snapshot = this.activatedRoute.snapshot;
      this.url = snapshot.params['url'];
    }


}
