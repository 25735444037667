import {Component} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerEntity } from 'src/app/core/entities/customer.entity';
import { PlantEntity } from 'src/app/core/entities/plant.entity';
import { MasterdataRepository } from 'src/app/core/repositories/masterdata.repository';
import { PlantRepository } from 'src/app/core/repositories/plant.repository';
import { LayoutService } from 'src/app/core/services/layout.service';
import { MMRGlobals } from 'src/app/core/services/mmr-globals';

@Component({
   templateUrl : './ag-user-picker-actions-cell-renderer.component.html',
   styleUrls: ['./ag-user-picker-actions-cell-renderer.component.scss']
})
export class AgUserPickerActionsCellRendererComponent {
   customer: CustomerEntity;
   constructor(public globals: MMRGlobals,
               public plantRepository: PlantRepository,
               public router: Router,
               public layoutService: LayoutService,
               public masterdataRepository: MasterdataRepository) {

   }
   public agInit(params: any): void {
      if (params) {
         this.customer = params.data; 
         this.customer.Sites = [];        
      }
   }
   public async loadPlants() {
      if(this.customer.Sites.length === 0) {
         this.customer.Sites = await this.plantRepository.getByCustomerId(this.customer.EnterpriseId, false);
      }      
   }
   async startSimulation(customerId: string, siteId: string = null) {
      await startSimulation(customerId, 
                            siteId, 
                            this.masterdataRepository, 
                            this.globals,
                            this.router,
                            this.layoutService);
   }
}
export async function startSimulation(customerId: string, 
                                      siteId: string = null,
                                      masterdataRepository: MasterdataRepository,
                                      globals: MMRGlobals,
                                      router: Router,
                                      layoutService: LayoutService) {      
   globals.showGlobalLoading();
   globals.allCustomerSites = await masterdataRepository.getSitesWithContentForCustomerId(customerId, null);
   globals.currentlySimulatedCustomerId = customerId;
   globals.currentlySimulatedPlantId = siteId;      
   globals.simulationActive = true;
   globals.hideGlobalLoading();
   layoutService.setNavigationtoCustomerPortalDefaultInternal();
   router.navigate(['portal', 'dashboard']);
}