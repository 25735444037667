import { Injectable } from '@angular/core';
import {
  getEntitiesFromDatabuckets,
  getEntityFromDatabucket,
} from 'datahive-basic';
import { Subscription } from 'rxjs';
import { LineWithContent } from '../core/entities/line-with-content';
import { ProductEntity } from '../core/entities/product.entity';
import { SiteWithContent } from '../core/entities/site-with-content';
import { MasterdataRepository } from '../core/repositories/masterdata.repository';
import { MMRGlobals } from '../core/services/mmr-globals';
import { MMRHttpClient } from '../core/shared/mmr-http-client';
import { GetSiteLocationsApiModel } from '../core/api-models/get-site-locations.api-model';
import { SiteLocationCollection } from '../core/models/site-location-collection';

@Injectable()
export class MasterdataRepositoryService implements MasterdataRepository {
  constructor(public bsGlobals: MMRGlobals, public http: MMRHttpClient) {}
  public async getSitesWithContentForCustomerId(
    customerId: string,
    requestReference: { request: Subscription }
  ): Promise<SiteWithContent[]> {
    const sites = await this.http.get<SiteWithContent[]>(
      this.bsGlobals.urlMatrix.MasterdataApiUrl + 'sites/' + customerId,
      requestReference
    );
    const results = this.transformDTOsToEntities(sites);
    return results;
  }
  public async getSiteLocationsAsync(
    apiModel: GetSiteLocationsApiModel
  ): Promise<SiteLocationCollection> {
    const site = await this.http.post<
      GetSiteLocationsApiModel,
      SiteLocationCollection
    >(this.bsGlobals.urlMatrix.MasterdataApiUrl + 'getSiteLocations', apiModel);
    return site;
  }
  public transformDTOsToEntities(sites: any[]): SiteWithContent[] {
    const siteEntities = getEntitiesFromDatabuckets<SiteWithContent>(
      sites,
      SiteWithContent
    );
    const results = [];
    for (const site of siteEntities) {
      if (!site.Lines) {
        continue;
      }
      const lineEntities = [];
      for (const line of site.Lines) {
        const lineEntity = getEntityFromDatabucket<LineWithContent>(
          line,
          LineWithContent
        );

        const productEntities = [];
        for (const product of lineEntity.Products) {
          const productEntity = getEntityFromDatabucket<ProductEntity>(
            product,
            ProductEntity
          );
          productEntities.push(productEntity);
        }
        lineEntity.Products = productEntities;
        lineEntities.push(lineEntity);
      }
      site.Lines = lineEntities;

      const productEntities = [];
      for (const product of site.Products) {
        const productEntity = getEntityFromDatabucket<ProductEntity>(
          product,
          ProductEntity
        );
        productEntities.push(productEntity);
      }
      site.Products = productEntities;
      results.push(site);
    }
    return results;
  }
}
