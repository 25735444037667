import { Subscription } from "rxjs";
import { EventItemEntity } from "../entities/service-book/event-item";
import { ServiceBookItem } from "../entities/service-book/service-book-item";
import { SiteServiceRequestEntity } from "../entities/service-book/site-service-request";
import { Twenty47RequestEntity } from "../entities/service-book/twenty47-request";
import { QueryRequest } from "../input-data/query.request";
import { ServiceBookAggregatedResponse } from "../models/service-book-aggregated.response";

export abstract class ServiceBookRepository {
   public abstract searchServiceBook(query: QueryRequest, requestReference: { request: Subscription; }): Promise<ServiceBookAggregatedResponse>;
   public abstract checkForAttachments(serviceBookItemId: string);

   public abstract createTwenty47Request(twenty47Request: Twenty47RequestEntity): Promise<string>;
   public abstract editTwenty47Request(serviceBookItemId: string, twenty47Request: Twenty47RequestEntity): Promise<boolean>;
   public abstract deleteTwenty47Request(serviceBookItemId: string): Promise<boolean>;
   public abstract getTwenty47RequestById(serviceBookItemId: string, renderOptions: boolean): Promise<Twenty47RequestEntity>;

   public abstract createSiteServiceRequest(siteServiceRequest: SiteServiceRequestEntity): Promise<string>;
   public abstract editSiteServiceRequest(serviceBookItemId: string, siteServiceRequest: SiteServiceRequestEntity): Promise<boolean>;
   public abstract deleteSiteServiceRequest(serviceBookItemId: string): Promise<boolean>;
   public abstract getSiteServiceRequestById(serviceBookItemId: string, renderOptions: boolean): Promise<SiteServiceRequestEntity>;
   
   public abstract createEventItem(eventItem: EventItemEntity): Promise<string>;
   public abstract editEventItem(serviceBookItemId: string, eventItem: EventItemEntity): Promise<boolean>;
   public abstract deleteEventItem(serviceBookItemId: string): Promise<boolean>;
   public abstract restoreEventItem(serviceBookItemId: string): Promise<boolean>;
   public abstract getEventItemById(serviceBookItemId: string, renderOptions: boolean): Promise<EventItemEntity>;
}
