import {Component} from '@angular/core';
import { Router } from '@angular/router';
import { ServiceBookItem } from 'src/app/core/entities/service-book/service-book-item';
import { SiteServiceRequestEntity } from 'src/app/core/entities/service-book/site-service-request';
import { Twenty47RequestEntity } from 'src/app/core/entities/service-book/twenty47-request';
import { ServiceBookItemType } from 'src/app/core/enums/service-book-item-type';
import { ServiceBookItemDeleteRequest } from 'src/app/core/input-data/service-book/service-book-item-delete.request';
import { MMRGlobals } from 'src/app/core/services/mmr-globals';
import { EventItemDeleteUseCaseService } from 'src/app/core/use-cases/service-book/event-item-delete.use-case';
import { EventItemEditUseCaseService } from 'src/app/core/use-cases/service-book/event-item-edit.use-case';
import { ServiceBookItemListComponent } from '../service-book-item-list.component';

@Component({
   templateUrl : './ag-service-book-item-actions-renderer.component.html',
   styleUrls: ['./ag-service-book-item-actions-renderer.component.scss']
})
export class AgServiceBookItemActionsCellRendererComponent {
   serviceBookItem: ServiceBookItem;
   onDelete: any = _ => {};
   onRestore: any = _ => {};
   context: ServiceBookItemListComponent;
   onEdit: any = _ => {};
   editRouteSegment = '';
   hasWritePermission = false;
   constructor(public globals: MMRGlobals,
               public router: Router,
               public eventItemDeleteUseCaseService: EventItemDeleteUseCaseService) {

   }
   public agInit(params: any): void {
      if (params) {         
         this.serviceBookItem = params.data;
         this.hasWritePermission = this.serviceBookItem.hasWritePermission(this.globals.currentCustomerPortalProfile);
         this.onDelete = params.onDelete;
         this.onEdit = params.onEdit;   

         this.onRestore = params.onRestore,
         this.context = params.context;
         if(this.serviceBookItem instanceof SiteServiceRequestEntity) {
            this.editRouteSegment = '';
         } else if(this.serviceBookItem instanceof Twenty47RequestEntity) {
            this.editRouteSegment = '';
         } else {
            this.editRouteSegment = 'eventItemForm';
         }
      }
   }
}
