import { Injectable } from "@angular/core";

import { CustomerEntity } from "../core/entities/customer.entity";
import { SharePointFileResults } from "../core/entities/sharepoint-file.results";
import { AdminRepository } from "../core/repositories/admin.repository";
import { MasterdataRepository } from "../core/repositories/masterdata.repository";
import { SharePointRepository } from "../core/repositories/sharepoint.repository";
import { MasterdataCache } from "../core/services/mmr-cache";
import { DocumentFilterConfiguration } from "../core/services/mmr-documentfilter-configuration";
import { MMRGlobals } from "../core/services/mmr-globals";

@Injectable()
export class MMRResolver
       {
   constructor(public mmrGlobals: MMRGlobals,
               public cache: MasterdataCache,
               public adminRepository: AdminRepository,
               public spoRepository: SharePointRepository,               
               public masterdataRepository: MasterdataRepository) {
   }
   public async resolve(): Promise<void> {  
      await this.setUserContext();
      if(this.mmrGlobals.currentCustomerPortalProfile) {
         if(this.mmrGlobals.currentCustomerPortalProfile.Internal === false) {
            await this.useCache();
            this.setCurrentCustomer();
            this.cacheDocumentation();
         }
         this.mmrGlobals.currentRole = this.mmrGlobals.currentCustomerPortalProfile.Internal ? 'Internal user' : 'Customer';
      }      
      this.mmrGlobals.appLoaded = true;
   }   
   private cacheDocumentation() {
      const maxFilesInPreview = 300;
      const fileCountPerSite = Math.floor(maxFilesInPreview / this.mmrGlobals.allCustomerSites.length);           
      setTimeout(async _ => {
         
         if(this.mmrGlobals.allCustomerSites.length > 1) {
            await this.cacheDocumentationForAllSites(maxFilesInPreview);
         } else {
            await this.cacheDocumentationForSingleSite();
         }
         
         this.mmrGlobals.documentationPreviewArrived.next(true);
         await this.cache.initCache();
      }, 0);
   }
   private async cacheDocumentationForAllSites(fileCountPerSite: number) {
      let totals = 0; 
      let filesForPreview = [];
      for(const site of this.mmrGlobals.allCustomerSites) {
         const fullQuery = 'HFCustomer:"' + this.mmrGlobals.currentCustomer.Name + '" HFPlant:"' + site.Name + '" AND DocumentKind:"01 HFMGDocumentation"';
         const filesForPreviewThisSite = await this.spoRepository.getSearchResults(fullQuery, '', fileCountPerSite, null, null, null, fileCountPerSite, false, null);
         filesForPreview = filesForPreview.concat(filesForPreviewThisSite.Results);
         totals += filesForPreviewThisSite.Total;
      }
      this.mmrGlobals.documentFilterConfiguration = new DocumentFilterConfiguration();
      this.mmrGlobals.documentFilterConfiguration.cacheContainsSingleSite = false;
      this.mmrGlobals.cachedPreviewDocuments = new SharePointFileResults(totals);         
      this.mmrGlobals.cachedPreviewDocuments.Results = filesForPreview;
      this.mmrGlobals.cachedPreviewDocuments.enrichFilesWithFileLocations(this.mmrGlobals.allCustomerSites);
   }
   private async cacheDocumentationForSingleSite() {
      let totals = 0; 
      let filesForPreview = [];
      const fullQuery = 'HFCustomer:"' + this.mmrGlobals.currentCustomer.Name + '" HFPlant:"' + this.mmrGlobals.allCustomerSites[0].Name + '" AND DocumentKind:"01 HFMGDocumentation"';
      const filesForPreviewThisSite = await this.spoRepository.getSearchResults(fullQuery, '', 500, null, null, null, 5000, false, null);
      filesForPreview = filesForPreview.concat(filesForPreviewThisSite.Results);
      totals += filesForPreviewThisSite.Total;
      this.mmrGlobals.documentFilterConfiguration = new DocumentFilterConfiguration();
      this.mmrGlobals.documentFilterConfiguration.cacheContainsSingleSite = true;
      this.mmrGlobals.cachedPreviewDocuments = new SharePointFileResults(totals);         
      this.mmrGlobals.cachedPreviewDocuments.Results = filesForPreview;
      this.mmrGlobals.cachedPreviewDocuments.enrichFilesWithFileLocations(this.mmrGlobals.allCustomerSites);
   }
   private async setUserContext() {
      this.mmrGlobals.currentUser = await this.mmrGlobals.getCurrentUser();
      try {
         this.mmrGlobals.currentCustomerPortalProfile = await this.adminRepository.getMyself();
         this.mmrGlobals.currentCustomerId = this.mmrGlobals.currentCustomerPortalProfile.CustomerId;
      } catch(e: any) {
         if(e.message.includes('not found')) {
            this.mmrGlobals.hasAccountInDirectoryButNoAccountInThePortal = true;
         }         
      }            
   }
   private async useCache() {
      const allSitesJson = localStorage.getItem('cached' + this.mmrGlobals.currentCustomerId);
      const gotDataFromCache = !(!allSitesJson || allSitesJson.length < 10);
      if(gotDataFromCache === false) {
         this.mmrGlobals.allCustomerSites = await this.masterdataRepository.getSitesWithContentForCustomerId(this.mmrGlobals.currentCustomerId, null);
         this.storeMasterdataInCache(this.mmrGlobals.currentCustomerId);
      } else {
         const sites = JSON.parse(allSitesJson);
         const entities = this.masterdataRepository.transformDTOsToEntities(sites);
         this.mmrGlobals.allCustomerSites = entities;
      }
      if(gotDataFromCache) {
         setTimeout(async _ => {
            this.mmrGlobals.allCustomerSites = await this.masterdataRepository.getSitesWithContentForCustomerId(this.mmrGlobals.currentCustomerId, null);
            this.storeMasterdataInCache(this.mmrGlobals.currentCustomerId);
         }, 0);
      }      
   }
   private async setCurrentCustomer() {
      this.mmrGlobals.currentCustomer = new CustomerEntity();
      this.mmrGlobals.currentCustomer.Name = this.mmrGlobals.allCustomerSites[0].EnterpriseName;
      this.mmrGlobals.currentCustomer.EnterpriseId = this.mmrGlobals.allCustomerSites[0].EnterpriseId;
   }
   private storeMasterdataInCache(customerId: string) {
      localStorage.setItem('cached' + customerId, JSON.stringify(this.mmrGlobals.allCustomerSites));
   }
}
 