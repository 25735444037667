import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MMRGlobals } from 'src/app/core/services/mmr-globals';
import { AdminRepository } from './core/repositories/admin.repository';
import { LayoutService } from './core/services/layout.service';
import { AuthenticationService } from './core/services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
   title = 'HF Mixing Group';
   
   @ViewChild('sideNav') public sideNav: MatSidenav;

   constructor(public globals: MMRGlobals,
               public repository: AdminRepository,               
               public layoutService: LayoutService,
               public authenticationService: AuthenticationService,
               public router: Router) {}  

   ngOnInit(): void {      
      this.authenticationService.init();
   }  
   ngOnDestroy(): void {
      this.authenticationService.onDestroy();
   }    
}
export function isTestSystem(): boolean {
   return (location.hostname.includes('cp-webapp-test') || location.hostname.includes('localhost'));
}