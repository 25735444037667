<div class="mmr-min-page-min-height">
   <mat-card class="mmr-flex-card mmr-white-card">
      <mat-card-header>
         <div mat-card-avatar>
            <mat-icon>location_city</mat-icon>
         </div>
         <mat-card-title *ngIf="allSites && allSites.length > 0">{{allSites[0].EnterpriseName}}</mat-card-title>
         <mat-card-subtitle>Click on a plant to see all products</mat-card-subtitle>
      </mat-card-header>      
      <mat-card-content>
         <div class="mmr-plant-outer">
            <mat-card *ngFor="let site of allSites"
                     class="mmr-action-card"
                     [routerLink]="'/portal/masterdata/plant-detail/' + site.SiteId">
               <mat-card-header>
                  <div mat-card-avatar>
                     <mat-icon>corporate_fare</mat-icon>
                  </div>
                  <mat-card-title>{{site.Name}}</mat-card-title>
                  <mat-card-subtitle>{{site.getLineCount()}} Lines | {{site.getProductsCount()}} Products</mat-card-subtitle>
               </mat-card-header>   
               <mat-card-content>        

               </mat-card-content>
            </mat-card>
            <div class="clear-both"></div>
         </div>

      </mat-card-content>
   </mat-card>
</div>