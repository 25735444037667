import { AgServiceBookItemActionsCellRendererComponent } from "./ag-service-book-item-actions-cell-renderer/ag-service-book-item-actions-renderer.component";
import { AgServiceBookItemTitleCellRendererComponent } from "./ag-service-book-item-title-cell-renderer/ag-service-book-item-title-cell-renderer.component";
import { ServiceBookItemListComponent } from "./service-book-item-list.component";

export function getColumnDefinitionServiceBookItem(source: ServiceBookItemListComponent) {
   return [{

         headerName: 'Info',
         children: [
         {
            headerName: 'Name',  
            field: 'Name',
            cellRenderer: AgServiceBookItemTitleCellRendererComponent,
            cellRendererParams: {
               onEdit: source.onEditAg,
            },            
            width: 400
         },
         {
            headerName: 'Actions',
            cellRenderer: AgServiceBookItemActionsCellRendererComponent,
            cellRendererParams: {
               onDelete: source.onDeleteAg,
               onEdit: source.onEditAg,
               onRestore: source.onRestoreAg,
            }
         },                
         {
            headerName: 'Plant',
            field: 'SiteName'
         },      
         {
            headerName: 'Lines',
            field: 'Lines',
            width: 600,
            valueGetter: lines => {
               let result = '';
               for(const line of lines.data.Lines) {
                  result += line.Name + ', ';
               }
               return result;
            }
         },      
         {
            headerName: 'Products',
            field: 'Products',
            width: 600,
            valueGetter: products => {
               let result = '';
               for(const product of products.data.Products) {
                  result += product.Name + ', ';
               }
               return result;
            }
         }
      ]
   }]
}
