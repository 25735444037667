import { Injectable } from "@angular/core";
import { getEntityFromDatabucket } from "datahive-basic";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { EventItemEntity } from "../core/entities/service-book/event-item";
import { ServiceBookItem } from "../core/entities/service-book/service-book-item";
import { SiteServiceRequestEntity } from "../core/entities/service-book/site-service-request";
import { Twenty47RequestEntity } from "../core/entities/service-book/twenty47-request";
import { ServiceBookItemType } from "../core/enums/service-book-item-type";
import { QueryRequest } from "../core/input-data/query.request";
import { ServiceBookAggregatedResponse } from "../core/models/service-book-aggregated.response";
import { ServiceBookRepository } from "../core/repositories/service-book.repository";
import { MMRGlobals } from "../core/services/mmr-globals";
import { MMRHttpClient } from "../core/shared/mmr-http-client";

@Injectable()
export class ServiceBookRepositoryService
       implements ServiceBookRepository {

   constructor(private globals: MMRGlobals,
               private http: MMRHttpClient) {}


   public async searchServiceBook(query: QueryRequest, requestReference: { request: Subscription; }): Promise<ServiceBookAggregatedResponse> {
      const items = await this.http.post<QueryRequest, ServiceBookAggregatedResponse>(this.globals.urlMatrix.ServiceBookApiUrl + 'serviceBook/query', query, requestReference);
      const res = new ServiceBookAggregatedResponse();
      res.Results = this.returnPolymorphicList(items.Results);
      res.Total = items.Total;
      res.GroupedLineIds = items.GroupedLineIds;
      res.GroupedProductIds = items.GroupedProductIds;   
      res.GroupedSiteIds = items.GroupedSiteIds;   
      return res;
   }
   
   public async createTwenty47Request(twenty47Request: Twenty47RequestEntity): Promise<string> {
      const newId = await this.http.post<Twenty47RequestEntity, any>(this.globals.urlMatrix.ServiceBookApiUrl + 'twenty47Requests', twenty47Request);
      return newId.Id;
   }
   public async editTwenty47Request(twenty47RequestId: string, twenty47Request: Twenty47RequestEntity): Promise<boolean> {
      const editedCount = await this.http.put<Twenty47RequestEntity, any>(this.globals.urlMatrix.ServiceBookApiUrl + 'twenty47Requests' + '/' + twenty47RequestId, twenty47Request);
      return editedCount > 0;
   }
   public async deleteTwenty47Request(serviceBookItemId: string): Promise<boolean> {
      const deletedCount = await this.http.delete(this.globals.urlMatrix.ServiceBookApiUrl + 'twenty47Requests/' + serviceBookItemId);
      return deletedCount > 0;
   }
   public async getTwenty47RequestById(serviceBookItemId: string, renderOptions: boolean): Promise<Twenty47RequestEntity> {
      const item = await this.http.get<Twenty47RequestEntity>(this.globals.urlMatrix.ServiceBookApiUrl + 'twenty47Requests/' + serviceBookItemId + '?renderOptions=' + renderOptions);
      return item;
   }

   public async createSiteServiceRequest(siteServiceRequest: SiteServiceRequestEntity): Promise<string> {
      const newId = await this.http.post<SiteServiceRequestEntity, string>(this.globals.urlMatrix.ServiceBookApiUrl + 'siteServiceRequests', siteServiceRequest);
      return newId;
   }
   public async editSiteServiceRequest(serviceBookItemId: string, twenty47Request: SiteServiceRequestEntity): Promise<boolean> {
      const editedCount = await this.http.put<Twenty47RequestEntity, any>(this.globals.urlMatrix.ServiceBookApiUrl + 'siteServiceRequests' + '/' + serviceBookItemId, twenty47Request);
      return editedCount > 0;
   }
   public async deleteSiteServiceRequest(serviceBookItemId: string): Promise<boolean> {
      const deletedCount = await this.http.delete(this.globals.urlMatrix.ServiceBookApiUrl + 'siteServiceRequests' + '/' + serviceBookItemId);
      return deletedCount > 0;
   }
   public async getSiteServiceRequestById(serviceBookItemId: string, renderOptions: boolean): Promise<SiteServiceRequestEntity> {
      const item = await this.http.get<Twenty47RequestEntity>(this.globals.urlMatrix.ServiceBookApiUrl + 'siteServiceRequests/' + serviceBookItemId + '?renderOptions=' + renderOptions);
      return item;
   }

   public async createEventItem(eventItem: EventItemEntity): Promise<string> {
      const newId = await this.http.post<EventItemEntity, any>(this.globals.urlMatrix.ServiceBookApiUrl + 'eventItems', eventItem);
      return newId.Id;
   }
   public async editEventItem(serviceBookItemId: string, twenty47Request: EventItemEntity): Promise<boolean> {
      const editedCount = await this.http.put<EventItemEntity, any>(this.globals.urlMatrix.ServiceBookApiUrl + 'eventItems' + '/' + serviceBookItemId, twenty47Request);
      return editedCount > 0;
   }
   public async deleteEventItem(serviceBookItemId: string): Promise<boolean> {
      const deletedCount = await this.http.delete(this.globals.urlMatrix.ServiceBookApiUrl + 'eventItems/' + serviceBookItemId);
      return deletedCount > 0;
   }
   public async restoreEventItem(serviceBookItemId: string): Promise<boolean> {
      const deletedCount = await this.http.put(this.globals.urlMatrix.ServiceBookApiUrl + 'eventItems/restore/' + serviceBookItemId, '');
      return deletedCount > 0;
   }   
   public async getEventItemById(serviceBookItemId: string, renderOptions: boolean): Promise<EventItemEntity> {
      const item = await this.http.get<EventItemEntity>(this.globals.urlMatrix.ServiceBookApiUrl + 'eventItems/' + serviceBookItemId + '?renderOptions=' + renderOptions);
      return item;
   }
   public async checkForAttachments(serviceBookItemId: string) {
      await this.http.post<any, any>(this.globals.urlMatrix.ServiceBookApiUrl + 'checkAttachment/' + serviceBookItemId, '');
   }
   private returnPolymorphicList(items: any[]):ServiceBookItem[] {
      const allItems: ServiceBookItem[] = [];
      if(!items) {
         return allItems;
      }
      for(const item of items) {
         let typedItem: ServiceBookItem;
         if(item.ServiceBookItemType === ServiceBookItemType.EventItem) {
            typedItem = getEntityFromDatabucket<EventItemEntity>(item, EventItemEntity);            
         } else if(item.ServiceBookItemType === ServiceBookItemType.SiteServiceRequest){
            typedItem = getEntityFromDatabucket<SiteServiceRequestEntity>(item, SiteServiceRequestEntity);            
         } else if(item.ServiceBookItemType === ServiceBookItemType.Twenty47Request){
            typedItem = getEntityFromDatabucket<Twenty47RequestEntity>(item, Twenty47RequestEntity);            
         }
         allItems.push(typedItem);
      }
      return allItems;
   }
}