import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LineWithContent } from 'src/app/core/entities/line-with-content';
import { ProductEntity } from 'src/app/core/entities/product.entity';
import { SiteWithContent } from 'src/app/core/entities/site-with-content';
import { GetTwenty47RequestFormRequest } from 'src/app/core/input-data/service-book/get-twenty47-request-form.request';
import { Twenty47RequestCreateRequest } from 'src/app/core/input-data/service-book/twenty47-request-create.request';
import { Twenty47RequestEditRequest } from 'src/app/core/input-data/service-book/twenty47-request-edit.request';
import { Twenty47RequestFormPresenter } from 'src/app/core/output-boundaries/twenty47-request-form.presenter';
import { LayoutService } from 'src/app/core/services/layout.service';
import { MMRGlobals } from 'src/app/core/services/mmr-globals';
import { BaseFormComponent } from 'src/app/core/shared/base-form.component';
import { ServiceBookItemCheckAttachmentUseCaseService } from 'src/app/core/use-cases/service-book/event-item-check-attachment.use-case';
import { GetTwenty47RequestFormUseCaseService } from 'src/app/core/use-cases/service-book/get-twenty47-request-form.use-case';
import { Twenty47RequestCreateUseCaseService } from 'src/app/core/use-cases/service-book/twenty47-request-create.use-case';
import { Twenty47RequestEditUseCaseService } from 'src/app/core/use-cases/service-book/twenty47-request-edit.use-case';
import { showSnackBar, showSnackBarError } from '../../shared/material-helpers';
import { getProductName } from '../../shared/product-helpers';
import { ServiceBookItemFormComponent } from '../base-service-book-item.form.component';
import { Twenty47RequestFormPresenterService } from './twenty47-request-form.presenter.service';
import { Twenty47RequestFormViewModel } from './twenty47-request-form.view-model';
import { Twenty47RequestFormModel } from './twenty47-request.form-model';

@Component({
  selector: 'app-twenty47-request-form',
  templateUrl: './twenty47-request-form.component.html',
  styleUrls: ['./twenty47-request-form.component.scss'],
  providers: [
   GetTwenty47RequestFormUseCaseService,
   Twenty47RequestCreateUseCaseService,
   { provide: Twenty47RequestFormPresenter, useClass: Twenty47RequestFormPresenterService},
]
})
export class Twenty47RequestFormComponent 
       extends ServiceBookItemFormComponent
       implements OnInit {

   constructor(public globals: MMRGlobals,
               public activatedRoute: ActivatedRoute,
               public router: Router,
               public formBuilder: UntypedFormBuilder,
               public layoutService: LayoutService,
               public getTwenty47RequestFormUseCase: GetTwenty47RequestFormUseCaseService,
               public twenty47RequestCreateUseCase: Twenty47RequestCreateUseCaseService,
               public checkAttachmentUseCaseService: ServiceBookItemCheckAttachmentUseCaseService,
               public twenty47RequestEditUseCase: Twenty47RequestEditUseCaseService,
               public twenty47RequestFormPresenter: Twenty47RequestFormPresenter<Twenty47RequestFormViewModel>) { 
      super(globals, 
            activatedRoute, 
            router, 
            layoutService, 
            formBuilder, 
            checkAttachmentUseCaseService);
   }
   openSharePoint(e) {
      this.globals.trackEvent('OpenLibrary', e.detail.webUrl);
      window.open(e.detail.webUrl);
   }     
   public async loadFormData() {      
      const request = new GetTwenty47RequestFormRequest();
      request.Twenty47RequestId = this.currentItemId;
      await this.getTwenty47RequestFormUseCase.execute(request);
      const event = this.twenty47RequestFormPresenter.viewModel.GetTwenty47RequestFormResponse.Twenty47RequestEntity;
      if(event) {
         this.edit = true;
         const formModel = new Twenty47RequestFormModel();
         formModel.Name = event.Name;
         formModel.Description = event.Description;
         formModel.SiteId = event.SiteId;
         formModel.LineIds = event.Lines.map(line => line.MasterdataId);
         formModel.ProductIds = event.Products.map(line => line.MasterdataId);         
         formModel.BasicInformation = event.BasicInformation;
         
         this.formGroup.patchValue(formModel);
         this.setSelectedSite();         
         this.setSelectedLine();         
         this.setSelectedProduct();         
      } else {
         if(this.router.url.includes('twenty47RequestForm')) {
            this.formGroup.patchValue({
               BasicInformation: {
                  tickettype__LSP3Q:0
               }
            });         
         }
         if(this.router.url.includes('siteServiceRequestForm')) {
            this.formGroup.patchValue({
               BasicInformation: {
                  tickettype__LSP3Q:1
               }
            });            
         }  
         this.edit = false;
      }
      if(!this.edit) {
         this.setPreselectedFields(Twenty47RequestFormModel);
      } 
   }
   public async editControllerAction() {
      this.disableSubmit();
      const twenty47RequestEditRequest = new Twenty47RequestEditRequest();
      twenty47RequestEditRequest.Twenty47RequestEntity = this.formGroup.value;
      twenty47RequestEditRequest.Id = this.currentItemId;
      try {
         await this.twenty47RequestEditUseCase.execute(twenty47RequestEditRequest);
         this.globals.trackEvent('Twenty47Updated', twenty47RequestEditRequest.Twenty47RequestEntity.Name);
         showSnackBar('Successfully updated event', this.globals.matSnackbar);
         this.navigateBackAfterTimeout();
      } catch(e) {
         showSnackBarError('Error: ' + e, this.globals.matSnackbar);
      }  
      this.enableSubmit();
   }
   public async createControllerAction() {
      this.disableSubmit();

      const twenty47RequestCreateRequest = new Twenty47RequestCreateRequest();
      twenty47RequestCreateRequest.Twenty47RequestEntity = this.formGroup.value;
      try {
         showSnackBar('Inserting...', this.globals.matSnackbar);
         await this.twenty47RequestCreateUseCase.execute(twenty47RequestCreateRequest);
         this.globals.trackEvent('Twenty47Added', twenty47RequestCreateRequest.Twenty47RequestEntity.Name);
         showSnackBar('Successfully created your request. You can add some files now.', this.globals.matSnackbar);
         if(this.preselectedProductId) {
            this.router.navigate(['portal', 'masterdata', 'product-detail', this.preselectedProductId]);
         } else if(this.preselectedSiteId) {
            this.router.navigate(['portal', 'masterdata', 'plant-detail', this.preselectedSiteId]);
         } else {
            this.router.navigate(['portal', 'servicebook', 'twenty47RequestForm', 'edit', this.twenty47RequestFormPresenter.viewModel.NewId]);
         }         
      } catch(e) {
         showSnackBarError('Error: ' + e, this.globals.matSnackbar);
      }            
      this.enableSubmit();
   }        
}
