import { Component, Input, OnInit } from '@angular/core';
import { PortalUserEntity } from 'src/app/core/entities/portal-user.entity';

@Component({
  selector: 'app-user-thumb',
  templateUrl: './user-thumb.component.html',
  styleUrls: ['./user-thumb.component.scss']
})
export class UserThumbComponent
       implements OnInit {   
   @Input() user: PortalUserEntity;

   initials = '??';

   constructor() { }

   ngOnInit(): void {  
      if(this.user) {
         this.initials = this.user.FirstName[0].toUpperCase() + this.user.LastName[0].toUpperCase();
      }          
   }
}
