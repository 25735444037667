import { Injectable } from '@angular/core';
import { CustomerEntity } from '../core/entities/customer.entity';
import { PlantEntity } from '../core/entities/plant.entity';
import { QueryRequest } from '../core/input-data/query.request';
import { CustomerRepository } from '../core/repositories/customer.repository';
import { PlantRepository } from '../core/repositories/plant.repository';
import { MMRGlobals } from '../core/services/mmr-globals';
import { MMRHttpClient } from '../core/shared/mmr-http-client';

@Injectable()
export class PlantRepositoryService
       implements PlantRepository {

   constructor(private globals: MMRGlobals,
               private http: MMRHttpClient) {}

   public async searchPlants(query: QueryRequest): Promise<PlantEntity[]> {
      const plants = await this.http.post<QueryRequest, PlantEntity[]>(this.globals.baseUriEnterprises + 'customerSites/search', query);            
      return plants;
   }  
   public async getById(enterpriseId: string, renderOptions = true): Promise<PlantEntity> {
      const plant = await this.http.get<PlantEntity>(this.globals.baseUriEnterprises + 'customerSites/' + enterpriseId + '?renderOptions=' + renderOptions);            
      return plant;
   }
   public async getByCustomerId(enterpriseId: string, renderOptions = true): Promise<PlantEntity[]> {
      const plants = await this.http.get<PlantEntity[]>(this.globals.baseUriEnterprises + 'customers/' + enterpriseId + '/sites?renderOptions=' + renderOptions);            
      return plants;
   }
   public async getByIdWithChangelog(enterpriseId: string, renderOptions = true): Promise<PlantEntity[]> {
      const plant = await this.http.get<PlantEntity[]>(this.globals.baseUriEnterprises + 'customerSites/' + enterpriseId + '/changelog?renderOptions=' + renderOptions);            
      return plant;
   }   
}
