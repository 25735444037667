import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MMRGlobals } from 'src/app/core/services/mmr-globals';
import { SnackBarErrorComponent } from './material/snack-bar-error/snack-bar-error.component';
import { SnackBarSuccessComponent } from './material/snack-bar-success/snack-bar-success.component';

export const horizontalSnackbarPosition: MatSnackBarHorizontalPosition = 'left';
export const verticalSnackbarPosition: MatSnackBarVerticalPosition = 'top';

export function showSnackBar(message: string, 
                             snackbar: MatSnackBar, 
                             time: SnackbarTime = SnackbarTime.Middle) {
   if(!message || !snackbar) {
      return;
   } 
   const snackBarTime = getSnackbarTime(time);
   snackbar.openFromComponent(SnackBarSuccessComponent, { data: { message: message }, 
                                                          duration: snackBarTime,
                                                          verticalPosition: verticalSnackbarPosition,
                                                          horizontalPosition: horizontalSnackbarPosition
                                                        });
}
export function showSnackBarError(message: string, 
                                  snackbar: MatSnackBar, 
                                  time: SnackbarTime = SnackbarTime.Middle) { 
   if(!message || !snackbar) {
      return;
   }                                      
   const snackBarTime = getSnackbarTime(time);
   snackbar.openFromComponent(SnackBarErrorComponent, { data: { errorMessage: message }, 
                                                        duration: snackBarTime,
                                                        verticalPosition: verticalSnackbarPosition,
                                                        horizontalPosition: horizontalSnackbarPosition
                                                      });
}
function getSnackbarTime(time: SnackbarTime): number {
   if(time === SnackbarTime.Short) {
      return 3000;
   } else if(time === SnackbarTime.Middle) {
      return 10000
   } else if(time === SnackbarTime.Long) {
      return 40000;
   } else if(time === SnackbarTime.Forever) {
      return 90000000; 
   }
   return -1;
}
export enum SnackbarTime {
   Short,
   Middle,
   Long,
   Forever
}
