import { Subscription } from 'rxjs';
import { ProductEntity } from '../entities/product.entity';
import { SiteWithContent } from '../entities/site-with-content';
import { GetSiteLocationsApiModel } from '../api-models/get-site-locations.api-model';
import { SiteLocationCollection } from '../models/site-location-collection';

export abstract class MasterdataRepository {
   public abstract getSitesWithContentForCustomerId(customerId: string, requestReference: {request: Subscription}): Promise<SiteWithContent[]>; 
   public abstract transformDTOsToEntities(siteEntities: any[]): SiteWithContent[];
   public abstract getSiteLocationsAsync(apiModel: GetSiteLocationsApiModel): Promise<SiteLocationCollection>;
}
