<div class="mmr-product-tile-outer"
     *ngIf="product">
   <div class="mmr-product-tile-image"
        [routerLink]="'/portal/masterdata/product-detail/' + product.ProductId">
      <img [src]="product.ImagePath">
   </div>
   <div class="mmr-product-tile-info">
      <div class="mmr-product-type">{{product.ProductTypeName}}</div>
      
      <div class="mmr-product-values">
         <div class="mmr-product-label">Serial number</div>
         <div class="mmr-product-value">{{getSerialNumberFromProduct(product)}}</div>
      </div>

      <div class="mmr-product-values">
         <div class="mmr-product-label">Category</div>
         <div class="mmr-product-value">{{product.ProductCategoryName}}</div>      
      </div>   
   </div>
   <div class="mmr-product-tile-actions">

      <button mat-stroked-button 
              color="primary" 
              (click)="openSharePointLib()"
              [class.no-permission]="!product.UserHasSupplierDocumentationReadAccess"
              [disabled]="!!!product.FileLocation">
         <mat-icon>folder_open</mat-icon> 
         Documentation
      </button>      
      <button mat-stroked-button 
              color="primary" 
              [ngClass]="{'much-time-left': product.MyErrorLogDurationIndicator === SubscriptionDurationIndicator.MuchTimeLeft,
                          'medium-time-left' : product.MyErrorLogDurationIndicator === SubscriptionDurationIndicator.MediumTimeLeft,
                          'almost-over' : product.MyErrorLogDurationIndicator === SubscriptionDurationIndicator.AlmostOver }"              
              *ngIf="!!product.MyErrorLogUrl"
              [routerLink]="'/portal/masterdata/analytics'"
              [matTooltip]="product.MyErrorLogDaysLeft + ' days left in the subscription'"
              [queryParams]="{url: getUrlForIFrame(product.MyErrorLogUrl, '')}">
         <mat-icon>insights</mat-icon>
         MyErrorLog
      </button>      
      <button mat-stroked-button 
              color="primary" 
              [ngClass]="{'much-time-left': product.HFMGAnalyticsDurationIndicator === SubscriptionDurationIndicator.MuchTimeLeft,
                          'medium-time-left' : product.HFMGAnalyticsDurationIndicator === SubscriptionDurationIndicator.MediumTimeLeft,
                          'almost-over' : product.HFMGAnalyticsDurationIndicator === SubscriptionDurationIndicator.AlmostOver }"              
              [matMenuTriggerFor]="menu"
              [matTooltip]="product.HFMGAnalyticsDaysLeft + ' days left in the subscription'"
              *ngIf="!!product.HFMGAnalyticsUrl">
         <mat-icon>insights</mat-icon>
         HFMGDiagnostics
      </button>
      <mat-menu #menu="matMenu">
         <button mat-menu-item
                 color="primary"
                 [routerLink]="'/portal/masterdata/analytics'"
                 [queryParams]="{url: getUrlForIFrame(product.HFMGAnalyticsUrl, 'alarmsovertime')}">
         Alarms Overtime 
         </button>
         <button mat-menu-item
                  color="primary"
                  [routerLink]="'/portal/masterdata/analytics'"
                  [queryParams]="{url: getUrlForIFrame(product.HFMGAnalyticsUrl, 'alarmsfrequency')}">
         Alarms By Frequency
         </button>
         <button mat-menu-item
                  color="primary"
                  [routerLink]="'/portal/masterdata/analytics'"
                  [queryParams]="{url: getUrlForIFrame(product.HFMGAnalyticsUrl, 'statemode')}">
         Unit Availability
         </button>     
         <button mat-menu-item
                  color="primary"
                  [routerLink]="'/portal/masterdata/analytics'"
                  [queryParams]="{url: getUrlForIFrame(product.HFMGAnalyticsUrl, 'health')}">
         Health
         </button>   
         <button mat-menu-item
                  color="primary"
                  [routerLink]="'/portal/masterdata/analytics'"
                  [queryParams]="{url: getUrlForIFrame(product.HFMGAnalyticsUrl, 'ocm')}">
         OCM
         </button>  
      </mat-menu>
   </div>      
</div>