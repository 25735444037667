<div
  [ngClass]="{
    'mmr-page-inner-hidden': doneWithInitialLoading === false,
    'mmr-page-inner-shown': doneWithInitialLoading === true
  }"
>
  <form
    [formGroup]="formGroup"
    *ngIf="eventItemFormPresenter.viewModel.GetEventItemFormResponse"
    (submit)="onSubmit()"
  >
    <mat-card>
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon>event_available</mat-icon>
        </div>
        <mat-card-title>{{
          eventItemFormPresenter.viewModel?.GetEventItemFormResponse
            ?.FormHeadline
        }}</mat-card-title>
        <mat-card-subtitle>Please enter some data</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="bs-form">
          <mat-form-field class="bs-default-control">
            <mat-label>Select a plant</mat-label>
            <mat-select
              formControlName="SiteId"
              (selectionChange)="setSelectedSite()"
              color="primary"
            >
              <ng-container
                *ngFor="
                  let site of eventItemFormPresenter.viewModel
                    .GetEventItemFormResponse.SitesWithReadWritePermission
                "
              >
                <mat-option [value]="site.SiteId">
                  {{ site.Name }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="bs-default-control" *ngIf="selectedSite">
            <mat-label>Select a line</mat-label>
            <mat-select
              formControlName="LineIds"
              multiple
              (selectionChange)="setSelectedLine()"
              color="primary"
            >
              <ng-container *ngFor="let line of selectedSite.Lines">
                <mat-option [value]="line.Id">
                  {{ line.Name }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="bs-default-control" *ngIf="selectedLines">
            <mat-label>Select a product</mat-label>
            <mat-select formControlName="ProductIds" multiple color="primary">
              <ng-container *ngFor="let product of availableProducts">
                <mat-option [value]="product.ProductId">
                  {{ getProductName(product) }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>

          <app-bs-textfield formControlName="Name" [placeholder]="'Name'">
          </app-bs-textfield>

          Description:<br />
          <textarea
            class="bs-textarea"
            style="width: 100%"
            formControlName="Description"
            matRipple
          ></textarea>

          <app-component-definition-controls
            [componentDefinition]="
              eventItemFormPresenter.viewModel?.GetEventItemFormResponse
                ?.ComponentDefinition
            "
            formControlName="BasicInformation"
          >
          </app-component-definition-controls>

          <ng-container *ngIf="edit">
            <h3>Drag & drop some files:</h3>
            <mgt-file-list
              enable-file-upload
              *ngIf="
                eventItemFormPresenter?.viewModel?.GetEventItemFormResponse
                  ?.EventItemEntity
              "
              [itemId]="
                eventItemFormPresenter.viewModel.GetEventItemFormResponse
                  .EventItemEntity.FolderId
              "
              [driveId]="
                eventItemFormPresenter.viewModel.GetEventItemFormResponse
                  .EventItemEntity.LibraryId
              "
              (itemClick)="openSharePoint($event)"
            >
            </mgt-file-list>
          </ng-container>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button
          mat-raised-button
          type="button"
          *ngIf="!edit"
          color="primary"
          (click)="onSubmit()"
          [disabled]="formGroup.valid === false || waitingForResponse"
        >
          Add Event
        </button>
        <button
          mat-raised-button
          type="button"
          *ngIf="edit"
          color="primary"
          (click)="onSubmit()"
          [disabled]="
            formGroup.valid === false ||
            waitingForResponse ||
            !eventItemFormPresenter.viewModel.GetEventItemFormResponse
              .CanChangeEvent
          "
        >
          Save
        </button>
        <button mat-raised-button type="button" (click)="cancel()">
          Cancel
        </button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
<div *ngIf="doneWithInitialLoading === false" class="bs-page-loading">
  <mat-spinner [diameter]="40" class="mmr-page-loading-animation"></mat-spinner>
</div>
