<table>
   <td *ngIf="numberOfCustomers">
      <tr>Customers</tr>
      <tr>{{numberOfCustomers}}</tr>
   </td>
   <td>
      <tr>Plants</tr>
      <tr>{{numberOfCustomerSites}}</tr>
   </td>      
   <td>
      <tr>Lines</tr>
      <tr>{{numberOfLines}}</tr>
   </td>   
   <td>
      <tr>Products</tr>
      <tr>{{numberOfProducts}}</tr>
   </td>    
   <td>
      <tr>Users</tr>
      <tr>{{numberOfUsers}}</tr>
   </td>     
</table>
