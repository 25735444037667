import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { MMRGlobals } from '../services/mmr-globals';
import { DataFrame } from './data-frame';

@Injectable()
export class MMRHttpClient {
    PERMISSION_ERROR = 'No permission';
    GENERAL_ERROR = 'Something went wrong, please check error log.';
    constructor(private globals: MMRGlobals,
                private http: HttpClient) {
    }    
    private getHttpOptions() {
      const httpHeaders = new HttpHeaders();        
      const httpOptions = {
         headers: httpHeaders
      }
      return httpOptions;
    }    
    private getHttpOptionsWithBody(body: any) {
      const httpHeaders = new HttpHeaders();        
      const httpOptions = {
         headers: httpHeaders,
         body: body
      }
      return httpOptions;
    }          
    private handleException(e) {
      if (e.status == 403) {
         throw new Error(this.PERMISSION_ERROR);
      } else if(e.error && e.error.errorMessage) {
         throw new Error(e.error.errorMessage);
      } else {
         throw new Error(this.GENERAL_ERROR);
      }
    }
    async get<T>(url: string, requestReference: {request: Subscription} = {request: null}, unwrapPackage = true): Promise<T> {
      return new Promise((resolve, reject) => {
         const result = this.http.get<DataFrame<T>>(url, this.getHttpOptions()).subscribe(result => {
            if(!result) {
               resolve(null);
            }
            if(unwrapPackage) {
               resolve(result.data);
            } else {
               resolve(result as any as T);
            }            
         }, error => {
               try {
                  this.handleException(error);
               } catch(error) {
                  reject(error);
               }                                                
         });
         if (requestReference) {
            requestReference.request = result;
         }
      });      
    }
    async put<T, R>(url: string, body: T): Promise<R> {
      try {
         const result = await this.http.put<DataFrame<R>>(url, body, this.getHttpOptions()).toPromise();
         if(!result) {return null;}
         return result.data;        
      } catch(e) {
         this.handleException(e);
      }
      return null;
    }
    async post<T, R>(url: string, body: T, requestReference: {request: Subscription} = {request: null}, unwrapPackage = true): Promise<R> {
      return new Promise((resolve, reject) => {
         const result = this.http.post<DataFrame<R>>(url, body, this.getHttpOptions()).subscribe(result => {
            if(!result) {
               resolve(null);
            }
            if(unwrapPackage) {
               if(result) {
                  resolve(result.data);
               } else {
                  resolve(null);
               }               
            } else {
               resolve(result as any as R);
            }  
         }, error => {
               try {
                  this.handleException(error);
               } catch(error) {
                  reject(error);
               }                                                
         });
         if (requestReference) {
            requestReference.request = result;
         }
      });            
    }
    async delete(url: string): Promise<number> {
      try {
         await this.http.delete<DataFrame<any>>(url, this.getHttpOptions()).toPromise();
         return 1;
      } catch(e) {
         this.handleException(e);
      }   
      return null;
    }
    async deleteWithBody<T>(url: string, body: T): Promise<number> {
      try {
         await this.http.delete<DataFrame<any>>(url, this.getHttpOptionsWithBody(body)).toPromise();
         return 1;
      } catch(e) {
         this.handleException(e);
      }   
      return null;
    }    
    async patch<T>(url: string, body: T): Promise<number>  {
      try {
         const item = await this.http.patch<DataFrame<number>>(url, body, this.getHttpOptions()).toPromise();
         if(!item) {return null;}
         return item.data;
      } catch(e) {
         this.handleException(e);
      }
      return null;
    }
    async patchGeneric<T, R>(url: string, body: T): Promise<R>  {
      try {
         const item = await this.http.patch<DataFrame<R>>(url, body, this.getHttpOptions()).toPromise();
         if(!item) {return null;}
         return item.data;
      } catch(e) {
         this.handleException(e);
      }
      return null;
    }    
}
