import {Component} from '@angular/core';
import { Router } from '@angular/router';
import { ServiceBookItem } from 'src/app/core/entities/service-book/service-book-item';
import { ServiceBookItemType } from 'src/app/core/enums/service-book-item-type';
import { MMRGlobals } from 'src/app/core/services/mmr-globals';
import { EventItemDeleteUseCaseService } from 'src/app/core/use-cases/service-book/event-item-delete.use-case';
import { ServiceBookItemListComponent } from '../service-book-item-list.component';

@Component({
   templateUrl : './ag-service-book-item-title-cell-renderer.component.html',
   styleUrls: ['./ag-service-book-item-title-cell-renderer.component.scss']
})
export class AgServiceBookItemTitleCellRendererComponent {
   icon = '';
   title = '';
   item: ServiceBookItem;
   context: ServiceBookItemListComponent;
   onEdit: any = _ => {};
   constructor(public globals: MMRGlobals,
               public router: Router,
               public eventItemDeleteUseCaseService: EventItemDeleteUseCaseService) {

   }
   public agInit(params: any): void {
      if (params) {
         this.onEdit = params.onEdit;   
         this.item = params.data;
         this.context = params.context;
         const ticketId = this.item.TicketId ? ' (' + this.item.TicketId + ')' : '';
         this.title = this.item.Name + ticketId
         this.icon = this.item.returnIcon();
      }
   }
   public goToView() {
      let route = '';
      if(this.item.ServiceBookItemType === ServiceBookItemType.EventItem) {
         route = 'eventItemForm';
      } else if(this.item.ServiceBookItemType === ServiceBookItemType.Twenty47Request) {
         route = 'twenty47RequestForm';
      }      
      this.router.navigate(['portal', 'servicebook', route, 'view', this.item.Id]);   
   }   
}
