import { objectAsIterableArray } from 'datahive-basic';
import { UserAction } from 'src/app/core/entities/user';
import { SubscriptionDurationIndicator } from '../enums/subscription-duration-indicator';
import { ComponentEntity } from './component.entity';

export class ProductEntity {
   Id: string;
   CreatedBy: UserAction;
   CreatedDate: string;
   ModifiedBy: UserAction;
   ModifiedDate: string;   
   ChangeHappenedDate: string;
   FileLocation: string;

   MyErrorLogUrl: string;
   MyErrorLogDurationIndicator: SubscriptionDurationIndicator;
   MyErrorLogDaysLeft: number;

   HFMGAnalyticsUrl: string;
   HFMGAnalyticsDurationIndicator: SubscriptionDurationIndicator;
   HFMGAnalyticsDaysLeft: number;

   UserHasSupplierDocumentationReadAccess: boolean;
   Inactive: boolean;
   ProductTypeId: string;
   ProductId: string;
   SiteId: string;
   EnterpriseId: string;
   LineId: string;
   OrderId: string;
   LineName: string;
   OrderNumber: string;
   EnterpriseName: string;
   SiteName: string;
   ProductTypeName: string;
   ProductCategoryName: string;
   ProductCategoryId: string;
   ImagePath: string;
   CountryName: string;
   Components: {[productComponentId: string]: ComponentEntity[]};
}
export function getComponentFromProductById(product: ProductEntity, componentId: string) {
   let returnComponent = null;
   if(product) {
      const arr = objectAsIterableArray<ComponentEntity[]>(product.Components);
      if(arr) {         
         arr.forEach(components => {
            components.dicValue.forEach(component => {
               if(component.Id === componentId) {
                  returnComponent = component;
               }
            })
         });
      }
   }
   return returnComponent;
}