export abstract class Presenter<TView> {
    viewModel: TView;

    constructor(private template: new() => TView) {
      
    }
    public reset(): void {
        const model = new this.template();
        this.viewModel = model;
    }
    public initIfNotPresent(): void {
      if(!this.viewModel) {
         this.reset();
      }
  }
}
