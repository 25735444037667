import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { showYesNoDialog } from 'datahive-ngx-ui';
import { SiteWithContent } from 'src/app/core/entities/site-with-content';
import { AdminRepository } from 'src/app/core/repositories/admin.repository';
import { MasterdataRepository } from 'src/app/core/repositories/masterdata.repository';
import { LayoutService } from 'src/app/core/services/layout.service';
import { MMRGlobals } from 'src/app/core/services/mmr-globals';
import { BreadCrumbMenu } from 'src/app/presentation/breadcrumb-menu';

@Component({
  selector: 'app-plant-overview',
  templateUrl: './plant-overview-change-shopping-org.component.html',
  styleUrls: ['./plant-overview-change-shopping-org.component.scss']
})
export class PlantOverviewChangeShoppingOrgComponent implements OnInit {
   allSites: SiteWithContent[];
   constructor(public globals: MMRGlobals,
               public layoutService: LayoutService,
               public dialog: MatDialog,
               public adminRepository: AdminRepository) { }

   public async ngOnInit() {
      this.globals.track();
      this.allSites = this.globals.allCustomerSites;
      this.buildBurgerMenu();
   }
   public buildBurgerMenu() {
      const burger = new BreadCrumbMenu();
      burger.addItem('All Plants', '/portal/masterdata/plant-overview');
      this.globals.setBurgerMenu(burger);
   }
   public ngOnDestroy() {
      this.globals.resetBurgerMenu();
   }
   public isBuyer(site: SiteWithContent) {
      const result = site.isBuyerOrganization();
      return result;
   }
   public updateShopOrganizationForUser(siteId: string, name: string, event: any) {
      if(event) {
         event.stopPropagation();
         event.preventDefault();
      }      
      showYesNoDialog('Changing the shopping organization', 'You are changing the organization for the shop to ' + name + '. Are you sure to continue?', this.dialog, async _ => {
         await this.adminRepository.updateShopOrganizationForUser(siteId);
         this.globals.currentCustomerPortalProfile.AppSettings.ActiveSiteIdForShop = siteId;
      });
   }
}
