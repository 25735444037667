import { AGGridBaseOverviewComponent } from './ag-grid-base-overview.component';
import { MMRGlobals } from '../../services/mmr-globals';
import { AgDateCellRendererComponent } from 'datahive-ngx-structure';
import { GridOptions } from 'ag-grid-community';

export function getGridOptions(rowSelectionEnabled: boolean,
                               globals: MMRGlobals,
                               sourceComponent: any,
                               allowMultipleRows = false) {
   const gos = {
      suppressRowClickSelection: !rowSelectionEnabled,
      rowSelection: allowMultipleRows ? 'multiple' : 'single',
      rowMultiSelectWithClick: true,
      suppressMenuHide: true,
      suppressCellFocus: true,
      rowData: [],
      context: sourceComponent,
      defaultColDef: getDefaultColDefinition()
   } as GridOptions;    
   return gos;  
}
export function getDetailColumn() {
   return {
      headerName: 'Details',
      id: '0000', // Id is here because sometimes I want to find this column to remove it (switching between supliers and customers for example.)
      children: getDetailArray()
   };
}
export function getDetailArray() {
   return [
      {
         headerName: 'Created',
         field: 'CreatedDate',
         cellRenderer: AgDateCellRendererComponent,
         cellRendererParams: {showTime: true},
         filter: 'agDateFilter',
         width: 200
      },
      {
         headerName: 'Created by',
         field: 'CreatedBy',         
         width: 200,
         valueFormatter: value => {
            if(!value.value) { return '-';}
            const result = value.value.FirstName + ' ' + value.value.LastName;
            return result;
         },
         filter: null,
      },
      {
         headerName: 'Modified',
         field: 'ModifiedDate',
         cellRenderer: AgDateCellRendererComponent,
         cellRendererParams: {showTime: true},
         width: 200,
         filter: 'agDateFilter'
      },
      {
         headerName: 'Modified by',
         field: 'ModifiedBy',
         valueFormatter: value => {
            if(!value.value) { return '-';}
            const result = value.value.FirstName + ' ' + value.value.LastName;
            return result;
         },         
         width: 200,
         filter: null,     
      }
   ];
}
function getDefaultColDefinition() {
   return {
      suppressToolPanel : true,
      suppressMenu: true,
      sortable: true,
      resizable: true,
      filter: 'agTextFilter',
      menuTabs : ['generalMenuTab', 'filterMenuTab'],
      getQuickFilterText : null
   };
}
export function onSelectGridContext(grid) {
   if (grid) {
      const thisC: AGGridBaseOverviewComponent = grid.context;
      thisC.onSelect.call(thisC, grid);
   } else {
      throw {message: 'The grid has to pass a grid context.'};
   }
}
export function expandOrCollapseAllRows(gridOptions: GridOptions, expand) {
   if (gridOptions.api) {
      gridOptions.api.forEachNode((node) => {
          node.setExpanded(expand);
      });
   }
}
