import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { IUseCase } from "../../architecture/use-case";
import { ServiceBookRepository } from "../../repositories/service-book.repository";

@Injectable({providedIn: 'root'})
export class ServiceBookItemCheckAttachmentUseCaseService
       implements IUseCase<string, void> {
   public readonly presenter: void;

   requestCancelationToken: {request: Subscription } = {request: null};
   constructor(public serviceBookRepository: ServiceBookRepository) {}

   public async execute(id: string): Promise<void> {
      await this.serviceBookRepository.checkForAttachments(id);
   }
}
