import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { IUseCase } from "../../architecture/use-case";
import { GetEventItemFormRequest } from "../../input-data/service-book/get-event-item-form.request";
import { GetTwenty47RequestFormRequest } from "../../input-data/service-book/get-twenty47-request-form.request";
import { EventItemFormPresenter } from "../../output-boundaries/event-item-form.presenter";
import { Twenty47RequestFormPresenter } from "../../output-boundaries/twenty47-request-form.presenter";
import { GetEventItemFormResponse } from "../../output-data/get-event-item-form.response";
import { GetTwenty47RequestFormResponse } from "../../output-data/get-twenty47-request-form.response";
import { ComponentDefinitionRepository } from "../../repositories/component-definition.repository";
import { ServiceBookRepository } from "../../repositories/service-book.repository";
import { MasterdataCache } from "../../services/mmr-cache";
import { MMRGlobals } from "../../services/mmr-globals";
import { getSitesWithPermission } from "./get-event-item-form.use-case";

@Injectable({providedIn: 'root'})
export class GetTwenty47RequestFormUseCaseService
       implements IUseCase<GetTwenty47RequestFormRequest, Twenty47RequestFormPresenter<any>> {
          
   requestCancelationToken: {request: Subscription } = {request: null};
   constructor(public presenter: Twenty47RequestFormPresenter<any>,
               public cache: MasterdataCache,
               public globals: MMRGlobals,
               public componentDefinitionRepository: ComponentDefinitionRepository,
               public serviceBookRepository: ServiceBookRepository) {}

   public async execute(request: GetTwenty47RequestFormRequest): Promise<void> {
      this.presenter.reset();
      this.cancelRequest();

      const response = new GetTwenty47RequestFormResponse();
      if(request.Twenty47RequestId) {
         response.Twenty47RequestEntity = await this.serviceBookRepository.getTwenty47RequestById(request.Twenty47RequestId, false);      
         response.FormHeadline = 'View 24/7 Request';
      } else {
         response.FormHeadline = 'Create 24/7 Request';
      }
      response.SitesWithReadWritePermission = getSitesWithPermission('Tickets', this.globals, false);
      response.ComponentDefinition = await this.cache.getTwenty47RequestComponentDefinition();
      this.presenter.showForm(response);
   }
   private cancelRequest() {
      if (this.requestCancelationToken && this.requestCancelationToken.request) {
         this.requestCancelationToken.request.unsubscribe();
      }
   }    
}
