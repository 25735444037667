import { EventOwnership } from "../../enums/event-ownership";
import { FilterLevel } from "../../enums/filter-level";

export class GetServiceBookItemsRequest {
   QuickFilter: string;
   CustomerId: string;
   FilteredSiteIds: string[];
   FilteredLineIds: string[];
   FilteredProductIds: string[];
   FilteredAffecteAreaIds: string[];
   FilterLevel: FilterLevel;
   OnlyFetchFilters: boolean;
   StartDateFilter: Date;
   EndDateFilter: Date;
   EventOwnershipFilter: EventOwnership;
}
