import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule, MatDateRangePicker } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu'; 
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { MatBadgeModule } from '@angular/material/badge';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { UserThumbComponent } from './user-thumb/user-thumb.component';
import { AgUserPickerActionsCellRendererComponent } from './ag-grid/cell-renderers/ag-user-picker-actions-cell-renderer/ag-user-picker-actions-cell-renderer.component';
import { AgLineActionsCellRendererComponent } from '../masterdata/plant-detail/ag-line-actions-cell-renderer/ag-line-actions-cell-renderer.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SafePipe } from './safepipe';
import { SnackBarErrorComponent } from './material/snack-bar-error/snack-bar-error.component';
import { MapStatsIndicatorComponent } from './map-stats-indicator/map-stats-indicator.component';
import { AgGridAngular, AgGridModule } from 'ag-grid-angular';

@NgModule({
  declarations: [
    UserThumbComponent,
    SafePipe,
    MapStatsIndicatorComponent,
    /** Cell renderers */
    AgUserPickerActionsCellRendererComponent,
    SnackBarErrorComponent
  ],
  providers: [
   { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  imports: [
    CommonModule,
    AgGridModule,
   /** Material modules */
   MatDialogModule,
   MatInputModule,
   MatCheckboxModule,
   MatDatepickerModule,
   MatButtonToggleModule,
   MatTabsModule,
   MatRadioModule,   
   MatNativeDateModule,
   MatSliderModule,
   MatChipsModule,
   MatSlideToggleModule,
   MatExpansionModule,
   MatCardModule,
   MatButtonModule,
   MatSelectModule,
   MatTooltipModule,
   MatProgressBarModule,
   MatDividerModule,
   MatRippleModule,
   MatSnackBarModule,
   MatSliderModule,
   MatSidenavModule,
   MatMenuModule,
   MatIconModule,
   MatStepperModule,
   MatBadgeModule,
   MatIconModule,
   MatProgressSpinnerModule,    
  ],
  exports: [
      UserThumbComponent,
      AgUserPickerActionsCellRendererComponent,
      MapStatsIndicatorComponent,
      AgGridModule,
      /** Material modules */
      MatDialogModule,
      MatCheckboxModule,
      MatDatepickerModule,
      MatButtonToggleModule,
      MatTabsModule,
      MatRadioModule,
      MatNativeDateModule,
      MatSliderModule,
      MatChipsModule,
      MatSlideToggleModule,
      MatExpansionModule,
      MatCardModule,
      MatButtonModule,
      MatSelectModule,
      MatTooltipModule,
      MatProgressBarModule,
      MatDividerModule,
      MatInputModule,
      MatRippleModule,
      MatSnackBarModule,
      MatSliderModule,
      MatSidenavModule,
      MatMenuModule,
      MatIconModule,
      MatStepperModule,
      MatBadgeModule,
      MatProgressSpinnerModule,
      SafePipe,  
  ]
})
export class SharedModule { }
