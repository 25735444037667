import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { DriveFile } from "../core/entities/drive-file";
import { GraphRepository } from "../core/repositories/graph.repository";
import { MMRGlobals } from "../core/services/mmr-globals";
import { MMRHttpClient } from "../core/shared/mmr-http-client";

@Injectable()
export class GraphRepositoryService
       implements GraphRepository {
   constructor(public globals: MMRGlobals,
               public httpClient: MMRHttpClient) {

   }
   public async getSearchResult(queryText: string, 
                                rowLimit: number,
                                requestReference: {request: Subscription}): Promise<DriveFile[]> {

      const graphBasePath = 'https://graph.microsoft.com/v1.0';
      const results = await this.httpClient.post<any, any>(graphBasePath + '/search/query',
      {
         requests: [
            {
               entityTypes: [
                  "listItem"
               ],
               query: {
                  "queryString": queryText
               },
               size: rowLimit,
               fields: [
                  "name",
                  "author",
                  "title",
                  "webUrl",
                  "size",
                  "id",
                  "Customer",
                  "Plant",
                  "HFLine",
                  "ProductIdHFMGGlobal",
                  "DocumentType",
                  "ProductType",
                  "SerialNumber"          
               ]
            }
         ]
      });
      return results;
   }
}