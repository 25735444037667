<div class="dashboard-outer">
   <app-map-stats-indicator class="map-stats-indicator"
                           [numberOfCustomerSites]="numberOfCustomerSites"
                           [numberOfLines]="numberOfLines"
                           [numberOfProducts]="numberOfProducts"
                           [numberOfUsers]="numberOfUsers">
   </app-map-stats-indicator>
   <div id="world-map"></div>
</div>



