import { Injectable } from "@angular/core";

import { LayoutService } from "../core/services/layout.service";
import { MMRGlobals } from "../core/services/mmr-globals";
import { StrapiService } from "../presentation/modules/website/strapi.service";

@Injectable()
export class GlobalResolver
       {
   constructor(public mmrGlobals: MMRGlobals,
               public layoutGlobals: LayoutService,
               public strapiService: StrapiService) {
   }
   public async resolve(): Promise<void> {  
      this.mmrGlobals.showGlobalLoading();
      // this.layoutGlobals.topNavigation = await this.strapiService.getTopNavigation();
      // this.layoutGlobals.footerNavigation = await this.strapiService.getFooterNavigation();
      this.mmrGlobals.hideGlobalLoading();
      this.mmrGlobals.appLoaded = true;
   }
}