import {BreadCrumbMenuItem} from './breadcrumb-menu-item';

export class BreadCrumbMenu {
   items: BreadCrumbMenuItem[];
   constructor() {
      this.items = [];
   }
   public addItem(linkText: string, linkRoute: string) {
      const item = new BreadCrumbMenuItem(linkText, linkRoute);
      this.items.push(item);
   }
   public addItemInverse(linkText: string, linkRoute: string) {
      const item = new BreadCrumbMenuItem(linkText, linkRoute);
      this.items.unshift(item);
   }   
}