import { isTestSystem } from '../app.component';
import { NavigationLink } from './modules/website/core/models/navigation-link';

export function getCustomerPortalTopNavigationExternal(): NavigationLink[] {
  const links = [
    {
      Title: 'Dashboard',
      Value: 'portal/dashboard',
      Template: 'portal',
      Sublinks: [],
    },
    {
      Title: 'Plants',
      Value: 'portal/masterdata/plant-overview',
      Template: 'portal',
      Sublinks: [],
    },
    {
      Title: 'Documentation',
      Value: 'portal/documentation',
      Template: 'portal',
      Sublinks: [],
    },
    {
      Title: 'Service book',
      Value: 'portal/servicebook',
      Template: 'portal',
      Sublinks: [],
    },
  ] as any[];

  if (isTestSystem()) {
    links.push({
      Title: 'MyMachines',
      Value:
        'https://demo01.stage.possehl.cloud/api/login?kc_idp_hint=hfmg-azure-stage&path=machines',
      External: true,
      Sublinks: [],
    });
    links.push({
      Title: 'Spare parts',
      Value:
        'https://demo01.stage.possehl.cloud/api/login?kc_idp_hint=hfmg-azure-stage&path=products/12082',
      External: true,
      Sublinks: [],
    });
  } else {
    // links.push({
    //    Title: 'MyMachines',
    //    Value: 'https://shop.hf-mixinggroup.com/api/login?kc_idp_hint=hfmg-azure-prod&path=machines',
    //    External: true,
    //    Sublinks: []
    // });
    links.push({
      Title: 'Shop',
      Value:
        'https://shop.hf-mixinggroup.com/api/login?kc_idp_hint=hfmg-azure-prod&path=products/12082',
      External: true,
      Sublinks: [],
    });
  }

  return links;
}
export function getCustomerPortalTopNavigationInternal(): NavigationLink[] {
  const links = [
    {
      Title: 'Home',
      Value: '/portal/admincenter/map-all-sites',
      Template: 'portal',
      Sublinks: [],
    },
    {
      Title: 'Smart final mix',
      Value: '/portal/smartTools/smart-final-mix',
      Template: 'portal',
      Sublinks: [],
    },
  ] as NavigationLink[];
  return links;
}
