<header class="hfmg-header">
   <div class="hfmg-header-top">
      <div class="hfmg-centered-header">
         <div class="hfmg-logo">
            <a [routerLink]="layoutService.websiteActive ? '/' : '/portal'">
               <img src="/assets/img/hfmglogo.png" title="logo" alt="hfmg-logo">
               <!-- <img *ngIf="!layoutService.websiteActive" src="/assets/img/mmrlogo.png" title="logo" alt="hfmg-logo"> -->
            </a>
         </div>
         <div class="hfmg-search-area"></div>
         <nav class="hfmg-top-navigation">
            <ul>
               <!-- <li *ngIf="!layoutService.websiteActive">
                  <mat-icon class="hfmg-top-nav-logo">undo</mat-icon>
                  <div class="login-text">
                     <a routerLink="/">Back to website</a>
                  </div>                  
               </li>                                  -->
               <!-- <li *ngIf="!authenticationService.loginDisplay">
                  <mat-icon class="hfmg-top-nav-logo">login</mat-icon>
                  <div class="login-text">
                     <a routerLink="/portal">Login</a>
                  </div>                  
               </li>              -->                  
               <!-- <li *ngIf="authenticationService.loginDisplay && layoutService.websiteActive">
                  <mat-icon class="hfmg-top-nav-logo">home</mat-icon>
                  <div class="login-text">
                     <a routerLink="/portal">MyMixingRoom</a>
                  </div>                  
               </li>
                                                    -->
               <li matTooltip="Shop">
                  <div style="padding: 15rem;">

                     <ng-container *ngIf="globals.currentCustomerPortalProfile?.Internal">
                        <a *ngIf="isTestSystem()" href="https://iam.stage.possehl.cloud/auth/realms/possehl-platform/protocol/openid-connect/auth?client_id=possehl-portal&redirect_uri=https%3A%2F%2Fportal.stage.possehl.cloud&response_type=code&scope=openid&kc_idp_hint=hfmg-azure-stage" target="_blank">
                           <div>                        
                              <mat-icon class="hfmg-top-nav-logo">                            
                                 shopping_cart
                              </mat-icon>                
                           </div>
                        </a>     
                        <a *ngIf="!isTestSystem()" href="https://iam.possehl.cloud/auth/realms/possehl-platform/protocol/openid-connect/auth?client_id=possehl-portal&redirect_uri=https%3A%2F%2Fportal.possehl.cloud&response_type=code&scope=openid&kc_idp_hint=hfmg-azure-prod" target="_blank">
                           <div>                        
                              <mat-icon class="hfmg-top-nav-logo">                            
                                 shopping_cart
                              </mat-icon>                
                           </div>
                        </a>                                                  
                     </ng-container>
                     <ng-container *ngIf="!globals.currentCustomerPortalProfile?.Internal">
                        <a *ngIf="isTestSystem()" href="https://demo01.stage.possehl.cloud/api/login?kc_idp_hint=hfmg-azure-stage" target="_blank">
                           <div>                        
                              <mat-icon class="hfmg-top-nav-logo">                            
                                 shopping_cart
                              </mat-icon>                
                           </div>
                        </a>                           
                        <a *ngIf="!isTestSystem()" href="https://shop.hf-mixinggroup.com/api/login?kc_idp_hint=hfmg-azure-prod" target="_blank">
                           <div>                        
                              <mat-icon class="hfmg-top-nav-logo">                            
                                 shopping_cart
                              </mat-icon>                
                           </div>
                        </a>                           
                     </ng-container>

                     <div style="font-size: 12rem; margin-top: -8rem; margin-left: -2rem;">Shop</div>
                  </div> 
               </li>                                                      
               <li *ngIf="!layoutService.websiteActive">
                  <div style="padding: 15rem;" [matMenuTriggerFor]="menu">
                     <div>
                        <mat-icon class="hfmg-top-nav-logo">
                           <a>account_circle</a>                     
                        </mat-icon>     
                     </div>
                     <div style="font-size: 12rem; margin-top: -8rem; margin-left: -10rem;">Account</div>
                  </div>
                  <mat-menu #menu="matMenu">   
                     <div style="height: 66rem; padding: 10rem;">
                        <div style="font-size: 16px;">{{globals?.currentCustomerPortalProfile?.getDisplayName()}}</div>
                        <div style="font-size: 14px; color: rgba(0, 0, 0, 0.6);"
                             *ngIf="globals.currentCustomer">{{globals.currentCustomer.Name}}
                        </div>                                                                        
                        <div style="font-size: 14px; color: rgba(0, 0, 0, 0.6);"
                             *ngIf="!globals.currentCustomer">HF Mixing Group
                        </div> 
                     </div>                                              
                     <button mat-menu-item  
                             *ngIf="globals.currentCustomerPortalProfile?.Internal && globals.currentCustomerPortalProfile.hasAtLeastOnePermission(['Users.Read', 'Users.ReadWrite'])"
                             routerLink="/portal/usermanagement/internal-portal-user-overview">
                        <mat-icon>
                           group
                        </mat-icon>                             
                        <span>Internal user management</span>
                     </button>      
                     <button mat-menu-item                       
                             *ngIf="!globals.currentCustomerPortalProfile?.Internal"
                             routerLink="/portal/masterdata/plant-overview-change-shopping-org">
                        <mat-icon>
                           published_with_changes
                        </mat-icon>                             
                        <span>Change shopping organization</span>
                     </button>                  
                     <button mat-menu-item  
                             *ngIf="globals.currentCustomerPortalProfile.hasAtLeastOnePermission(['Users.Read', 'Users.ReadWrite'])"
                             routerLink="/portal/usermanagement/external-portal-user-overview">
                        <mat-icon>
                           supervisor_account
                        </mat-icon>
                        <span *ngIf="globals.currentCustomerPortalProfile?.Internal">Customer user management</span>
                        <span *ngIf="!globals.currentCustomerPortalProfile?.Internal">User management</span>
                     </button>   
                     <button mat-menu-item  
                             routerLink="/portal/about">
                        <mat-icon class="hfmg-top-nav-logo">                            
                           info
                        </mat-icon>                              
                        <span>About</span>
                     </button>                      
                     <button mat-menu-item 
                             (click)="logout()">
                        <mat-icon class="hfmg-top-nav-logo">                            
                           logout
                        </mat-icon> 
                        Logout
                     </button>
                                                         
                  </mat-menu>                                    
               </li>                               
            </ul>            
         </nav>             
      </div>
   </div>
   <div class="hfmg-header-bottom">
      <div class="hfmg-centered-header">
         <nav class="hfmg-main-navigation">
            <ul>
               <li *ngFor="let item of layoutService.topNavigation">
                  <a (click)="showSubNavigationOrNavigate(item)"
                     [class.hfmg-main-navigation-highlighted-item]="item.Highlighted === true">
                     <!-- [class.hfmg-main-navigation-selected-item]="currentMainItem && currentMainItem.Identifier === item.Identifier" -->
                     {{item.Title}}
                  </a>
               </li>
               <li *ngIf="globals.simulationActive">
                   <a (click)="exitSimulation()"
                      class="hfmg-main-navigation-highlighted-item">Exit simulation</a>
               </li>
            </ul>
         </nav>
      </div>         
   </div>
   <nav class="hfmg-sub-navigation"
        id="hfmg-sub-navigation">
      <ng-container *ngIf="sublinks && sublinks.length > 0">
         <h3>{{currentMainItem.Title}}</h3>           
         <ul>            
            <li *ngFor="let subItem of sublinks">                
               <a (click)="closeSubNavigation(); navigationService.navigateTo(subItem)"
                  (mouseenter)="showNavigationImage(subItem)"
                  (mouseleave)="hideNavigationImage()"                  
                  [class.hfmg-main-navigation-active-item]="subItem.IsActive === true">
                  {{subItem.Title}}
               </a>
               <mat-icon class="hfmg-sub-navigation-chevron"
                        *ngIf="subItem.Sublinks && subItem.Sublinks.length > 0">
                  chevron_right
               </mat-icon>                
               <div class="hfmg-sub-sub-navigation"
                  *ngIf="subItem.Sublinks && subItem.Sublinks.length > 0">
                  <h3>{{subItem.Title}}</h3> 
                  <ul>                  
                     <li *ngFor="let subsubItem of subItem.Sublinks"
                        (mouseenter)="showNavigationImage(subsubItem)"
                        (mouseleave)="hideNavigationImage()">                           
                        <a (click)="closeSubNavigation(); navigationService.navigateTo(subsubItem)"
                           [class.hfmg-main-navigation-active-item]="subsubItem.IsActive === true">
                           {{subsubItem.Title}}   
                        </a>
                     </li>
                  </ul>
               </div>                         
            </li>
         </ul>            
      </ng-container>

      <img [hidden]="!navigationImage" 
         id="hfmg-navigation-preview"
         [src]="navigationImage" />           

      <mat-icon class="hfmg-close-sub-navigation" (click)="closeSubNavigation()">close</mat-icon>
   </nav>          
</header>
<header class="hfmg-breadcrumb">
   <div class="mmr-burger-menu"
         *ngIf="breadCrumbMenu && breadCrumbMenu.items && breadCrumbMenu.items.length > 0">
      <div class="mmr-burger-item"
            *ngFor="let items of breadCrumbMenu.items; let i = index"
            [routerLink]="items.LinkRoute">
         <div class="mmr-burger-text"
               [innerHtml]="items.LinkText">
         </div>
         <div class="mmr-burger-chevron"
               *ngIf="i < breadCrumbMenu.items.length - 1">
            <mat-icon>chevron_right</mat-icon>
         </div>
         <div class="clear-both"></div> 
      </div>   
      <div class="clear-both"></div>             
   </div> 
</header>
<div class="hfmg-blur-page" (click)="closeSubNavigation()" id="blur-page"></div>      