import { PortalUserEntity } from "../portal-user.entity";
import { ServiceBookItem } from "./service-book-item";

export class Twenty47RequestEntity 
       extends ServiceBookItem {
   public hasWritePermission(portalUser: PortalUserEntity) {
      return false;
   }
   public hasReadPermission(portalUser: PortalUserEntity) {
      return false;
   }
   public returnName() {
      return '24/7 Request';
   }         
   public returnIcon() {
      return 'question_answer';
   } 
   public returnRoute() {
      return 'twenty47RequestForm';
   }           
}