import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { getEntitiesFromDatabuckets, getEntityFromDatabucket } from "datahive-basic";
import { PortalUserEntity } from "../core/entities/portal-user.entity";
import { PermissionMatrixEntity } from "../core/entities/portal-users/permission-matrix.entity";
import { AdminRepository } from "../core/repositories/admin.repository";
import { CreateExternalPortalUserApiRequest } from "../core/repositories/api-requests/create-external-portal-user.api-request";
import { CreateInternalPortalUserApiRequest } from "../core/repositories/api-requests/create-internal-portal-user.api-request";
import { DeletePortalUserApiRequest } from "../core/repositories/api-requests/delete-portal-user.api-request";
import { EditExternalPortalUserApiRequest } from "../core/repositories/api-requests/edit-external-portal-user.api-request";
import { EditInternalPortalUserApiRequest } from "../core/repositories/api-requests/edit-internal-portal-user.api-request";
import { GetPortalUserApiRequest } from "../core/repositories/api-requests/get-portal-user.api-requests";
import { GenericResultSet } from "../core/repositories/api-responses/generic-result-set";
import { PortalUserCreateApiResponse } from "../core/repositories/api-responses/portal-user-create.api-response";
import { PortalUserEditApiResponse } from "../core/repositories/api-responses/portal-user-edit.api.response";
import { MMRGlobals } from "../core/services/mmr-globals";
import { MMRHttpClient } from "../core/shared/mmr-http-client";

@Injectable()
export class AdminRepositoryService
       extends AdminRepository {

   constructor(public globals: MMRGlobals,
               public http: MMRHttpClient,
               public httpClient: HttpClient) {
         super();
   }          
   public async getMyself(): Promise<PortalUserEntity> {
      const dto = await this.http.get<PortalUserEntity>(this.globals.urlMatrix.AdminApiUrl + 'users/me');
      const user = getEntityFromDatabucket<PortalUserEntity>(dto, PortalUserEntity);
      return user;
   }   
   // public async getDocument() {
   //    const httpOptions = {
   //       headers: new HttpHeaders({
   //          'Authorization': 'Basic ' + btoa('elsner:E8ytr9uu3!11'),
   //       })
   //    };
   //    let target = await this.client.get('http://defgb-svawrun.mg.mixinggroup.com:12080/rest-ECM/api/files/531674', httpOptions as any).toPromise() as any;
   //    let fileName = target.content.filename;

   //    const httpOptionsFile = {
   //       headers: new HttpHeaders({
   //          'Authorization': 'Basic ' + btoa('elsner:E8ytr9uu3!11'),
   //       }),
   //       responseType: 'blob'
   //    };
   //    await this.client.get('http://defgb-svawrun.mg.mixinggroup.com:12080/rest-ECM/api/files/531674/download', httpOptionsFile as any).subscribe((response : any) => {
   //    var a = document.createElement('a');
   //    a.href = window.URL.createObjectURL(response);
   //    a.download = fileName;
   //    a.dispatchEvent(new MouseEvent('click'));
   //    });

   // }      
   // public async getNews(): Promise<any> {
   //    const dto = await this.httpClient.get('https://hf-mixinggroup.com/news/rss/?type=9818', {
   //       headers: new HttpHeaders({
   //         'Accept': 'text/html, application/xhtml+xml, */*'
   //       }),
   //       responseType: 'text'
   //     }).toPromise();
   //    let parser = new DOMParser();
   //    let news = parser.parseFromString(dto,"text/xml");       
   // }   
   public async getPermissionMatrixForInternalUser(): Promise<PermissionMatrixEntity> {
      const matrix = await this.http.get<PermissionMatrixEntity>(this.globals.urlMatrix.AdminApiUrl + 'permissionMatrix/internal');
      return matrix;
   }
   public async getPermissionMatrixForExternalUserAsExternalUser(): Promise<PermissionMatrixEntity> {
      const matrix = await this.http.get<PermissionMatrixEntity>(this.globals.urlMatrix.AdminApiUrl + 'permissionMatrix/external');
      return matrix;
   }
   public async getPermissionMatrixForExternalUserAsInternalUser(customerId: string): Promise<PermissionMatrixEntity> {
      const matrix = await this.http.get<PermissionMatrixEntity>(this.globals.urlMatrix.AdminApiUrl + 'permissionMatrix/external/' + customerId);
      return matrix;
   }
   public async getInternalPortalUsers(request: GetPortalUserApiRequest): Promise<GenericResultSet<PortalUserEntity>> {
      const dto = await this.http.post<GetPortalUserApiRequest, GenericResultSet<PortalUserEntity>>(this.globals.urlMatrix.AdminApiUrl + 'users/search/internal', request);
      const users = getEntitiesFromDatabuckets<PortalUserEntity>(dto.Results, PortalUserEntity);
      const result = new  GenericResultSet<PortalUserEntity>();
      result.Results = users;
      result.Total = dto.Total;
      return result;
   }
   public async getExternalPortalUsers(request: GetPortalUserApiRequest): Promise<GenericResultSet<PortalUserEntity>> {
      const dto = await this.http.post<GetPortalUserApiRequest, GenericResultSet<PortalUserEntity>>(this.globals.urlMatrix.AdminApiUrl + 'users/search/external', request);
      const result = new  GenericResultSet<PortalUserEntity>();
      const users = getEntitiesFromDatabuckets<PortalUserEntity>(dto.Results, PortalUserEntity);
      result.Results = users;
      result.Total = dto.Total;
      return result;
   }
   public async createExternalPortalUserAsInternal(request: CreateExternalPortalUserApiRequest): Promise<PortalUserCreateApiResponse> {
      const result = await this.http.post<CreateExternalPortalUserApiRequest, PortalUserCreateApiResponse>(this.globals.urlMatrix.AdminApiUrl + 'users/external/asInternal', request);
      return result;
   }
   public async createExternalPortalUserAsExternal(request: CreateExternalPortalUserApiRequest): Promise<PortalUserCreateApiResponse> {
      const result = await this.http.post<CreateExternalPortalUserApiRequest, PortalUserCreateApiResponse>(this.globals.urlMatrix.AdminApiUrl + 'users/external/asExternal', request);
      return result;
   }   
   public async editExternalPortalUserAsExternal(request: EditExternalPortalUserApiRequest, id: string): Promise<PortalUserEditApiResponse> {
      const result = await this.http.put<EditExternalPortalUserApiRequest, PortalUserEditApiResponse>(this.globals.urlMatrix.AdminApiUrl + 'users/external/' + id, request);
      return result;
   }  
   public async editInternalPortalUser(request: EditInternalPortalUserApiRequest, id: string): Promise<PortalUserEditApiResponse> {
      const result = await this.http.put<EditInternalPortalUserApiRequest, PortalUserEditApiResponse>(this.globals.urlMatrix.AdminApiUrl + 'users/internal/' + id, request);
      return result;
   }     
   public async createInternalPortalUser(request: CreateInternalPortalUserApiRequest): Promise<PortalUserCreateApiResponse> {
      const result = await this.http.post<CreateInternalPortalUserApiRequest, PortalUserCreateApiResponse>(this.globals.urlMatrix.AdminApiUrl + 'users/internal', request);
      return result;
   }
   public async deleteExternalPortalUser(request: DeletePortalUserApiRequest, id: string) {
      await this.http.deleteWithBody(this.globals.urlMatrix.AdminApiUrl + 'users/external/' + id, request);
   }
   public async deleteInternalPortalUser(id: string) {
      await this.http.delete(this.globals.urlMatrix.AdminApiUrl + 'users/internal/' + id);
   }   
   public async getPortalUserById(id: string): Promise<PortalUserEntity> {
      const dto = await this.http.get<PortalUserEntity>(this.globals.urlMatrix.AdminApiUrl + 'users/' + id);
      const user = getEntityFromDatabucket<PortalUserEntity>(dto, PortalUserEntity);
      return user;
   }
   public async getPortalUserByUserId(userId: string): Promise<PortalUserEntity> {
      const dto = await this.http.get<PortalUserEntity>(this.globals.urlMatrix.AdminApiUrl + 'users/byUserId/' + userId);
      const user = getEntityFromDatabucket<PortalUserEntity>(dto, PortalUserEntity);
      return user;
   }
   public async manualDocumentsRefreshCustomerLevel(customerId: string) {
      await this.http.patch(this.globals.urlMatrix.AdminApiUrl + 'users/documentsRefreshCustomerLevel/' + customerId, null);
   }
   public async manualDocumentsRefreshUserLevel(userId: string) {
      await this.http.patch(this.globals.urlMatrix.AdminApiUrl + 'users/documentsRefreshUserLevel/' + userId, null);
   }   
   public async updateShopOrganizationForUser(siteId: string) {
      await this.http.put(this.globals.urlMatrix.AdminApiUrl + 'users/external/updateShopOrganizationForUser', {
         SiteId: siteId
      });
   }     
}