import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page } from './core/models/page';
import { NavigationLink } from './core/models/navigation-link';
import { clonePOJO } from 'datahive-basic';
import { Posting } from './core/models/posting';

@Injectable({
  providedIn: 'root'
})
export class StrapiService {

   //baseUrl = 'http://localhost:1337/api/'; 
   baseUrl = 'https://spareparts.mymixingroom.com/api/';
   
   token = 'adcd015cc74f820f1ec52a0442c22dbe899133e665feee9c8bdb8205376a9e8fd81738813eadffa460511b0bcaa46e63fa23845ff89d98f31cc84edae3b08d2bb5b821ab4c1238dc727d7482948655014ae599a76d8e14ead2f219fe1fad7d14bce435fb7dfc50ce4fee2f247ea8c930cf77113fdaabcebab192e578fc67657a';
   constructor(public httpClient: HttpClient) { }

   async get(url: string) {
      let httpHeaders = new HttpHeaders();
      httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);
      return await this.httpClient.get<any>(url, {
         headers: httpHeaders
      }).toPromise();
   }
   async getTopNavigation(): Promise<NavigationLink[]> {
      const nav = await this.get(this.baseUrl + 'navigation/render/5?type=TREE');
      const data: [] = nav;
      const result: NavigationLink[] = data.map((item: any) => {
         return this.mapStrapiNavToPortalNav(item);
      });
      return result;
   }
   async getFooterNavigation(): Promise<NavigationLink[]> {
      const nav = await this.get(this.baseUrl + 'navigation/render/7?type=TREE');
      const data: [] = nav;
      const result: NavigationLink[] = data.map((item: any) => {
         return this.mapStrapiNavToPortalNav(item);
      });
      return result;
   }
   async getPostings(): Promise<Posting[]> {
      const nav = await this.get(this.baseUrl + 'postings?populate=*');
      const data: [] = nav.data;
      let result: Posting[] = data.map((item: any) => {
         return this.mapStrapiPostingToPortalPosting(item);
      });
      result = result.sort((v1, v2) => {
         return v2.CreatedDate.getTime() - v1.CreatedDate.getTime();
      });
      return result;
   }   
   private mapStrapiNavToPortalNav(item: any, parent: NavigationLink = null): NavigationLink {

      const result = {
         id: item.id,
         Identifier: item.slug,
         External: item.external,
         Template: item.Template,
         Title: item.title,
         OriginalPath: item.path,
         ImagePath: item.ImagePath,
         HasActiveChild: new NavigationLink().HasActiveChild,
         Parent: this.getParent(parent),
         Value: item && item.path ? item.path.substring(item.path.lastIndexOf('/') + 1) : ''         
      } as NavigationLink;
      result.Sublinks = item.items ? item.items.map(singleItem => this.mapStrapiNavToPortalNav(singleItem, result)): []
      return result;
   }
   private mapStrapiPostingToPortalPosting(item: any): Posting {
      const result = {
         Title: item.attributes.Title,
         Text: item.attributes.Text,
         HeaderImagePath: this.getNewsImagePath(item),
         PostingType: item.attributes.PostingType,
         CreatedDate: new Date(item.attributes.createdAt)
      } as Posting;
      return result;
   }
   getParent(parent: any) {
      const parentCopy = clonePOJO<any>(parent);
      if(parentCopy && (parentCopy.Sublinks)) {
         parentCopy.Sublinks = null;
      }      
      if(!parentCopy) { return null; }

      if(parentCopy.Identifier) {
         return parentCopy;
      }
      return this.mapStrapiNavToPortalNav(parentCopy)
   }
   async getPage(id: string): Promise<Page> {
      let result;
      try {
         result = await this.get(this.baseUrl + 'slugify/slugs/page/' + id + '?populate=*');
      } catch(e) {
         result = await this.get(this.baseUrl + 'slugify/slugs/page/sample-page?populate=*');
      }
      if(result.data) {
         const headerImage = {
            HeaderImagePath: this.getHeaderImagePath(result)
         };
         return {...result.data.attributes, ...headerImage};         
      }
      return new Page();      
   }
   getNewsImagePath(result: any) {
      if(result &&
         result.attributes &&
         result.attributes.HeaderImage && 
         result.attributes.HeaderImage.data && 
         result.attributes.HeaderImage.data.attributes && 
         result.attributes.HeaderImage.data.attributes.url) {

         return result.attributes.HeaderImage.data.attributes.url;
      }
   }   
   getHeaderImagePath(result: any) {
      if(result.data &&
         result.data.attributes &&
         result.data.attributes.HeaderImage && 
         result.data.attributes.HeaderImage.data && 
         result.data.attributes.HeaderImage.data.attributes && 
         result.data.attributes.HeaderImage.data.attributes.url) {

         return result.data.attributes.HeaderImage.data.attributes.url;
      } else {
         const random = Math.floor(Math.random() * 4);
         if(random === 0) {
            return 'https://cpstrapi.blob.core.windows.net/strapi-uploads/strapi-uploads/assets/pexels_pixabay_159298_9514b78904.jpg?updated_at=2022-09-06T07:32:25.978Z';
         } else if(random === 1) {
            return 'https://cpstrapi.blob.core.windows.net/strapi-uploads/strapi-uploads/assets/pexels_pixabay_357440_f9247fadac.jpg?updated_at=2022-09-07T06:22:49.083Z';
         } else if(random === 2) {
            return 'https://cpstrapi.blob.core.windows.net/strapi-uploads/strapi-uploads/assets/pexels_pixabay_414579_6112b7fd6d.jpg?updated_at=2022-09-06T07:34:35.888Z';
         } else if(random === 3) {
            return 'https://cpstrapi.blob.core.windows.net/strapi-uploads/strapi-uploads/assets/pexels_pixabay_209251_58c0e23ffb.jpg?updated_at=2022-09-07T06:22:49.470Z';
         }
         
      }
   }
}
