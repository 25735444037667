import { ServiceBookItemType } from "../../enums/service-book-item-type";
import { ComponentEntity } from "../component.entity";
import { PortalUserEntity } from "../portal-user.entity";
import { MasterdataAssignment } from "./masterdata-assignment";
import { TicketComment } from "./ticket-comment";

export abstract class ServiceBookItem {
   Id: string;
   Name: string;
   Description: string;
   CustomerName: string;
   CustomerId: string;
   SiteName: string;
   SiteId: string;
   FilesUrl: string;
   Comments: TicketComment[];
   TicketId: string;
   CustomerEvent: boolean;
   Lines: MasterdataAssignment[];
   Products: MasterdataAssignment[];
   BasicInformation: ComponentEntity;
   DeletedByCustomer: boolean;
   LibraryId: string;
   FolderId: string;
   HasAttachment: boolean;
   ServiceBookItemType: ServiceBookItemType;

   public abstract returnIcon();
   public abstract returnRoute();
   public abstract returnName();
   public abstract hasWritePermission(portalUser: PortalUserEntity);
   public abstract hasReadPermission(portalUser: PortalUserEntity);
}